import {
  API_DOWNLOAD_MIS,
  API_AUDIT_LOGS,
  API_SEARCH_GENERATE_DOCUMENT,
  API_ADD_CASE_FILE,
  API_UPLOAD_TESTING,
  API_UPLOAD_SHIPMENTS
} from "../ApiUrl";

const apiPayloadMap = {
    [API_DOWNLOAD_MIS]: {
        type: "Download MIS",
        notifiable_type: "Download MIS",
        successData: "MIS downloaded successfully.",
        failureData: "Failed to download MIS.",
    },
    [API_AUDIT_LOGS]: {
        type: "Audit Logs",
        notifiable_type: "Audit Logs Download",
        successData: "Audit logs downloaded successfully.",
        failureData: "Failed to download audit logs.",
    },
    [API_SEARCH_GENERATE_DOCUMENT]: {
        type: "Certified Copies OR E-Service",
        notifiable_type: "Certified Copies OR E-Service",
        successData: () => `${localStorage.getItem("nm")} downloaded successfully.`,
        failureData: () => `Failed to download ${localStorage.getItem("nm")}.`,
    },
    [API_ADD_CASE_FILE]: {
        type: "Fresh Cases Upload",
        notifiable_type: "Fresh Cases Upload",
        successData: () => `Fresh cases uploaded successfully in ${localStorage.getItem("nm") || ""}.`,
        failureData: () => `Failed to upload fresh cases in ${localStorage.getItem("nm") || ""}.`,
    },
    [API_UPLOAD_TESTING]: {
        type: "Update Cases",
        notifiable_type: "Update Cases",
        successData: "Cases updated successfully.",
        failureData: "Failed to update cases.",
    },
    [API_UPLOAD_SHIPMENTS]: {
        type: "Shipment Upload",
        notifiable_type: "Shipment Upload",
        successData: "Shipment uploaded successfully.",
        failureData: "Failed to upload shipment.",
    }
};

export default apiPayloadMap;