import React from "react";
import {
  Link,
  connect,
  TEXTFIELD,
  CircularProgress,
  toast,
  ToastContainer,
  Logo,
  Axios,
  PASSWORDFIELD,
  Row,
  Col,
  SUBMIT,
} from "../components";

import axios from "axios";

import ReCaptcha from "./Recaptcha";

import { API_LOGIN } from "../ApiUrl";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "../actions/Auth";

import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";

const tabsnav = [
  {
    name: "Arbitrator",
    value: "Arbitrator",
  },
  {
    name: "Mediator",
    value: "Mediator",
  },
  {
    name: "Advocate",
    value: "Advocate",
  },
  {
    name: "Party",
    value: "Party",
  },
  {
    name: "Manager-L2",
    value: "Chief-Manager-L2",
  },
  {
    name: "Manager-L3",
    value: "Chief-Manager-L3",
  },
];

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      errorMessage: "",
      role: "Arbitrator",
      activeRole: 0,
      captchaToken: "",
      // disable: true,
    };
  }

  componentDidUpdate(e) {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  changeText = (text, index) => {
    this.setState({ role: text });
    this.setState({ activeRole: index });
  };

  handleVerifyCaptcha = (token) => {
    this.setState({ captchaToken: token });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    // if (!phone) {
    //   this.setState({ disable: true })
    // } else if (!password) {
    //   this.setState({ disable: true })
    // }
  };
  
  loginUser = (e) => {
    e.preventDefault();
    // this.props.showAuthLoader();
    const { phone, password, role, captchaToken } = this.state;

    const formData = {
      phone: phone.trim(),
      password: password.trim(),
      role: role,
      google_recaptcha: captchaToken,
    };
    // if (!captchaToken) {
    //   toast.error('Please complete the reCAPTCHA');
    //   return;
    // }

    
    // const formData = new FormData(event.target);
    // formData.append('captchaToken', captchaToken);
    // toast.success("Login successfully.");
    // setTimeout(() => {
    //   this.props.history.push("/app/dashboard/index");
    // }, 1000);

    // axios
    // .post("https://reqres.in/api/login", userData)
    // .then((response) => {
    //   console.log(response);
    // })
    // .catch((error) => {
    //   if (error.response) {
    //     console.log(error.response);
    //     console.log("server responded");
    //   } else if (error.request) {
    //     console.log("network error");
    //   } else {
    //     console.log(error);
    //   }
    // });

    // axios
    //   .post("https://justsettle.in/backend/api/v1/login", formData)
      Axios.Post(API_LOGIN, formData)
      .then((response) => {
        const data = response.data;
        // if (data.success == false) {
        //   toast.error(data.error);
        if (data.success === false || data.error) {
          const errorMessage = data.error?.message || data.error || "An unknown error occurred.";
          toast.error(errorMessage);
        } else {
          localStorage.setItem("token", data.authorization.access_token);
          localStorage.setItem("_id", data.user.id);
          localStorage.setItem("name", data.user.name);
          localStorage.setItem("role", data.role);
          if (data.permissions && Array.isArray(data.permissions)) {
            localStorage.setItem("permissions", JSON.stringify(data.permissions));
          } else {
            localStorage.setItem("permissions", JSON.stringify([]));
          }
          localStorage.setItem("user_id", data.authorization.access_token);
          this.props.userSignInSuccess(data.authorization.access_token);
          toast.success("Login successfully.");
          setTimeout(() => {
            const role = localStorage.getItem("role");
            {
              role == "Party"
                ? this.props.history.push("/app/party/dashboard/")
                : role == "Mediator"
                ? this.props.history.push("/app/mediator/dashboard/index")
                : role == "Advocate"
                ? this.props.history.push("/app/advocate/dashboard/index")
                : role == "Arbitrator"
                ? this.props.history.push("/app/arbitrator/dashboard/index")
                : role == "LawFirm"
                ? this.props.history.push("/app/lawfirm/dashboard/index")
                : role == "Chief-Manager-L2"
                ? this.props.history.push("/app/lawfirm/dashboard/index")
                : role == "Chief-Manager-L3"
                ? this.props.history.push("/app/lawfirm/dashboard/index")
                : role == "Client"
                ? this.props.history.push("/app/client/dashboard/")
                : role == "collection"
                ? this.props.history.push("/app/collection/dashboard/")
                : role == "Superadmin"
                ? this.props.history.push("/app/admin/dashboard")
                : role == "casemanager"
                ? this.props.history.push("/app/casemanager/dashboard/index")
                : role == "lawfirm-manager"
                ? this.props.history.push("/app/lawfirm-manager/dashboard/")
                : "";
              // this.props.history.push("/app/dashboard/index")
            }
          }, 1000);
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        console.log(err, "asasaas");
        // let error = err.response;
        // if (error !== undefined) {
        //   toast.error(error.data.message);
        // } else {
        //   toast.error("server error, Please try after some time.");
        // }
        this.props.hideAuthLoader();
      });
  };

  render() {
    const { phone, password, role, activeRole } = this.state;
    const { loader } = this.props;
    var diabled = !phone || !password ? true : "";
    return (
      <>
        <Header />
        <div style={{ paddingTop: "18vh" }}></div>
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3 border11">
          <div className="app-login-main-content">
            <div className="tabs-center">
              {tabsnav.map((item, index) => (
                <button
                  className={
                    activeRole === index
                      ? "login-conariner-right-link selected-clr"
                      : "login-conariner-right-link"
                  }
                  onClick={() => this.changeText(`${item.value}`, index)}
                >
                  {item.name}
                </button>
              ))}
            </div>
            <div className="app-logo-content d-flex align-items-center justify-content-center">
              <Link className="logo-lg" to="/">
                <h1>Sign In</h1>
                <p className="signin-text12">
                  To connect with us send an email at info@justsettle.in
                </p>
                {/* <img src={Logo} alt="Logo" className="logo" /> */}
              </Link>
            </div>

            <div className="app-login-content">
              <div className="">
                <h1 className="text-capitalize">{role}</h1>
              </div>

              <Row style={{ display: "block" }}>
                <form onSubmit={(e) => this.loginUser(e)}>
                  <Col xm="12">
                    <TEXTFIELD
                      label="Enter Phone No."
                      fullWidth
                      name="phone"
                      onChange={this.handleChange}
                      value={phone}
                      errors={{}}
                    />
                  </Col>
                  <Col xm="12">
                    <PASSWORDFIELD
                      label="Password"
                      fullWidth
                      name="password"
                      onChange={this.handleChange}
                      value={password}
                      errors={{}}
                    />
                  </Col>
                  <Col xm="12" md="7">
                    {" "}
                    <ReCaptcha onVerify={this.handleVerifyCaptcha} />
                  </Col>
                  <Col xm="12" md="7">
                    <SUBMIT
                      title="Submit"
                      disabled={diabled}
                      className="clrfff"
                    />
                  </Col>
                </form>
                <Row className="m-2">
                  <div className="w-50">
                    {" "}
                    <Link to="/forget-password">Forgot Password</Link>
                  </div>
                  <div className="text-right w-50">
                    {role == "Party" ? (
                      <Link to="/party-signup">Register Now</Link>
                    ) : role == "Mediator" ? (
                      <Link to="/mediator-signup">Register Now</Link>
                    ) : role == "Advocate" ? (
                      <Link to="/advocate-signup">Register Now</Link>
                    ) : role == "Arbitrator" ? (
                      <Link to="/arbitrator-signup">Register Now</Link>
                    ) : null
                    }
                  </div>
                </Row>
              </Row>
            </div>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          <ToastContainer />
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  console.log(auth, "auth90890");
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(SignIn);