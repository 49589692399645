import React, { useState, useEffect, Fragment } from "react";
import {
  Row,
  Col,
  TEXTFIELD,
} from "../../components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const Addons = ({ data, updateAddons }) => {
  const AddonObj_new = {
    college_institute_name: "",
    university_name: "",
    course_name: "",
    year_of_passing: ""
  };

  const [state, setState] = useState({
    addons: data,
    errors: {}
  });

  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    setState(prev => ({ ...prev, addons: data }));
  }, [data]);

  useEffect(() => {
    if (isChange) {
      updateAddons(state.addons);
      setIsChange(false);
    }
  }, [isChange, state.addons, updateAddons]);

  const validateField = (name, value, index) => {
    let error = "";

    switch (name) {
      case "college_institute_name":
        error = value.trim() === "" ? "Required" : "";
        break;
      case "university_name":
        error = value.trim() === "" ? "Required" : "";
        break;
      case "course_name":
        error = value.trim() === "" ? "Required" : "";
        break;
      case "year_of_passing":
        const currentYear = new Date().getFullYear();
        if (value.trim() === "") {
          error = "Required";
        } else if (!/^\d{4}$/.test(value.trim())) {
          error = "Must be 4-digit";
        } else {
          const year = Number(value.trim());
          if (year < 1900) {
            error = "Must 1900 or later";
          } else if (year > currentYear) {
            error = "Year not allowed";
          } else {
            error = "";
          }
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e, i) => {
    e.preventDefault();
    const { name, value } = e.target;
    const error = validateField(name, value, i);
    const newErrors = {
      ...state.errors,
      [`${name}_${i}`]: error
    };
    setState(prev => ({
      ...prev,
      errors: newErrors,
      addons: [
        ...prev.addons.slice(0, i),
        { ...prev.addons[i], [name]: value },
        ...prev.addons.slice(i + 1)
      ]
    }));
    setIsChange(true);
  };

  const addRow = (e) => {
    e.preventDefault();
    setState(prev => ({
      ...prev,
      addons: [...prev.addons, { ...AddonObj_new }],
    }));
    setIsChange(true);
  };

  const deleteRow = (e, index) => {
    e.preventDefault();
    const newAddons = state.addons.filter((_, i) => i !== index);
    const newErrors = { ...state.errors };
    Object.keys(newErrors).forEach(key => {
      if (key.endsWith(`_${index}`)) {
        delete newErrors[key];
      }
    });
    setState({
      addons: newAddons,
      errors: newErrors
    });
    setIsChange(true);
  };

  return (
    <Fragment>
      {state.addons.map((s, i) => (
        <div className="uper-multi-row" key={i} style={{ marginBottom: "2rem" }}>
          <Row className="g-3 align-items-end">
            <Col lg="12" className="my-2">
              <h2 className="m-0 fw-bold font-weight-bold">Education</h2>
            </Col>
            <Col md="3" xs="12">
              <TEXTFIELD className="inputPadding"
                label="College Name"
                value={s.college_institute_name}
                name="college_institute_name"
                onChange={(e) => handleChange(e, i)}
                errors={{ college_institute_name: state.errors[`college_institute_name_${i}`] }}
                required
              />
            </Col>
            <Col md="3" xs="12">
              <TEXTFIELD className="inputPadding"
                label="University Name"
                value={s.university_name}
                name="university_name"
                onChange={(e) => handleChange(e, i)}
                errors={{ university_name: state.errors[`university_name_${i}`] }}
                required
              />
            </Col>
            <Col md="3" xs="12">
              <TEXTFIELD className="inputPadding"
                label="Course Name"
                value={s.course_name}
                name="course_name"
                onChange={(e) => handleChange(e, i)}
                errors={{ course_name: state.errors[`course_name_${i}`] }}
                required
              />
            </Col>
            <Col md="2" xs="12">
              <TEXTFIELD className="inputPadding"
                label="Year of passing"
                value={s.year_of_passing}
                name="year_of_passing"
                onChange={(e) => handleChange(e, i)}
                errors={{ year_of_passing: state.errors[`year_of_passing_${i}`] }}
                placeholder="YYYY"
                required
              />
            </Col>
            <Col md="1" xs="12" style={{ textAlign: "center" }}>
              {i === 0 ? (
                <IconButton aria-label="add" onClick={addRow}>
                  <AddCircleIcon color="primary" />
                </IconButton>
              ) : (
                <IconButton aria-label="delete" onClick={(e) => deleteRow(e, i)}>
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </Col>
          </Row>
        </div>
      ))}
    </Fragment>
  );
};

export default React.memo(Addons);