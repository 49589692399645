import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../components/Header/index";
import Sidebar from "../containers/SideNav/index";

import Dashboard from "./routes/dashboard";
import Users from "./routes/users";
import AdminUser from "./routes/roles";
import Permission from "./routes/permission";

import Arbitapproval from "./routes/arbit-approval";
import Claimantapproval from "./routes/claimant-approval";
import Respondentapproval from "./routes/respondent-approval";
import AdvocateApproval from "./routes/advocate-approval";


import PushNotification from "./routes/notification";
import Faq from "./routes/faq";
import ContentPage from "./routes/contentPages";
// import Settings from "./routes/settings";
import Systemsettings from "./routes/systemSetting";
import Emailsetting from "./routes/email-setting";
import Profile from "./routes/userProfile";
import ContactQuery from "./routes/contactQuery";

// party dashboard routes
import Dashboardparty from "./partyroutes/dashboard/dashboard";
import Changepassowrd from "./partyroutes/password/password";

import Bookings from "./partyroutes/bookings/bookings";
import Partyprofile from "./partyroutes/profile/profile";
import Meeting from "./partyroutes/Meeting/Meeting";

import PartyMediationSecond from "./partyroutes/mediation/routes/list";

import PartyConciliation from "./partyroutes/conciliation/PartyConciliation";
import PartyConciliationFresh from "./partyroutes/conciliation/PartyConciliationFresh";
import PartyConciliationFiled from "./partyroutes/conciliation/PartyConciliationFiled";
import PartyConciliationSecond from "./partyroutes/conciliation/PartyConciliationSecond";

import RespondantCases from "./partyroutes/cases/routes/list";

import PartyCaseDetail from "./partyroutes/cases/routes/SingleCase/Casedetail";
import Registercases from "./partyroutes/arbitration/list";

// import Registerparty from "./partyroutes/arbitration/register";

//client
import ClientDashboard from "./routes/Client/dashboard";

import ProfileSettings from "./routes/Client/ProfileSetting/ProfileSetting";

import AllCases from "./routes/Client/cases/routes/list";

import CaseDetail from "./routes/Client/cases/routes/SingleCase/Casedetail";

import MedCaseDetail from "./routes/Client/Mediation/routes/SingleCase/Casedetail";

import ClientMediationFiled from "./routes/Client/Mediation/routes/list";

import ClientConciliationFresh from "./routes/Client/Conciliation/ClinetFreshCAse";
import ClientConciliationFiled from "./routes/Client/Conciliation/ClientFiledCase";
import ClientConciliationSecond from "./routes/Client/Conciliation/ClientSecondCasess";
import ClientBooking from "./routes/Client/Booking/ClinetBooking";

import ClientMeeting from "./routes/Client/Meeting/ClientMeeting";
import ClinetSingleCase from "./routes/Client/SingleCase/ClinetSingleCase";

// collection dashboard routes
import CollectionDashboard from "./routes/Collection-dashboard/dashboard";

import CollectionSettings from "./routes/Collection-dashboard/ProfileSetting/ProfileSetting";

import CollectionAllCases from "./routes/Collection-dashboard/cases/routes/list";
import CollectionCaseDetail from "./routes/Collection-dashboard/cases/routes/SingleCase/Casedetail";

import CollectionConciliation from "./routes/Collection-dashboard/Conciliation/CollectionConciliation";

import CollectionMedCaseDetail from "./routes/Collection-dashboard/Mediation/routes/SingleCase/Casedetail";

//  arbitrator dashboard routes
import Arbitratordashboard from "./routes/ArbitrationFirm/dashboard";
import ArbitProfile from "./routes/ArbitrationFirm/userProfile";
import ArbitPassword from "./routes/ArbitrationFirm/password";
import ArbitCases from "./routes/ArbitrationFirm/clients";

import Mediatordashboard from "./routes/Mediator/dashboard";
import MediatorCases from "./routes/Mediator/clients";

import MediationCases from "./routes/Mediator/mediation";

//  lawfirm dashboard routes
import Clients from "./routes/Lawfirm/clients";
import Pendingcases from "./routes/Lawfirm/pending-cases";
import Usersapproval from "./routes/Lawfirm/users-approvals";
import Notices from "./routes/Lawfirm/notices";
import Lawfirmprofile from "./routes/Lawfirm/userProfile/routes/add";
import GeneralSetting from "./routes/Lawfirm/general-setting/routes/add";
import Documents from "./routes/Lawfirm/upload/routes/add";
import Excelfile from "./routes/Lawfirm/upload-excel/routes/add";
import Signature from "./routes/Lawfirm/digital-sign/routes/add";
import Updatecases from "./routes/Lawfirm/digital-testing/routes/add";
import MessageUs from "./routes/Lawfirm/message-us/routes/add";
import AdminMessageUs from "./routes/message-us/routes/add";
import AdminAssignCase from "./routes/assign-case/routes/add";
import AdminAssignManager from "./routes/assign-manager/routes/add";
import AdminReassignManager from "./routes/re-assign-manager/routes/add";
import Lawfirmdashboard from "./routes/Lawfirm/dashboard";
import LawfirmNotifications from "./routes/Lawfirm/notifications";
import Template from "./routes/template";

//  mediation dashboard routes
import MediationClients from "./routes/MediationFirm/clients";
import MediationPendingcases from "./routes/MediationFirm/pending-cases";
import MediationUsersapproval from "./routes/MediationFirm/users-approvals";
import MediationNotices from "./routes/MediationFirm/notices";
import Mediationprofile from "./routes/MediationFirm/userProfile/routes/add";
import MediationDocuments from "./routes/MediationFirm/upload/routes/add";
import MediationExcelfile from "./routes/MediationFirm/upload-excel/routes/add";
import MediationSignature from "./routes/MediationFirm/digital-sign/routes/add";
import MediationUpdatecases from "./routes/MediationFirm/digital-testing/routes/add";
import Mediationdashboard from "./routes/MediationFirm/dashboard";
import MediationSingleCase from "./routes/MediationFirm/clients/routes/Components/files/caseDetails";


// import IndiaPost from "../app/routes/Indiapost"

// Advocate
import Advocatedashboard from "./routes/Advocates/dashboard/dashboard";
import AdvocateMeeting from "./routes/Advocates/Meeting/Meeting";
import AdvocaterespondantCases from "./routes/Advocates/respondent-cases";

// Case manager
import CaseManagerDashboard from "./routes/Case-manager/cases/routes/list";
import CaseManagerClients from "./routes/Case-manager/case-clients";

import CaseManagerCasedetail from "./routes/Case-manager/cases/routes/SingleCase/Casedetail";
import CasemanagerProfile from "./routes/Case-manager/case-m-userProfile";
import CasemanagerPasswprd from "./routes/Case-manager/case-m-password";

// manager
import ManagerDashboardCasedetail from "./routes/Manager/cases/routes/SingleCase/Casedetail";
import ManagerDashboard from "./routes/Manager/cases/routes/list";
import ManagerNotifications from "./routes/Manager/notifications";
import MangerClentList from "./routes/Manager/Client/routes/components/ListAll";
import ManagerPassword from "./routes/Manager/manager-password";
import ManagerProfile from "./routes/Manager/manager-userProfile";
import MangerCaseAdd from "./routes/Manager/Client/routes/components/Notes/AddCase";
import MangerCaseList from "./routes/Manager/Client/routes/components/files/RevenueTable";

//dharmani
import AppSetting from "../../src/app/routes/userProfile/routes/Components/AppSetting";
import Admincalender from "../../src/app/routes/Lawfirm/dashboard/routes/admincalender";
import Revenuetable from "../app/routes/Lawfirm/clients/routes/Components/files/sAdminClients";
import Revenuetble from "../app/routes/Lawfirm/clients/routes/Components/files/sAdminpendingClients";
import Trashtab from "../app/routes/Lawfirm/clients/routes/Components/files/trash";
import ListAll from "../app/routes/Lawfirm/clients/routes/Components/ListAllClients";
import Approvedlots from "../app/routes/Lawfirm/pending-cases/routes/approvedlotsadmin";
import Pendinglots from "../app/routes/Lawfirm/pending-cases/routes/pendinglotsadmin";
import caseDetailsadmin from "../app/routes/Lawfirm/pending-cases/routes/caseDetailsadmin";
import TrashLots from "../app/routes/Lawfirm/pending-cases/routes/lotsTrash";
import Advocatedetails from "../app/routes/Lawfirm/users-approvals/routes/adovatedetails";
import Arbitratordetails from "../app/routes/Lawfirm/users-approvals/routes/arbitratordetails";
import AdvocateDetailsView from "../app/routes/Lawfirm/users-approvals/routes/adovatedetailsView";
import ArbitratorDetailsView from "../app/routes/Lawfirm/users-approvals/routes/arbitratordetailsView";
import AdminUtilitysManage from "../app/routes/Lawfirm/clients/routes/Components/files/adminUtility";
import fullPageLoader from "../../src/components/Common/extra/fullPageLoader";
import eventEmitter from "../../src/util/emitter";

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../constants/ActionTypes";

import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "../components/TopNav";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Chat from "../chat";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.showLoader = this.showLoader.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
  }
  showLoader() {
    this.setState({ isLoading: true });
  }
  hideLoader() {
    this.setState({ isLoading: false });
  }
  componentDidMount() {
    eventEmitter.on("SHOW_FULL_LOADER", this.showLoader);
    eventEmitter.on("HIDE_FULL_LOADER", this.hideLoader);
  }
  componentWillUnmount() {
    eventEmitter.off("SHOW_FULL_LOADER", this.showLoader);
    eventEmitter.off("HIDE_FULL_LOADER", this.hideLoader);
  }

  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
        ? "collapsible-drawer"
        : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION
              ? "app-header-horizontal"
              : ""
              }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>
          <Chat />
          <main className="app-main-content-wrapper">
              {this.state.isLoading && fullPageLoader()}
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                <Route path={`${match.url}/users`} component={Users} />

                <Route
                  path={`${match.url}/arbit-approval`}
                  component={Arbitapproval}
                />
                <Route
                  path={`${match.url}/claimant-approval`}
                  component={Claimantapproval}
                />
                <Route
                  path={`${match.url}/respondent-approval`}
                  component={Respondentapproval}
                />
                <Route
                  path={`${match.url}/advocate-approval`}
                  component={AdvocateApproval}
                />
                <Route
                  path={`${match.url}/notification`}
                  component={PushNotification}
                />
                <Route
                  path={`${match.url}/contentPages`}
                  component={ContentPage}
                />
                <Route path={`${match.url}/faq`} component={Faq} />

                <Route path={`${match.url}/role`} component={AdminUser} />
                <Route
                  path={`${match.url}/permission`}
                  component={Permission}
                />
                {/* <Route path={`${match.url}/settings`} component={Settings} /> */}
                <Route path={`${match.url}/cache`} component={Systemsettings} />
                <Route path={`${match.url}/email`} component={Emailsetting} />
                <Route path={`${match.url}/profile`} component={Profile} />
                <Route
                  path={`${match.url}/contactquery`}
                  component={ContactQuery}
                />
                {/* Party routes */}
                <Route
                  path={`${match.url}/party/dashboard`}
                  component={Dashboardparty}
                />
                <Route
                  path={`${match.url}/party/change-password`}
                  component={Changepassowrd}
                />

                <Route
                  path={`${match.url}/party/mediation/second-party-cases`}
                  component={PartyMediationSecond}
                />

                <Route
                  path={`${match.url}/party/conciliation/file/fresh-case`}
                  component={PartyConciliationFresh}
                />
                <Route
                  path={`${match.url}/party/conciliation/filed-cases`}
                  component={PartyConciliationFiled}
                />
                <Route
                  path={`${match.url}/party/conciliation/second-party-cases`}
                  component={PartyConciliationSecond}
                />
                <Route
                  path={`${match.url}/party/conciliation`}
                  component={PartyConciliation}
                />

                <Route
                  path={`${match.url}/party/registered-cases`}
                  component={Registercases}
                />
                <Route
                  path={`${match.url}/party/all-respondant-cases`}
                  component={RespondantCases}
                />
                <Route
                  path={`${match.url}/party/case-detail`}
                  component={PartyCaseDetail}
                />
                <Route
                  path={`${match.url}/party/bookings`}
                  component={Bookings}
                />
                <Route
                  path={`${match.url}/party/profile`}
                  component={Partyprofile}
                />
                <Route
                  path={`${match.url}/party/join-meeting`}
                  component={Meeting}
                />

                {/* <Route path={`${match.url}/party/register-case`} component={Registerparty} /> */}

                {/* client */}

                <Route
                  path={`${match.url}/client/dashboard/`}
                  component={ClientDashboard}
                />

                <Route
                  path={`${match.url}/client/password-updae`}
                  component={ProfileSettings}
                />

                <Route
                  path={`${match.url}/client/all/cases`}
                  component={AllCases}
                />

                <Route
                  path={`${match.url}/case-detail/clinet`}
                  component={CaseDetail}
                />
                <Route
                  path={`${match.url}/mediation/case-detail/`}
                  component={MedCaseDetail}
                />

                <Route
                  path={`${match.url}/client/mediation/filed-cases`}
                  component={ClientMediationFiled}
                />

                <Route
                  path={`${match.url}/client/conciliation/file/fresh-case`}
                  component={ClientConciliationFresh}
                />
                <Route
                  path={`${match.url}/client/conciliation/filed-cases`}
                  component={ClientConciliationFiled}
                />
                <Route
                  path={`${match.url}/client/conciliation/second-party-cases`}
                  component={ClientConciliationSecond}
                />

                <Route
                  path={`${match.url}/client/booking`}
                  component={ClientBooking}
                />

                <Route
                  path={`${match.url}/client/join-meeting`}
                  component={ClientMeeting}
                />

                <Route
                  path={`${match.url}/clinet/single-case`}
                  component={ClinetSingleCase}
                />

                {/* Collection */}
                <Route
                  path={`${match.url}/collection/dashboard/`}
                  component={CollectionDashboard}
                />
                <Route
                  path={`${match.url}/collection/password-update`}
                  component={CollectionSettings}
                />

                <Route
                  path={`${match.url}/collection/all/cases`}
                  component={CollectionAllCases}
                />

                <Route
                  path={`${match.url}/case-detail/collection`}
                  component={CollectionCaseDetail}
                />

                <Route
                  path={`${match.url}/mediation/case-detail/`}
                  component={CollectionMedCaseDetail}
                />

                {/*case manager */}
                <Route
                  path={`${match.url}/casemanager/dashboard/`}
                  component={CaseManagerDashboard}
                />
                <Route
                  path={`${match.url}/casemanager/profile`}
                  component={CasemanagerProfile}
                />
                <Route
                  path={`${match.url}/casemanager/change-password`}
                  component={CasemanagerPasswprd}
                />
                <Route
                  path={`${match.url}/casemanager/client/`}
                  component={CaseManagerClients}
                />
                <Route
                  path={`${match.url}/casemanager/case-detail/`}
                  component={CaseManagerCasedetail}
                />
                {/* manager */}
                <Route
                  path={`${match.url}/lawfirm-manager/dashboard/`}
                  component={ManagerDashboard}
                />
                <Route
                  path={`${match.url}/lawfirm-manager/notification`}
                  component={ManagerNotifications}
                />
                <Route
                  path={`${match.url}/lawfirm-manager/case-detail/`}
                  component={ManagerDashboardCasedetail}
                />
                <Route
                  path={`${match.url}/lawfirm-manager/profile`}
                  component={ManagerProfile}
                />
                <Route
                  path={`${match.url}/lawfirm-manager/change-password`}
                  component={ManagerPassword}
                />
                <Route
                  path={`${match.url}/collection/mediation/filed-cases`}
                  component={ClientMediationFiled}
                />

                <Route
                  path={`${match.url}/collection/conciliation/file/fresh-case`}
                  component={ClientConciliationFresh}
                />

                <Route
                  path={`${match.url}/collection/conciliation/filed-cases`}
                  component={ClientConciliationFiled}
                />

                <Route
                  path={`${match.url}/collection/conciliation/second-party-cases`}
                  component={ClientConciliationSecond}
                />

                <Route
                  path={`${match.url}/collection/conciliation`}
                  component={CollectionConciliation}
                />

                <Route
                  path={`${match.url}/collection/booking`}
                  component={ClientBooking}
                />

                <Route
                  path={`${match.url}/collection/join-meeting`}
                  component={ClientMeeting}
                />

                <Route
                  path={`${match.url}/collection/single-case`}
                  component={ClinetSingleCase}
                />

                <Route
                  path={`${match.url}/manager/list/`}
                  component={MangerClentList}
                />

                 {/* dharmani */}  

                 <Route path="/app/settings" 
                  component={AppSetting} />

                 <Route path="/app/admin/dashboard" 
                  component={Admincalender} />

                 <Route path="/app/admin/all/approved/clients" 
                  component={Revenuetable} />

                 <Route path="/app/admin/all/pending/clients" 
                  component={Revenuetble} />

                 <Route path="/app/client/trash/documents"
                  component={Trashtab} />

                 <Route path="/app/admin/all/clients/list"
                  component={ListAll}
                 />

                 <Route path="/app/admin/all/approved/lots"
                  component={Approvedlots}
                 />

                 <Route path="/app/admin/all/pending/lots"
                  component={Pendinglots}
                 />

                 <Route path = "/app/admin/lot-cases/case-details/:editId"
                 component={caseDetailsadmin}
                 />

                 <Route path = "/app/admin/all/lot-cases/trash"
                  component={TrashLots}
                />

                <Route path="/app/admin/users-approval/advocates"
                  component={Advocatedetails}
                />

                <Route path="/app/admin/users-approval/arbitrators"
                  component={Arbitratordetails}
                />

                <Route
                  path={`${match.url}/admin/advocate/details/view/:id`}
                  component={AdvocateDetailsView}
                />

                <Route
                  path={`${match.url}/admin/arbitrator/details/view/:id`}
                  component={ArbitratorDetailsView}
                />

                <Route path="/app/admin/manage/utilities"
                  component={AdminUtilitysManage} 
                />

                  {/* dharmani */}

                <Route
                  path={`${match.url}/manager/case/add`}
                  component={MangerCaseAdd}
                />

                <Route
                  path={`${match.url}/manager/case-list`}
                  component={MangerCaseList}
                />
                {/* Arbitrator routes */}
                <Route
                  path={`${match.url}/arbitrator/dashboard/`}
                  component={Arbitratordashboard}
                />

                <Route
                  path={`${match.url}/mediator/dashboard/`}
                  component={Mediatordashboard}
                />

                <Route
                  path={`${match.url}/mediator/`}
                  component={MediatorCases}
                />

                <Route
                  path={`${match.url}/arbitrator/profile`}
                  component={ArbitProfile}
                />
                <Route
                  path={`${match.url}/arbitrator/change-password`}
                  component={ArbitPassword}
                />
                <Route
                  path={`${match.url}/arbitrator/`}
                  component={ArbitCases}
                />

                <Route
                  path={`${match.url}/mediation-process`}
                  component={MediationCases}
                />

                {/* {Mediation routes} */}
                <Route
                  path={`${match.url}/mediation-client`}
                  component={MediationClients}
                />

                <Route
                  path={`${match.url}/mediation-client/details`}
                  component={MediationSingleCase}
                />
                <Route
                  path={`${match.url}/mediation/pending-cases/`}
                  component={MediationPendingcases}
                />
                <Route
                  path={`${match.url}/mediation/users-approval/`}
                  component={MediationUsersapproval}
                />
                <Route
                  path={`${match.url}/mediation/notices/`}
                  component={MediationNotices}
                />
                <Route
                  path={`${match.url}/mediation/profile`}
                  component={Mediationprofile}
                />
                <Route
                  path={`${match.url}/mediation/documents`}
                  component={MediationDocuments}
                />
                <Route
                  path={`${match.url}/mediation/dashboard`}
                  component={Mediationdashboard}
                />
                {/* <Route path={`${match.url}/template`} component={MediationTemplate} /> */}
                <Route
                  path={`${match.url}/mediation/excel`}
                  component={MediationExcelfile}
                />
                <Route
                  path={`${match.url}/mediation/signature`}
                  component={MediationSignature}
                />
                <Route
                  path={`${match.url}/mediation/update-cases`}
                  component={MediationUpdatecases}
                />

                {/* Lawfirm routes */}
                <Route path={`${match.url}/client`} component={Clients} />
                <Route
                  path={`${match.url}/lawfirm/pending-cases/`}
                  component={Pendingcases}
                />
                <Route
                  path={`${match.url}/lawfirm/users-approval/`}
                  component={Usersapproval}
                />
                <Route
                  path={`${match.url}/lawfirm/notices/`}
                  component={Notices}
                />
                <Route
                  path={`${match.url}/lawfirm/profile`}
                  component={Lawfirmprofile}
                />
                <Route
                  path={`${match.url}/lawfirm/general`}
                  component={GeneralSetting}
                />

                <Route
                  path={`${match.url}/admin/general`}
                  component={GeneralSetting}
                />

                <Route
                  path={`${match.url}/lawfirm/documents`}
                  component={Documents}
                />
                <Route
                  path={`${match.url}/lawfirm/dashboard`}
                  component={Lawfirmdashboard}
                />
                <Route
                  path={`${match.url}/lawfirm/notifications`}
                  component={LawfirmNotifications}
                />
                <Route path={`${match.url}/template`} component={Template} />
                <Route
                  path={`${match.url}/lawfirm/excel`}
                  component={Excelfile}
                />
                <Route
                  path={`${match.url}/lawfirm/signature`}
                  component={Signature}
                />

                <Route
                  path={`${match.url}/admin/signature`}
                  component={Signature}
                />

                <Route
                  path={`${match.url}/lawfirm/update-cases`}
                  component={Updatecases}
                />
                <Route
                  path={`${match.url}/lawfirm/message-us`}
                  component={MessageUs}
                />
                <Route
                  path={`${match.url}/admin/message-us`}
                  component={AdminMessageUs}
                />
                <Route
                  path={`${match.url}/admin/assign-case`}
                  component={AdminAssignCase}
                />
                <Route
                  path={`${match.url}/admin/assign-manager`}
                  component={AdminAssignManager}
                />
                <Route
                  path={`${match.url}/admin/reassign-manager`}
                  component={AdminReassignManager}
                />

                {/* Advocate routes */}
                <Route
                  path={`${match.url}/advocate/dashboard/`}
                  component={Advocatedashboard}
                />
                <Route
                  path={`${match.url}/advocate/change-password`}
                  component={Changepassowrd}
                />

                <Route
                  path={`${match.url}/advocate/mediation/file/fresh-case`}
                  component={PartyConciliationFresh}
                />
                <Route
                  path={`${match.url}/advocate/mediation/filed-cases`}
                  component={PartyConciliationFiled}
                />
                <Route
                  path={`${match.url}/advocate/mediation/second-party-cases`}
                  component={PartyMediationSecond}
                />

                <Route
                  path={`${match.url}/advocate/conciliation/file/fresh-case`}
                  component={PartyConciliationFresh}
                />
                <Route
                  path={`${match.url}/advocate/conciliation/filed-cases`}
                  component={PartyConciliationFiled}
                />
                <Route
                  path={`${match.url}/advocate/conciliation/second-party-cases`}
                  component={PartyConciliationSecond}
                />

                <Route
                  path={`${match.url}/advocate/registered-cases`}
                  component={Registercases}
                />
                <Route
                  path={`${match.url}/advocate/all-respondant-cases/`}
                  component={AdvocaterespondantCases}
                />
                <Route
                  path={`${match.url}/advocate/case-detail`}
                  component={PartyCaseDetail}
                />
                <Route
                  path={`${match.url}/advocate/bookings`}
                  component={Bookings}
                />
                <Route
                  path={`${match.url}/advocate/profile`}
                  component={Partyprofile}
                />
                <Route
                  path={`${match.url}/advocate/join-meeting`}
                  component={AdvocateMeeting}
                />
                {/* <Route 
                  path={`${match.url}/Indiapost`}
                    
                     component={IndiaPost} /> */}

                <Route
                  component={asyncComponent(() =>
                    import("../app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <ToastContainer autoClose={2000} />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
