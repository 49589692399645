import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { v4 as uuidv4 } from "uuid";
import { Link } from "../../../../../components";

const MyCalendar = ({ List = [], onDateRangeChange }) => {
  const [events, setEvents] = useState([]);
  const [id, setId] = useState();

  useEffect(() => {
    const formattedEvents =
      List.length > 0 &&
      List.reduce((acc, caseItem) => {
        const hashId = caseItem.hash_id;

        if (caseItem.hearing && Array.isArray(caseItem.hearing)) {
          caseItem.hearing.forEach((hearingItem) => {
            if (hearingItem.hearing) {
              const hearing = hearingItem.hearing;
              const startDateTime = `${hearing.start_date.split(" ")[0]}T${
                hearing.start_time
              }`;
              acc.push({
                title: hearing.topic,
                start: startDateTime,
                end: startDateTime,
                id: uuidv4(),
                hash_id: hashId,
              });
              setId(hashId);
            }
          });
        }
        return acc;
      }, []);
    setEvents(formattedEvents);
  }, [List]);

  const handleSelect = (info) => {
    const { start, end } = info;
    const eventNamePrompt = prompt("Enter event name");
    if (eventNamePrompt) {
      setEvents([
        ...events,
        {
          start,
          end,
          title: eventNamePrompt,
          id: uuidv4(),
        },
      ]);
    }
  };

  // const handleDatesSet = (info) => {
  //   const { startStr, endStr } = info;
  //   console.log("Visible date range:", { startStr, endStr });
  //   if (onDateRangeChange) {
  //     onDateRangeChange({ startStr, endStr });
  //   }
  // };

  const handleDatesSet = (info) => {
    const { startStr, endStr, view } = info;
    const currentDate = new Date(startStr);
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString();
    if (view.type === "dayGridWeek" || view.type === "dayGridDay") {
      console.log("Adjusted Visible Date Range:", { startStr: firstDayOfMonth, endStr });
      if (onDateRangeChange) {
        onDateRangeChange({ startStr: firstDayOfMonth, endStr });
      }
    } else {
      console.log("Visible Date Range:", { startStr, endStr });
      if (onDateRangeChange) {
        onDateRangeChange({ startStr, endStr });
      }
    }
  };

  const EventItem = ({ info }) => {
    const { event } = info;
    return (
      <div>
        <Link to={`/app/client/case-detail/${event.extendedProps.hash_id}`}>
          <p className="event-title">{event.title}</p>
        </Link>
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        editable
        selectable
        headerToolbar={{
          start: "today",
          center: "title",
          end: "prev,dayGridMonth,dayGridWeek,dayGridDay,next",
        }}
        titleFormat={{ year: "numeric", month: "long" }}
        allDayText="All Day"
        timeZone="UTC"
        events={events}
        eventContent={(info) => <EventItem info={info} />}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        dayMaxEvents={1}
        datesSet={handleDatesSet}
      />
    </div>
  );
};

export default MyCalendar;