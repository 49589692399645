import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
// import CsvDownloader from "react-csv-downloader";
// import Select from "@material-ui/core/Select";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import { FaEdit, FaTrashAlt, FaMapMarkedAlt, FaEllipsisV } from 'react-icons/fa';
import Checkbox from "../../../../common/checkbox";
import {
  Link,
  ApiUrl,
  Axios,
  DialogBox,
  Row,
  Col,
  SELECT,
  toast,
  Loading,
  TEXTFIELD,
  moment,
  SUBMIT,
} from "../../../../../../../components";

// import loaderImg from "../../../../../../../assets/images/loading.gif";
// import FaqList from "../../../../../template/routes/Components/FaqList";
// import AddEditForm from "../../../../../template/routes/Components/FaqForm";
// import Headersets from "../headersets/ViewForm";
// import ArbitList from "../arbitlist";
// import filter from "../../../../../../../assets/images/icone-de-filtre-grise.png";
// import AddForm from "./hearingForm";
// import SelectSearch from "./selectSearch";
// import Tooltip from "@material-ui/core/Tooltip";

const {
  // API_GET_USERS_CASE,
  // API_GENERATE_CASES,
  // API_DELETE_CASES,
  // API_GET_USER_TEMPLATES_URL,
  // API_GET_USER_STAGES_URL,
  // API_FILTER_CASE,
  // API_FILTER_TRACKER_CASE,
  // API_FILTER_SELECT_DATA,
  API_CLIENT_FILTER_DELETED_SEARCH,
  // API_AUDIT_LOGS,
  // API_GET_SETTING_DOC,
  // API_GET_CASE_STATUS,
  // API_UPDATE_CASE_STATUS,
  // API_UPDATE_CASE_STAGE,
  // API_GET_DOCUMENTS_URL,
  // API_SUBMIT_EXCEL,
  DELETED_CASES_BULK_SPECIFIC,
  RESTORE_DELETED_CASES,
} = ApiUrl;

const Revenuetable = (props) => {
  // const [show, setShow] = useState(false);
  // const [showNoticeM, setShowNoticeM] = useState(false);
  // const [showNoticeT, setShowNoticeT] = useState(false);
  // const [showNoticeCerti, setShowNoticeCerti] = useState(false);
  // const [showStatus, setShowStatus] = useState(false);
  // const [showStages, setShowStages] = useState(false);
  // const [showNoticeH, setShowNoticeH] = useState(false);
  // const [showNoticeA, setShowNoticeA] = useState(false);
  const [showNoticeAss, setShowNoticeAss] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // const [respondentNames, setRespondentNames] = useState([]);
  // const [showRespondentModal, setShowRespondentModal] = useState(false);
  // const [selectedRespondent, setSelectedRespondent] = useState(null);
  // const [state, setState] = useState({
  //   temp: "",
  //   stage: "",
  //   title: "",
  //   type: "",
  //   document: "",
  //   start_date: "",
  //   temp_name: "",
  //   disabled: false,
  // });

  // const [error, setError] = useState();
  // const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [showVenue, setVenue] = useState(false);
  // const [add, setAdd] = useState(false);
  // const [editTeId, setEditid] = useState();
  // const [status_id, setStatus_id] = useState();
  // const [stageid, setStageId] = useState();
  // const [stagename, setStageName] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [dropdownState, setDropdownState] = useState(null);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  // const [certifiedStamp, setcertifiedStamp] = useState([]);
  // const [temp, setTemp] = useState([]);
  // const [stage, setStage] = useState([]);
  // const [documentList, setDocumentList] = useState([]);
  // const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  // const [showTrackerModal, setShowTrackerModal] = useState(false);
  const [count, setCount] = useState();
  const [responseData, setResponseData] = useState([]);
  const [trackerData, settrackerData] = useState([]);
  // const [stateData, setStateData] = useState([]);
  // const [venueData, setVenueData] = useState([]);
  // const [arbitratorData, setArbitratorData] = useState([]);
  // const [stagingData, setStagingData] = useState([]);
  // const [seatData, setSeatData] = useState([]);
  // const [lotData, setLotData] = useState([]);
  // const [clientData, setClientData] = useState([]);
  // const [caseStatus, setcaseStatus] = useState([]);
  const [formData, setFormData] = useState({
    status: "",
    created_at: "",
    created_at_from: "",
    created_at_to: "",
    arbitrator: "",
    hearing_date: "",
    state: "",
    seat: "",
    venue: "",
    lot: "",
    client: "",
    stage: "",
    title: "",
    msg_type: "",
    msg_status: "",
    fieldValue: "",
    fieldName: "",
    notes: "",
    hearing_date_from: "",
    hearing_date_to: "",
    final_status: "",
  });
  const [tech, setTech] = useState("basic");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [checkedItems, setCheckedItems] = useState({
    estamp: "",
    esign: "",
  });

  const handleClicked = (e, id) => {
    if (e.target.checked) {
      setIsCheck([...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // const excelData = list?.map((data) => {
  //   return {
  //     id: data.id,
  //     created_at: data?.created_at,
  //     case_number: data?.case_number,
  //     name: data?.case_master_users
  //       .filter(
  //         (i) =>
  //           (i?.related_user_role === 'Client' &&
  //             i?.related_user_type === 'claimant') ||
  //           (i?.related_user_role === 'Party' &&
  //             i?.related_user_type === 'respondent')
  //       )
  //       .map((i) => {
  //         if (
  //           i?.related_user_role === 'Client' &&
  //           i?.related_user_type === 'claimant'
  //         ) {
  //           return `${i?.related_user?.name} vs`;
  //         } else if (
  //           i?.related_user_role === 'Party' &&
  //           i?.related_user_type === 'respondent'
  //         ) {
  //           return i?.related_user?.name;
  //         } else {
  //           return '';
  //         }
  //       })
  //       .join(' '),
  //   };
  // });

  // If no items are selected, use the full data, otherwise use only the selected items
  // const filteredData = isCheck.length > 0 ? excelData.filter((data) => isCheck.includes(data.id)) : excelData;

  // const excelData =
  //   list &&
  //   list.map((data) => {
  //     return {
  //       created_at: data?.created_at,
  //       case_number: data?.case_number,
  //       name: data?.case_master_users
  //         .filter(
  //           (i) =>
  //             (i?.related_user_role === "Client" &&
  //               i?.related_user_type === "claimant") ||
  //             (i?.related_user_role === "Party" &&
  //               i?.related_user_type === "respondent")
  //         )
  //         .map((i) => {
  //           if (
  //             i?.related_user_role === "Client" &&
  //             i?.related_user_type === "claimant"
  //           ) {
  //             return `${i?.related_user?.name} vs`;
  //           } else if (
  //             i?.related_user_role === "Party" &&
  //             i?.related_user_type === "respondent"
  //           ) {
  //             return i?.related_user?.name;
  //           } else {
  //             return "";
  //           }
  //         })
  //         .join(" "),
  //     };
  //   });
  // .reverse();

  // const columns = [
  //   {
  //     id: "created_at",
  //     displayName: "Created At",
  //     type: "date",
  //   },
  //   {
  //     id: "name",
  //     displayName: "Name",
  //   },
  //   {
  //     id: "case_number",
  //     displayName: "Case Number",
  //   },
  // ];

  // const [detail, setDetail] = useState({
  //   name: "",
  //   email: "",
  //   clientId: "",
  // });

  // useEffect(() => {
  //   if (props.detail.name) {
  //     localStorage.setItem("Name", props.detail.name);
  //   }
  //   if (props.detail.email) {
  //     localStorage.setItem("Email", props.detail.email);
  //   }
  //   if (props.detail.id) {
  //     localStorage.setItem("Id", props.detail.id);
  //   }
  //   setDetail({
  //     name: localStorage.getItem("Name"),
  //     email: localStorage.getItem("Email"),
  //     clientId: localStorage.getItem("Id"),
  //   });
  // }, [props.detail.name, props.detail.email, props.detail.id]);

  // const handleCancel = () => {
  //   setShow(false);
  // };
  // const handlePreview = () => {
  //   setShow(true);
  // };
  // const handleCancelN = () => {
  //   setShowNoticeM(false);
  // };
  // const handleCancelAss = () => {
  //   setShowNoticeAss(false);
  // };
  // const handlePreviewAss = () => {
  //   setShowNoticeAss(true);
  // };
  // const handlePreviewN = () => {
  //   setShowNoticeM(true);
  // };
  // const handleCancelT = () => {
  //   setShowNoticeT(false);
  // };
  // const handleCancelCerti = () => {
  //   setShowNoticeCerti(false);
  // };
  // const handlePreviewT = () => {
  //   setShowNoticeT(true);
  //   setAdd(false);
  // };

  const handleShowModal = () => {
    setShowConfirmModal(true);
  };

  const handleCancelModal = () => {
    setShowConfirmModal(false);
  };

  // const handlePreviewCerti = () => {
  //   setShowNoticeCerti(true);
  // };
  // const handlePreviewStatus = () => {
  //   setShowStatus(true);
  // };
  // const handlePreviewStage = () => {
  //   setShowStages(true);
  // };
  // const handleCancelH = () => {
  //   setShowNoticeH(false);
  // };
  // const handleCancelStatus = () => {
  //   setShowStatus(false);
  // };
  // const handleCancelStages = () => {
  //   setShowStages(false);
  // };
  // const handlePreviewH = () => {
  //   setShowNoticeH(true);
  // };
  // const handleCancelA = () => {
  //   setShowNoticeA(false);
  // };
  // const handlePreviewA = () => {
  //   setShowNoticeA(true);
  // };

  const showErrorN = () => {
    toast.error("Please select checkbox first");
  };

  // const handleDateChange = (e) => {
  //   const { name, value } = e.target;

  //   const formattedDate = value
  //     ? new Date(value).toISOString().split("T")[0]
  //     : "";
  //   setFormData({ ...formData, [name]: formattedDate });
  // };
  // const handleHearingDateChange = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     hearing_date: value,
  //   }));
  // };
  // const handleStatusChange = (e) => {
  //   const { value } = e.target;
  //   console.log("value35465", value);
  //   setStatus_id(value);
  // };
  // const handleStageChange = (e) => {
  //   const { value } = e.target;
  //   console.log("value35465", value);
  //   setStageId(value);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   let data = {
  //     status_id: status_id,
  //     case_ids: isCheck,
  //   };

  //   Axios.Post(API_UPDATE_CASE_STATUS, data)
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         toast.success(res.data.message);
  //         setTimeout(() => {
  //           handleCancelStatus();
  //           getTableRecords();
  //         }, 1000);
  //       } else {
  //         toast.error(res.data.error);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error, "res.message");
  //     });
  // };

  // const handleStage = (e) => {
  //   e.preventDefault();
  //   let data = {
  //     stage: stageid,
  //     case_ids: isCheck,
  //   };

  //   Axios.Post(API_UPDATE_CASE_STAGE, data)
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         toast.success(res.data.message);
  //         setTimeout(() => {
  //           handleCancelStages();
  //           getTableRecords();
  //         }, 1000);
  //       } else {
  //         toast.error(res.data.error);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error, "res.message");
  //     });
  // };

  // const handleChangeTitle = (e) => {
  //   const { value } = e.target;
  //   setState((prevState) => ({
  //     ...prevState,
  //     title: value,
  //   }));
  // };
  // const handleChangeTempname = (e) => {
  //   const { value } = e.target;
  //   setState((prevState) => ({
  //     ...prevState,
  //     temp_name: value,
  //   }));
  // };
  // const handleChangeType = (e) => {
  //   const { value } = e.target;
  //   setState((prevState) => ({
  //     ...prevState,
  //     type: value,
  //   }));
  // };

  // useEffect(() => {
  //   if (state.type) {
  //     const timeoutId = setTimeout(() => {
  //       getDocument(state.type);
  //     }, 1000);
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [state.type]);

  // const handleChangeState = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     state: value,
  //   }));
  // };
  // const handleChangeSeat = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     seat: value,
  //   }));
  // };
  // const handleChangeLot = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     lot: value,
  //   }));
  // };
  // const handleChangeClient = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     client: value,
  //   }));
  // };
  // const handleChangeNotes = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     notes: value,
  //   }));
  // };
  // const handleChangeDateRange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };
  // const handleFiledDateRange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const downloadFile = async () => {
  //   try {
  //     const data = {
  //       cases_id: isCheck,
  //     };
  //     const response = await Axios.Post(
  //       "https://justsettle.in/backend/api/v1/export/mis/cases",
  //       data,
  //       {
  //         responseType: "blob",
  //       }
  //     );
  //     console.log(response, "response123");
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const a = document.createElement("a");

  //     a.href = url;
  //     a.download = "files.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //     handleCancelAss(false);
  //   } catch (error) {
  //     console.error("Error downloading ZIP file:", error.message);
  //   }
  // };

  // useEffect(() => {
  //   getTemplates();
  //   getStage();
  //   // filterData("state");
  //   // filterData("venue");
  //   // filterData("stages");
  //   // filterData("arbitrator");
  //   // filterData("seat");
  //   // filterData("lawFirmClient");
  //   getStatus();
  // }, []);

  // useEffect(() => {
  //   if (detail.clientId) {
  //     gettingLotData();
  //   }
  // }, [detail.clientId]); 
  
  useEffect(() => {
    getTableRecords();
    // getSettingRecords();
  }, []);

  useEffect(() => {
    if (list.length === 0) {
      setIsCheckAll(false);
    }
  }, [list]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleChecked = (e) => {
  //   const { name, checked } = e.target;
  //   setCheckedItems((prevState) => ({
  //     ...prevState,
  //     [name]: checked,
  //   }));
  // };

  // const handleChangeRadio = (e) => {
  //   const { name, value } = e.target;
  //   setTech(value);
  //   if (value == "basic") {
  //     setCheckedItems("");
  //   }
  // };

  // const handleClickOpen = () => {
  //   setOpen(true);
  //   // setId(id)
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleClick = (e) => {
  //   const { id, checked } = e.target;
  //   setIsCheck([...isCheck, id]);
  //   if (!checked) {
  //     setIsCheck(isCheck.filter((item) => item !== id));
  //   }
  // };

  // const handleClick = (e, id) => {
  //   const isChecked = isCheck.includes(id);
  //   if (isChecked) {
  //     setIsCheck(isCheck.filter((item) => item !== id));
  //   } else {
  //     setIsCheck([...isCheck, id]);
  //   }
  // };
  // const handleConfirmSelection = () => {
  //   const filteredFormData = Object.fromEntries(
  //     Object.entries(formData).filter(([_, value]) => value !== "")
  //   );

  //   const id = {
  //     specificId: detail.clientId,
  //   };

  //   const payload = {
  //     ...filteredFormData,
  //     ...id,
  //   };

  //   Axios.post(API_FILTER_CASE, payload)
  //     .then((response) => {
  //       setList(response.data?.data);
  //       setCount(response.data?.data?.length);
  //       setShowModal(false);
  //       setFormData({});
  //       // setResponseData(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred while fetching data:", error);
  //     });
  // };

  // const handleTrackerSelection = () => {
  //   const filteredFormData = Object.fromEntries(
  //     Object.entries(formData).filter(([_, value]) => value !== "")
  //   );
  //   Axios.Post(API_FILTER_TRACKER_CASE, filteredFormData)
  //     .then((response) => {
  //       console.log(response, "ree");
  //       if (response.data.success === true) {
  //         setList(response.data?.data);
  //         setCount(response.data?.data?.length);
  //         setShowTrackerModal(false);
  //         setFormData({});
  //         // settrackerData(response.data.data);
  //       } else {
  //         console.log(
  //           response.data?.error.msg_status[0],
  //           response.data?.error.msg_type[0],
  //           "response.data.error"
  //         );
  //         toast.error(
  //           response.data?.error.msg_status[0],
  //           response.data?.error.msg_type[0]
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred while fetching data:", error);
  //     });
  // };

  // const auditLogs = () => {
  //   const payload = {
  //     cases_id: isCheck,
  //   };
  //   Axios.post(API_AUDIT_LOGS, payload)
  //     .then((response) => {
  //       const { filename, zip_content } = response.data;

  //       const byteCharacters = atob(zip_content);
  //       const byteNumbers = new Array(byteCharacters.length);
  //       for (let i = 0; i < byteCharacters.length; i++) {
  //         byteNumbers[i] = byteCharacters.charCodeAt(i);
  //       }
  //       const byteArray = new Uint8Array(byteNumbers);

  //       const blob = new Blob([byteArray], { type: "application/zip" });

  //       const url = URL.createObjectURL(blob);

  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = filename;
  //       document.body.appendChild(link);

  //       link.click();

  //       document.body.removeChild(link);
  //     })

  //     .catch((error) => {
  //       console.error("Error occurred while fetching data:", error);
  //     });
  // };

  useEffect(() => {
    if (confirmDelete) {
      restoreCases();
    }
  }, [confirmDelete]);

  useEffect(() => {
    if (showConfirmModal) {
      setConfirmDelete(false);
    }
  }, [showConfirmModal]);

  const restoreCases = () => {
    if (confirmDelete) {
      const payload = {
        cases_id: isCheck,
      };
      try {
        setDisabled(true);
        setShowConfirmModal(false);
        Axios.post(RESTORE_DELETED_CASES, payload)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Case restored successfully");
              const updatedList = list.filter((item) => !isCheck.includes(item.id));
              setList(updatedList);
              setDropdownState(null);
              setIsCheck([]);
              getTableRecords()
            } else {
              toast.error("Error deleting case");
            }
          })
          .catch((err) => {
            const errorMessage = err.response?.data?.message || "Error deleting case";
            toast.error(errorMessage);
          })
          .finally(() => {
            setDisabled(false);
            setDropdownState(null);
            setShowConfirmModal(false);
          });
      } catch (err) {
        toast.error("Unexpected error occurred while deleting case.");
        setDisabled(false);
        setDropdownState(null);
        setShowConfirmModal(false);
      }
    }
  };

  const searchFilters = (value) => {
    setLoading(true)
    const clientId = localStorage.getItem("Client_id");
    let data = {};
    let url = API_CLIENT_FILTER_DELETED_SEARCH;
    if (value.fieldName == "client") {
      data = `?client_name=${value.fieldValue}`;
    } else if (value.fieldName == "respondent_name") {
      data = `?respondent_name=${value.fieldValue}`;
    } else if (value.fieldName == "LAN") {
      data = `?LAN=${value.fieldValue}`;
    } else {
      data = `?case_number=${value.fieldValue}`;
    }
    Axios.get(url + clientId + "/case/" + data)
      .then((response) => {
        console.log(response, "responsexzcxzczxc");
        var data = response.data.data;
        console.log(data, "xzcxzczxc");
        setList(data);
        setLoading(false);
      })
      .catch((error) => {
        // toast.error("No data found");
      });
  };

  // const handleStartDateChange = (event) => {
  //   const { value } = event.target;
  //   setState((prev) => ({
  //     ...prev,
  //     start_date: value,
  //     disabled: true,
  //   }));
  // };

  const removefilter = () => {
    setFormData((prev) => ({ ...prev, fieldValue: "", fieldName: "" }));
    setShowSearch(!showSearch);
    refresh();
  };

  const handleChangeKey = (e) => {
    const { name, value } = e.target;
    if (name === "fieldName") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        fieldValue: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    const searchData =
      name === "fieldName"
        ? { fieldName: value, fieldValue: "" }
        : { fieldName: formData.fieldName, fieldValue: value };

    if (value.trim() !== "") {
      searchFilters(searchData);
    }
  };

  // const getStage = async () => {
  //   Axios.get(API_GET_USER_STAGES_URL)
  //     .then((response) => {
  //       const stage = response.data;
  //       console.log(stage, "stage");
  //       const stages = stage.data.map((s) => {
  //         return { [s.id]: s.stage };
  //       });
  //       setStage(stages);
  //       const stagesId = stage?.data?.map((s) => {
  //         return { [s.stage]: s.stage };
  //       });
  //       setStageName(stagesId);
  //     })
  //     .catch((err) => {
  //       console.log("");
  //     });
  // };

  // const getDocument = async (type) => {
  //   Axios.get(API_GET_DOCUMENTS_URL + `${type}`)
  //     .then((response) => {
  //       const stage = response.data;
  //       const stages = stage.data.map((s) => {
  //         return { [s.id]: s.name };
  //       });
  //       setDocumentList(stages);
  //     })
  //     .catch((err) => {
  //       console.log("");
  //     });
  // };

  // const getStatus = async () => {
  //   Axios.get(API_GET_CASE_STATUS)
  //     .then((response) => {
  //       const stage = response.data;
  //       console.log(stage, "stage");
  //       const status = stage.data.map((s) => {
  //         return { [s.id]: s.name };
  //       });
  //       setcaseStatus(status);
  //     })
  //     .catch((err) => {
  //       console.log("");
  //     });
  // };

  // const filterData = async (type) => {
  //   try {
  //     const response = await Axios.get(API_FILTER_SELECT_DATA + `${type}`);
  //     if (type === "state") {
  //       var data = response.data.data;
  //       const statedata =
  //         data?.length > 0 &&
  //         data.map((h, id) => {
  //           return { [h.state]: h.state };
  //         });
  //       setStateData(statedata);
  //     } else if (type === "venue") {
  //       var data = response.data.data;
  //       const venuedata =
  //         data?.length > 0 &&
  //         data.map((h) => {
  //           return { [h.venue]: h.venue };
  //         });
  //       setVenueData(venuedata);
  //     }
  //     //  else if (type === "stages") {
  //     //   setStagesData(response.data.data.map((item) => item.stage));
  //     //   value;
  //     // }
  //     else if (type === "arbitrator") {
  //       var data = response.data.data;
  //       const arbitdata =
  //         data?.length > 0 &&
  //         data.map((h, id) => {
  //           return { [h.name]: h.name };
  //         });
  //       setArbitratorData(arbitdata);
  //     } else if (type === "seat") {
  //       var data = response.data.data;
  //       const seatdata =
  //         data.length > 0 &&
  //         data.map((h) => {
  //           return { [h.seat]: h.seat };
  //         });
  //       setSeatData(seatdata);
  //     } else if (type === "stages") {
  //       var data = response.data.data;

  //       setStagingData(response.data.data);
  //     } else if (type === "lawFirmClient") {
  //       var data = response.data.data;
  //       const clientdata =
  //         data?.length > 0 &&
  //         data.map((h, id) => {
  //           return { [h.id]: h.name };
  //         });
  //       setClientData(clientdata);
  //     }
  //   } catch (error) {
  //     console.error("Error filtering data");
  //   }
  // };

  // const gettingLotData = () => {
  //   const id = detail.clientId;
  //   console.log(id, "4120");
  //   const data = `lot?clientId=${id}`;
  //   Axios.get(API_FILTER_SELECT_DATA + data)
  //     .then((response) => {
  //       var data = response.data.data;
  //       const statedata =
  //         data?.length > 0 &&
  //         data.map((h, id) => {
  //           return { [h.id]: h.title };
  //         });
  //       setLotData(statedata);
  //     })
  //     .catch((error) => {
  //       // toast.error("No data found");
  //     });
  // };

  // const getTableRecords = async () => {
  //   setLoading(true);
  //   Axios.Get(API_GET_USERS_CASE + props.editId)
  //     .then((response) => {
  //       const resData = response.data;
  //       if (response.status == 200) {
  //         const updatedList = resData.data.map((item) => {
  //           const arbitrator = item?.case_master_users?.find(
  //             (user) => user?.related_user_role === "Arbitrator"
  //           );
  //           return {
  //             ...item,
  //             arbitratorName: arbitrator?.related_user?.name || "No Arbitrator Assigned",
  //           };
  //         });
  //         setList(updatedList);
  //         setCount(resData?.data?.length);
  //         setLoading(false);
  //       } else {
  //         toast.error(resData.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "wwwwwwwww");
  //     });
  // };

  const getTableRecords = async () => {
    setLoading(true);
    const hash = window.location.hash;
    const extractedValue = hash.split('/').pop();
    const apiUrl = `${DELETED_CASES_BULK_SPECIFIC}/${extractedValue}`;
    Axios.get(apiUrl)
      .then((response) => {
        const resData = response.data.data;
        if (response.status === 200) {
          setList(resData.data || []);
          setCount(resData?.data?.length);
          setLoading(false);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };

  // const getSettingRecords = async () => {
  //   setLoading(true);
  //   Axios.Get(API_GET_SETTING_DOC)
  //     .then((response) => {
  //       const resData = response.data;
  //       if (response.status == 200) {
  //         setcertifiedStamp(resData);
  //       } else {
  //         console.log("check");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "wwwwwwwww");
  //     });
  // };

  // const getTemplates = async () => {
  //   Axios.post(API_GET_USER_TEMPLATES_URL + props.editId)
  //     .then((response) => {
  //       const temp = response.data;
  //       console.log(temp, "temp");
  //       const temps = temp?.data.map((t) => {
  //         return { [t.id]: t.name };
  //       });
  //       setTemp(temps);
  //     })
  //     .catch((err) => {
  //       console.log("");
  //     });
  // };

  // const submitExcel = () => {
  //   console.log(file, "after file456");

  //   const data = new FormData();
  //   data.append("case_file", file);
  //   data.append("client_id", detail.clientId);

  //   Axios.Post(API_SUBMIT_EXCEL, data)
  //     .then((response) => {
  //       console.log(response, "ree");
  //       if (response.data.success === true) {
  //         setShowTrackerModal(false);
  //         setList(response.data.data);
  //         setFile("");
  //       } else {
  //         toast.error(response.data.error);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred while fetching data:", error);
  //     });
  // };

  // const handleRespondentSelect = (respondent) => {
  //   setSelectedRespondent(respondent);
  //   setShowRespondentModal(false);
  // };

  const refresh = () => {
    getTableRecords();
    setCount();
    setResponseData([]);
    settrackerData([]);
  };

  // const handleDownload = async (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   try {
  //     const data = {
  //       cases_id: isCheck,
  //       case_stage: state.stage,
  //       template_id: state.temp,
  //       notice_type: state.type,
  //       start_date: state.start_date,
  //       title: state.title,
  //       document: state.document,
  //       basic: tech,
  //       estamp: checkedItems.estamp,
  //       esign: checkedItems.esign,
  //       temp_name: state.temp_name,
  //     };
  //     const response = await Axios.Post(API_GENERATE_CASES, data, {
  //       responseType: "blob",
  //     });
  //     console.log(response, "response123");
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const a = document.createElement("a");

  //     a.href = url;
  //     a.download = "files.zip";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //     handleCancelAss(false);
  //   } catch (error) {
  //     console.error("Error downloading ZIP file:", error.message);
  //   } finally {
  //     setLoading(false); // Stop loader
  //   }
  // };

  // const deleteAction = (id) => {
  //   Axios.post(API_DELETE_CASES + id)
  //     .then((res) => {
  //       setOpen(false);
  //       toast.success(res.data.message);
  //     })
  //     .catch((err) => {
  //       setOpen(false);
  //       toast.success("Cases does not deleted!");
  //     });
  // };

  // const handlenotassignChange = (e) => {
  //   const { value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     not_have_hearing_date: value,
  //   }));
  // };

  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };

  // const handleFile = (event) => {
  //   event.preventDefault();
  //   let selectedFile = event.target.files[0];
  //   let newErr = { file: "" };
  //   console.log(selectedFile, "filefile");

  //   setFile(selectedFile);
  //   setFileName(selectedFile.name);
  // };

  // const handleOpenModal1 = () => {
  //   setShowModal(true);
  // };
  // const handleOpenModalTracker = () => {
  //   setShowTrackerModal(true);
  // };
  // const handleCloseModal = () => setShowModal(false);
  // const handleCloseTrackerModal = () => setShowTrackerModal(false);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}.${month}.${year}`;
  };

  const cataloge =
    list &&
    list?.length > 0 &&
    list.slice(0, limit).map((item, index) => {
      const partyRespondentVs = item.case_master_users.find(
        (i) =>
          i.related_user_role === "Client" && i.related_user_type === "claimant"
      );
      const respondentNameVs = partyRespondentVs
        ? partyRespondentVs.related_user.name
        : null;

      const respondentNames = item.case_master_users.reduce((acc, i) => {
        if (
          i.related_user_role === "Party" &&
          i.related_user_type === "respondent"
        ) {
          acc.push(i.related_user.name);
        }
        return acc;
      }, []);

      // const respondents = item.case_master_users.reduce((acc, i) => {
      //   if (
      //     i.related_user_role === "Party" &&
      //     i.related_user_type === "respondent"
      //   ) {
      //     acc.push({ id: i.related_user_id, name: i.related_user.name });
      //   }
      //   return acc;
      // }, []);

      // const handleDropdownToggle = (index) => {
      //   setDropdownState(dropdownState === index ? null : index);
      // };

      // const handleDelete = async (respondentId) => {
      //   try {
      //     setDisabled(true);
      //     const response = await Axios.delete(`${DELETE_RESPONDENT_BY_ADMIN}/${respondentId}`);

      //     if (response.status === 200) {
      //       toast.success("Case deleted successfully");
      //       const updatedList = list.filter((item) => item.id !== respondentId);
      //       setList(updatedList);
      //       setDropdownState(null);
      //     } else {
      //       toast.error("Error deleting case");
      //     }
      //   } catch (err) {
      //     const errorMessage = err.response?.data?.message || "Error deleting case";
      //     toast.error(errorMessage);
      //   } finally {
      //     setDisabled(false);
      //     setDropdownState(null);
      //   }
      // };

      return (
        <div className="timline-content">
          <div class="px-1 time-name d-flex justify-content-between">
            <div class="d-flex">
              <span className="mt-1">
                <Checkbox
                  key={item.id}
                  type="checkbox"
                  name={item.label}
                  id={item.id}
                  handleClick={(e) => handleClicked(e, item.id)}
                  isChecked={isCheck.includes(item.id)}
                // handleClick={handleClick}
                />
              </span>
              <h4>
                {" "}
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Case No."
                  className="d-flex"
                >
                  {" "}
                  {/* {item.case_number} */}
                  <div className="ml-2 text-capitalize">
                    <span>{respondentNameVs} vs </span>
                    <span>
                      {respondentNames?.length === 1
                        ? respondentNames
                        : respondentNames?.length === 2
                          ? respondentNames[0] + " & another"
                          : respondentNames?.length === 3 ||
                            respondentNames?.length > 3
                            ? respondentNames[0] + " & others"
                            : ""}
                    </span>
                  </div>
                  {/* {item.case_master_users.map((i, index) => {
                    return (
                      <div className="ml-2 text-capitalize" key={index}>
                        <span>
                          {i.related_user_role == "Client" &&
                          i.related_user_type == "claimant"
                            ? `${i.related_user?.name}   vs`
                            : ""}
                        </span>

                        <span>
                          {i.related_user_role == "Party" &&
                          i.related_user_type == "respondent"
                            ? i.related_user?.name
                            : ""}
                        </span>
                      </div>
                    );
                  })} */}
                </div>
              </h4>
            </div>

            <p className="checkbox-revenue-subtext">
              <span className="status-container-status14">
                Case Status : {item?.final_status?.name || "N/A"}
              </span>
              <span> {item?.date} </span>
              <span className="revenue-status-14 font-bold">
                Present Stage : {item?.case_status}
              </span>
            </p>

            {/* 
            <div className="button-dropdown-content" key={item.id}>
              <div className="px-1 time-name d-flex justify-content-between">
                <div className="dropdown">
                  <Button
                    className="m-0 p-0 border-0 bg-transparent shadow-none"
                    style={{ minWidth: "auto", display: "flex", alignItems: "center" }}
                    onClick={() => handleDropdownToggle(index)}
                  >
                    <i className="zmdi zmdi-more-vert" style={{ fontSize: "18px", color: "grey" }}></i>
                  </Button>
                  {dropdownState === index && (
                    <div className="dropdown-menu show  dropdown-shw">
                      {respondents.length > 1 ? (
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            setRespondentNames(respondents);
                            setShowRespondentModal(true);
                            setDropdownState(null);
                          }}
                        >
                          <FaEdit className="mr-2" />
                          Edit Respondent
                        </button>
                      ) : (
                        <Link
                          to={`/app/client/admin/edit-respondent/${respondents[0].id}`}
                          className="dropdown-item d-flex"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Edit Respondent"
                        >
                          <FaEdit className="mr-2" />
                          Edit Respondent
                        </Link>
                      )}

                      <Link
                        to={`/app/client/admin/edit-venue/${item.hash_id}`}
                        className="dropdown-item d-flex"
                        data-toggle="tooltip"
                        data-placement="top"
                        title=""
                        data-original-title="Edit Venue"
                      >
                        <FaMapMarkedAlt className="mr-2" />
                        Edit Venue
                      </Link>

                      <button
                        className="dropdown-item"
                        onClick={() => handleDelete(item.id)}
                        disabled={disabled}
                      >
                        <FaTrashAlt className="mr-2" />
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          </div>

          <div className="filesrevenue-conatiner">
            <div className="filesrevenue-subconatiner">
              <div className="pov123 mt-3">
                <p>
                  <span className="pov123Text">Case No.</span>
                  {item.case_number}
                </p>
                <p>
                  <span className="pov123Text">Filed On:</span>
                  {moment(item.created_at).format("DD.MM.YYYY")}
                </p>
                {/* <p>
                    <span className="revenue-status-14 font-bold">
                      Current Arbitrator: {item?.arbitratorName}
                    </span>
                  </p> */}
              </div>
            </div>
            <div className="filesrevenue-subconatinerpov">
              {/* {item?.hearing &&
                  item?.hearing.length > 0 &&
                  item?.hearing.map((hearing) => {
                    const startDate = new Date(hearing?.hearing?.start_date);
                    const currentDate = new Date();

                    if (startDate >= currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing?.hearing_id}
                        >
                          <p>Next</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    } else if (startDate < currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing.hearing_id}
                        >
                          <p>Previous</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    }
                  })} */}

              {item?.hearing &&
                item?.hearing?.length > 0 &&
                (() => {
                  const currentDate = new Date();

                  // Filter out previous and next hearings
                  const previousHearings = item?.hearing?.filter(
                    (hearing) => {
                      const startDate = new Date(
                        hearing?.hearing?.start_date
                      );
                      return startDate < currentDate;
                    }
                  );

                  const nextHearings = item.hearing.filter((hearing) => {
                    const startDate = new Date(hearing?.hearing?.start_date);
                    return startDate >= currentDate;
                  });

                  // Sort previous hearings by start_date in descending order
                  previousHearings.sort(
                    (a, b) =>
                      new Date(b.hearing?.start_date) -
                      new Date(a.hearing?.start_date)
                  );

                  // Sort next hearings by start_date in ascending order
                  nextHearings.sort(
                    (a, b) =>
                      new Date(a.hearing?.start_date) -
                      new Date(b.hearing?.start_date)
                  );

                  // Get the latest previous hearing and the earliest next hearing
                  const latestPreviousHearing = previousHearings[0];
                  const nextHearing = nextHearings[0];

                  return (
                    <>
                      {latestPreviousHearing && (
                        <div
                          className="filesrevenue-povBoxes"
                          key={latestPreviousHearing?.hearing_id}
                        >
                          <p>Previous</p>
                          <p>
                            {formatDate(
                              new Date(
                                latestPreviousHearing?.hearing?.start_date
                              )
                            )}
                          </p>
                        </div>
                      )}
                      <div
                        className="filesrevenue-povBoxes"
                        key={
                          nextHearing ? nextHearing?.hearing_id : "no-next"
                        }
                      >
                        <p>Next</p>
                        <p>
                          {nextHearing
                            ? formatDate(
                              new Date(nextHearing?.hearing?.start_date)
                            )
                            : "N/A"}
                        </p>
                      </div>
                    </>
                  );
                })()}

              {!item?.hearing ||
                (item?.hearing?.every(
                  (hearing) =>
                    new Date(hearing?.hearing?.start_date) >= new Date()
                ) && <></>)}
            </div>
          </div>

          {/* <div class="right-text d-flex align-items-center">
              <div class="d-inline-block">
                <span className="text-dt">17 January 2024 </span>
               
              </div>

              <select
                id="5588"
                class="form-control dropdown downloadstatus pb-2"
              >
                <option value="0">Download Activated</option>
                <option value="1">Download Deactivated</option>
              </select>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center py-2">
            <div className="">
              
              <div className="time-leftcontent mb-3">
                <div className="d-flex mt-2">
                  <p>
                    <font>Video Conferencing: </font>
                  </p>
                  <Link to="" className="ml-3 order-btn">
                    {" "}
                    Upcoming Meetings{" "}
                  </Link>
                  <Link
                    to={`/app/client/case-detail/${item.hash_id}`}
                    className="ml-3 make-aapbtn rounded-pill mt-2"
                  >
                    View Case Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="time-rightcontent">
              <div className="prev-div mr-1 align-items-center">
                <p>
                  <font>Shipment Status</font>
                </p>
              </div>
              <div className="prev-div mr-1 whats-status align-items-center">
                <p>
                  <font>Whatsapp Status</font>
                </p>
              </div>
            </div>
          </div> */}
          {showVenue === index ? (
            <div onClick={() => toggleRev1(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev1(index)} className="text-center">
              <span className="arrow-conainer">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
          { /* {showVenue === index &&
            item.metas.map((m) => {
              return (
                <>
                  <div className="revenue-state">
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {" "}
                        {m.meta_value}{" "}
                      </p>
                    </div>
                  </div>
                </>
              );
            })} */}

          {showVenue === index &&
            item.metas
              .filter(m => !["section_17"].includes(m.meta_key))
              .map((m) => {
                return (
                  <div key={m.meta_key} className="revenue-state">
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {" "}
                        {m.meta_value}{" "}
                      </p>
                    </div>
                  </div>
                );
              })}
        </div>
      );
    });

  return (
    <section className="Sales_analytics ml-0 mr-3 pl-3">
      {/* <DialogBox
        title={"Cases"}
        open={open}
        onClose={handleClose}
        deleteAction={deleteAction}
      /> */}
      <div fluid>
        <Row>
          <Col sm="1">
            <Link
              to="/app/admin/all/clients/list"
              style={{
                color: "grey",
                fontSize: "32px",
              }}
              onClick={() => {
                localStorage.removeItem("Client_name")
                localStorage.removeItem("Client_id")
              }}
            >
              <i
                class="zmdi zmdi-arrow-left"
                style={{ fontSize: "22px", marginTop: "2px" }}
              ></i>
            </Link>
          </Col>
          <Col sm="7"></Col>
          <Col sm="4">
            <h2 className="mb-0 mt-3 mr-3" style={{ float: "right" }}>
              {localStorage.getItem("Client_name")}
            </h2>
          </Col>
        </Row>

        <div className="buton-conatiner2431">
          <div className="box-rev-123 mt80">

            <button
              className="restorecasses123 scrol-btn-genrte ml-auto"
              onClick={isCheck == "" ? showErrorN : handleShowModal}
            >
              Restore Cases
            </button>

            {/* <button
              className="gnrtarbit123 scrol-btn-genrte"
              onClick={isCheck == "" ? showErrorN : handlePreviewA}
            >
              Assign Arbitrator
            </button>

            <button
              className="gnrtaudit123 scrol-btn-genrte"
              onClick={isCheck == "" ? showErrorN : handleShowModal}
            >
              Delete Cases
            </button> */}

            {/* <button
              className="gnrate12As scrol-btn-genrte"
              onClick={isCheck == "" ? showErrorN : handlePreviewN}
            >
              Assign Hearing
            </button> */}

            {/* <button
              className="gnrtaudit123 scrol-btn-genrte"
              onClick={isCheck == "" ? showErrorN : auditLogs}
            >
              Audit Logs
            </button> */}

            {/* <button
              className="gnrtaudit1234 scrol-btn-genrte"
              onClick={isCheck == "" ? showErrorN : handlePreviewStatus}
            >
              Status Update
            </button> */}

            {/* <button
              className="gnrtcertified123 scrol-btn-genrte"
              onClick={isCheck == "" ? showErrorN : downloadFile}
            >
              {" "}
              <span className="mr-2">Download MIS</span>
              <i
                class="zmdi zmdi-cloud-upload"
                style={{ fontSize: "17px" }}
              ></i>
            </button> */}

            {/* <button
              className="stage123 scrol-btn-genrte"
              onClick={isCheck == "" ? showErrorN : handlePreviewStage}
            >
              Stage Update
            </button> */}

          </div>

          <div className="box-rev-123 mt67">
            <div>

              {/* <button
                className="gnrtheader123 scrol-btn-genrte"
                onClick={handlePreviewH}
              >
                Header Sets
              </button> */}

            </div>
            <div>
              {/* 
              <button
                className="gnratemp123 scrol-btn-genrte"
                onClick={handlePreviewT}
              >
                Template
              </button> */}

            </div>
            <div>

              {/* <button
                className="gnrate123 scrol-btn-genrte"
                onClick={isCheck == "" ? showErrorN : handlePreviewAss}
              >
                Document Generation
              </button> */}

            </div>
            <div>
              {/* <button
                className="gnrtcertified123 scrol-btn-genrte"
                onClick={isCheck == "" ? showErrorN : handlePreviewCerti}
              >
                Certified Copy
              </button> */}
            </div>
          </div>
          {/* <div className="one-btn-arbit1">
            <div className="">
              <button className="gnrtarbit123" onClick={isCheck == "" ? showErrorN : handlePreviewA}>Assign Arbitrator</button>
              </div>
          </div>
          <div className="one-btn-tmplate">
            <div className="d-flex justify-content-between">
              <button className="gnratemp123" onClick={handlePreviewT}>
                Template
              </button>
              <button className="gnrtheader123" onClick={handlePreviewH}>Header Sets</button>
              
            </div>
          </div>

          <div className="one-btn-gnrate">
            <div className="d-flex justify-content-between">
             <button
                className="gnrate123"
                onClick={isCheck == "" ? showErrorN : handlePreviewN}
              >
                Document Generation
              </button>
            </div>
          </div>
          <div className="one-btn-arbit1">
            <div className="">
               <button className="gnrtaudit123">Generate Audit Trail</button>
            </div>
          </div> */}
          {/* <div className="one-btn-tmplate">
            <div className="d-flex justify-content-between">
              <span className="gnrate-select">Action</span>
              <button className="gnratemp123" onClick={handlePreviewT}>
                Template
              </button>
              <button className="gnrtaudit123">Generate Audit Trail</button>
            </div>
          </div> */}
          {/* <div className="one-btn-arbit">
            <div className="d-flex justify-content-between">
              <button className="gnrtheader123" onClick={handlePreviewH}>Header Sets</button>
              <button className="gnrtarbit123" onClick={isCheck == "" ? showErrorN : handlePreviewA}>Assign Arbitrator</button>
            </div>
          </div> */}
        </div>

        {/* <Row>
          <Col md="3" xs="12">
            {temp.length > 0 && (
              <SELECT
                label="Template"
                name={"name"}
                // value={role}
                onChange={(event) =>
                  setState({ temp: event.target.value, disabled: true })
                }
                data={temp}
              />
            )}
          </Col>
          <Col md="9" xs="12">
            
            <Tooltip title="Need to select Template and all checkboxes" arrow>
              <Button
                className={
                  state.disabled == true
                    ? "float-right mt-4 mr-5"
                    : "float-right mt-4 mr-5 generate-disabled"
                }
                onClick={state.disabled == true ? handleDownload : ""}
              >
                Generate
              </Button>
            </Tooltip>
             
          </Col>
        </Row> */}

        <div className="revenueTable-conatiner mt-0">
          <div className="revenueTable-pagination-box">
            <div className="pgination-1st">
              {" "}
              <span className="check-po-icon">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                  />
                </div>
              </span>
              <span className="check-po-icon" onClick={refresh}>
                <i class="zmdi zmdi-refresh-alt"></i>
              </span>

              {/* <span className="check-po-icon" onClick={handleClickOpen}>
                <i class="zmdi zmdi-delete"></i>
              </span> */}

              {/* <span className="check-po-icon" onClick={handleOpenModal1}>
                <span className="mr-2 mb-1">Filter</span>

                <img
                  src={filter}
                  alt=""
                  style={{ width: " 20px", height: " 18px" }}
                />
                <i class="zmdi zmdi-filter-list"></i>
              </span> */}

              {/* <span className="check-po-icon" onClick={handleOpenModalTracker}>
                <span className="mr-2 mb-1">Filter by excel</span>

                <img
                  src={filter}
                  alt=""
                  style={{ width: " 20px", height: " 18px" }}
                />
              </span> */}

              {/* <Modal isOpen={showModal} toggle={handleCloseModal}>
                <div className="modal-header">
                  <span className="filter-text"> Filter</span>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseModal}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <ModalBody>
                  <Row>
                    <Col md="4" className="mt-3">
                      <SELECT
                        onChange={handleChange}
                        value={formData.final_status}
                        name="final_status"
                        label="Case Status"
                        data={caseStatus}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <FormControl className="select-multi">
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Present Stage
                        </InputLabel>
                        <Select
                          fullWidth
                          id="demo-mutiple-name"
                          onChange={handleChange}
                          name="status"
                        >
                          <option value="pending" style={{ cursor: "pointer" }}>
                            Pending
                          </option>
                          <option
                            value="registered"
                            style={{ cursor: "pointer" }}
                          >
                            Registered
                          </option>
                          {stagingData &&
                            stagingData.map((e, key) => {
                              return (
                                <option
                                  key="abc"
                                  value={e.stage}
                                  style={{ cursor: "pointer" }}
                                >
                                  {e.stage}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col md="4" xs="12">
                      <TEXTFIELD
                        type="date"
                        label="Created At"
                        onChange={handleDateChange}
                        value={formData.created_at}
                        name="created_at"
                      />
                    </Col>
                    <Col md="4" className="mt-3">
                      <SELECT
                        onChange={handlenotassignChange}
                        value={formData.not_have_hearing_date}
                        name="not_have_hearing_date"
                        label="Hearing not assigned"
                        data={[{ yes: "Yes" }]}
                      />
                    </Col>

                    <Col md="4" className="mt-3">
                      <SELECT
                        onChange={handleChange}
                        value={formData.arbitrator}
                        name="arbitrator"
                        label="Arbitrator"
                        data={arbitratorData}
                      />
                    </Col>

                    <Col md="4">
                      <TEXTFIELD
                        type="date"
                        label="Hearing Date"
                        onChange={handleHearingDateChange}
                        value={formData.hearing_date}
                        name="hearing_date"
                      />
                    </Col>

                    <Col md="4">
                      <SELECT
                        onChange={handleChangeState}
                        value={formData.state}
                        name="state"
                        label="State"
                        data={stateData}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChangeSeat}
                        value={formData.seat}
                        name="seat"
                        label="Seat"
                        data={seatData}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChange}
                        value={formData.venue}
                        name="venue"
                        id="variation-select-6"
                        label="Venue"
                        data={venueData}
                      />
                    </Col>
                    {lotData && (
                      <Col md="4">
                        <SELECT
                          name="lot"
                          onChange={handleChangeLot}
                          value={formData.lot}
                          id="variation-select-8"
                          label="LOT"
                          data={lotData}
                        />
                      </Col>
                    )}
                    <Col md="4" xs="12">
                      <SELECT
                        name="client"
                        onChange={handleChangeClient}
                        value={formData.client}
                        id="variation-select-8"
                        label="Client"
                        data={clientData}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <SELECT
                        name="notes"
                        onChange={handleChangeNotes}
                        value={formData.notes}
                        id="variation-select-8"
                        label="Notes"
                        data={[{ yes: "Yes" }]}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <FormControl className="select-multi mt-0">
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Notice
                        </InputLabel>
                        <Select
                          fullWidth
                          id="demo-mutiple-name"
                          onChange={handleChange}
                          name="notice"
                        >
                          <option value="pending" style={{ cursor: "pointer" }}>
                            Pending
                          </option>
                          <option
                            value="registered"
                            style={{ cursor: "pointer" }}
                          >
                            Registered
                          </option>
                          {stagingData &&
                            stagingData.map((e, key) => {
                              return (
                                <option
                                  key="abc"
                                  value={e.stage}
                                  style={{ cursor: "pointer" }}
                                >
                                  {e.stage}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col md="4" xs="12" style={{ marginTop: "3px" }}>
                      <SELECT
                        name="msg_type"
                        value={formData.msg_type}
                        onChange={handleChange}
                        label="Notice Type"
                        data={[
                          { whatsapp: "Whatsapp" },
                          { sms: "SMS" },
                          { email: "Mail" },
                        ]}
                      />
                    </Col>
                    <Col md="4" xs="12" style={{ marginTop: "4px" }}>
                      <SELECT
                        name="msg_status"
                        value={formData.msg_status}
                        onChange={handleChange}
                        label="Notice Status"
                        data={[
                          { sent: "Sent" },
                          { delivered: "Delivered" },
                          { read: "Read" },
                          { UNDELIV: "Undelivered" },
                          { failed: "Failed" },
                        ]}
                      />
                    </Col>
                    <Col md="6" className="mt-4">
                      <div>
                        <label htmlFor="date_range" className="d-block">
                          Hearing Date Range:
                        </label>
                        <div className="d-flex">
                          <input
                            type="date"
                            id="date_range"
                            name="created_at_from"
                            value={formData.created_at_from}
                            onChange={handleChangeDateRange}
                            className="form-control mr-1"
                          />
                          <span className="align-self-center"> - </span>
                          <input
                            type="date"
                            name="created_at_to"
                            value={formData.created_at_to}
                            onChange={handleChangeDateRange}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className="mt-4">
                      <div>
                        <label htmlFor="date_range" className="d-block">
                          Case Filed Date Range:
                        </label>
                        <div className="d-flex">
                          <input
                            type="date"
                            id="date_range"
                            name="hearing_date_from"
                            value={formData.hearing_date_from}
                            onChange={handleFiledDateRange}
                            className="form-control mr-1"
                          />
                          <span className="align-self-center"> - </span>
                          <input
                            type="date"
                            name="hearing_date_to"
                            value={formData.hearing_date_to}
                            onChange={handleFiledDateRange}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {" "}
                    <Col md="4" xs="12">
                      <Button
                        color="primary"
                        onClick={handleConfirmSelection}
                        className="btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT M add-notes w-30"
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal> */}

              {/* <Modal
                isOpen={showTrackerModal}
                toggle={handleCloseTrackerModal}
                className="generateCerti"
              >
                <div className="modal-header">
                  <span>Filter by excel</span>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseTrackerModal}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <ModalBody>
                  <Row>
                    <Col md="12" xs="12">
                      <div class="file-upload-cus mb-2">
                        <div class="file-select">
                          <div class="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div class="file-select-name" id="noFile">
                            {fileName ? fileName : "No file chosen..."}
                          </div>
                          <div class="importcus">
                            <input
                              type="file"
                              name={"file"}
                              id="chooseFile"
                              accept=".xlsx , .xls , .csv"
                              onChange={handleFile}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" xs="12">
                      <Button
                        color="primary"
                        onClick={submitExcel}
                        className={
                          file
                            ? "btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT add-notes w-30"
                            : "btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT apply-disabled"
                        }
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" xs="12">
                      <SELECT
                        label="Stage"
                        name={"stage"}
                        onChange={handleChange}
                        data={stage}
                        className="mt-0"
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <SELECT
                        name="msg_type"
                        value={formData.msg_type}
                        onChange={handleChange}
                        label="Type"
                        data={[
                          { whatsapp: "Whatsapp" },
                          { sms: "SMS" },
                          { email: "Mail" },
                        ]}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <SELECT
                        name="msg_status"
                        value={formData.msg_status}
                        onChange={handleChange}
                        label="Status"
                        data={[
                          { sent: "Sent" },
                          { delivered: "Delivered" },
                          { read: "Read" },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {" "}
                    <Col md="4" xs="12">
                      <Button
                        color="primary"
                        onClick={handleTrackerSelection}
                        className={
                          formData.stage &&
                          formData.msg_status &&
                          formData.msg_type
                            ? "btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT add-notes w-30"
                            : "btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT apply-disabled"
                        }
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal> */}

              <span className="mr-2 mb-1 se12">Search</span>
              <span
                className="search56"
                onClick={() => setShowSearch(!showSearch)}
              >
                <i class="zmdi zmdi-search"></i>
              </span>
            </div>

            <div className="pagination-conatinerw">
              <div className="d-flex pagination-text justify-content-end">

                {/* <div className="text-right">
                  {" "}
                  <p
                    className="order-name1"
                    onClick={isCheck == "" ? showErrorN : downloadFile}
                  >
                    <div className="excel34-dwn">
                      <span className="mr-2">Download MIS</span>
                      <i
                        class="zmdi zmdi-cloud-upload"
                        style={{ fontSize: "17px" }}
                      ></i>
                    </div>
                  </p>
                </div> */}

                {/* <div className="text-right">
                  {" "}
                  <p className="order-name1 mx-1">
                    <CsvDownloader
                      filename="Cases"
                      extension=".csv"
                      separator=","
                      columns={columns}
                      // datas={filteredData}
                      text="Download"
                      className="excel34-dwn"
                    >
                      <span className="mr-2">Details Download</span>
                      <i
                        class="zmdi zmdi-cloud-upload"
                        style={{ fontSize: "17px" }}
                      ></i>
                    </CsvDownloader>
                  </p>
                </div> */}

                <span className="pto-5">Show</span>
                <select
                  class="form-select page-select12"
                  aria-label="Default select example"
                  onChange={(event) => setLimit(event.target.value)}
                >
                  {list && list?.length > 10 ? (
                    <option selected>20</option>
                  ) : list?.length > 50 ? (
                    <option value="50">50</option>
                  ) : list?.length > 80 ? (
                    <option value="80">80</option>
                  ) : list?.length > 100 ? (
                    <option value="100">100</option>
                  ) : (
                    ""
                  )}

                  <option value={list && list?.length}>
                    {count == 0 ? 0 : list && list?.length}
                  </option>
                </select>

                <span className="pto-5">
                  {" "}
                  per page from {count == 0
                    ? 0
                    : list && list?.length} results{" "}
                </span>
              </div>
            </div>
          </div>
          {showSearch === true && (
            <div className="box-serch-filter0">
              <Row className="ml-2">
                <Col md={3}>
                  <SELECT
                    label="Filter Name"
                    name="fieldName"
                    onChange={handleChangeKey}
                    value={formData.fieldName}
                    data={[
                      { respondent_name: "Respondent Name" },
                      { case_number: "Case Number" },
                      { LAN: "LAN" },
                    ]}
                  />
                </Col>
                <Col md={3}>
                  <div className="form-search-header">
                    <input
                      type="search"
                      placeholder="Search..."
                      value={formData.fieldValue}
                      name="fieldValue"
                      onChange={handleChangeKey}
                    />

                    {formData.fieldValue && (
                      <span onClick={removefilter}>
                        <i class="zmdi zmdi-close"></i>
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          )}

          {loading === false && list && list?.length == 0 && (
            <div className="n--data text-center m-5">No data Found!</div>
          )}

          {loading === true && (
            <div className="n--data text-center m-5">
              <Loading />
            </div>
          )}
          <div className="revenue-scroll-container">{cataloge}</div>
        </div>
      </div>

      {/* <Modal isOpen={show} toggle={handleCancel}>
        <p className="text-right m-0">
          <span onClick={handleCancel} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <p className="modal-case-12-heading">Case Limitation</p>
          <form>
            <Row>
              <Col md="4" xs="12">
                <SELECT
                  label="Chamber of arbitration"
                  name={"role"}
                  // value={role}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Source Law Firm"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Arbitrator"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Arbitrator"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal> */}

      {/* <Modal
        isOpen={showNoticeAss}
        toggle={handleCancelAss}
        className="gn-modal"
      >
        <p className="text-right m-0">
          <span onClick={handleCancelAss} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <p className="modal-case-12-heading text-center m-0">
            Please select these options
          </p>
          <div className="m-auto">
            <form
              className="tempalte-form1"
              onSubmit={
                state.stage && state.temp && state.type ? handleDownload : ""
              }
            >
              <Row>
                <Col md="12" xs="12">
                  <TEXTFIELD
                    type="date"
                    label="Date"
                    name="start_date"
                    value={state.start_date}
                    onChange={handleStartDateChange}
                  />
                </Col>
                {state.start_date == "" ? (
                  <span className="errormsg">Please select date</span>
                ) : (
                  ""
                )}
                <Col md="12" xs="12">
                  <SELECT
                    label="Stage"
                    name={"stage"}
                    onChange={(event) =>
                      setState((prev) => ({
                        ...prev,
                        stage: event.target.value,
                        disabled: true,
                      }))
                    }
                    data={stage}
                    className="mt-0"
                  />
                </Col>
                {state.stage == "" ? (
                  <span className="errormsg">Please select stage</span>
                ) : (
                  ""
                )}
                <Col md="12" xs="12">
                  <SELECT
                    label="Document Type"
                    name={"name"}
                    onChange={handleChangeType}
                    data={[{ notice: "Notice" }, { order: "Order" }]}
                  />
                </Col>
                {state.type == "" ? (
                  <span className="errormsg">Please select document type</span>
                ) : (
                  ""
                )}
                {state.type && (
                  <>
                    <Col md="12" xs="12">
                      <SELECT
                        label="Document Name"
                        name={"document"}
                        onChange={(event) =>
                          setState((prev) => ({
                            ...prev,
                            document: event.target.value,
                            disabled: true,
                          }))
                        }
                        data={documentList}
                        className="mt-0"
                      />
                    </Col>
                    {state.document == "" ? (
                      <span className="errormsg">Please select document</span>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {state.document == 34 || state.document == 35 ? (
                  <>
                    <Col md="12" xs="12">
                      <TEXTFIELD
                        type="text"
                        label="Document Title"
                        onChange={handleChangeTitle}
                        value={state.title}
                        name="title"
                      />
                    </Col>
                    {state.title == "" ? (
                      <span className="errormsg">Please give title</span>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                <Col md="12" xs="12">
                  <SELECT
                    label="Template"
                    name={"name"}
                    onChange={(event) =>
                      setState((prev) => ({
                        ...prev,
                        temp: event.target.value,
                        disabled: true,
                      }))
                    }
                    data={temp}
                  />
                </Col>
                {state.temp == "" ? (
                  <span className="errormsg">Please select template</span>
                ) : (
                  ""
                )}

                <Col md="12" xs="12">
                  <TEXTFIELD
                    type="text"
                    label="Temporary Name"
                    onChange={handleChangeTempname}
                    value={state.temp_name}
                    name="temp_name"
                  />
                </Col>
                {state.temp_name == "" ? (
                  <span className="errormsg">Please give name</span>
                ) : (
                  ""
                )}

                <Col md="12" xs="12" className="my-2">
                  <Row>
                    <Col md="6" xs="6">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          name="tech"
                          value="basic"
                          checked={tech === "basic"}
                          onChange={handleChangeRadio}
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Basic
                        </label>
                      </div>
                    </Col>
                    <Col md="6" xs="6">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="tech"
                          value="advance"
                          checked={tech === "advance"}
                          onChange={handleChangeRadio}
                          id="flexRadioDefault2"
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Advance
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {tech == "advance" && (
                  <Col md="12" xs="12" className="mt-3">
                    <Row>
                      <Col md="6" xs="6">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="estamp"
                            id="flexCheckChecked"
                            onChange={handleChecked}
                            name="estamp"
                            checked={checkedItems.estamp}
                            disabled={certifiedStamp.certified_estamp === false}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckChecked"
                          >
                            Certified E-Stamp
                          </label>
                        </div>
                      </Col>

                      <Col md="6" xs="6">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="esign"
                            name="esign"
                            checked={checkedItems.esign}
                            id="flexCheckDefault"
                            onChange={handleChecked}
                            disabled={certifiedStamp.certified_sign === false}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            E-Sign
                          </label>
                        </div>
                      </Col>
                      <p className="stamp-required-text">
                        You can update the files
                        <Link to="/app/lawfirm/general"> here.</Link> If
                        disabled please upload the new file.
                      </p>
                    </Row>
                  </Col>
                )}
                <Col md="12" xs="12">
                  <button
                    className={
                      state.stage &&
                        state.temp &&
                        state.type &&
                        state.temp_name &&
                        state.start_date
                        ? "submit-gnrate3"
                        : "submit-gnrate3 generate-disabled"
                    }
                    disabled={
                      state.stage &&
                        state.temp &&
                        state.type &&
                        state.start_date &&
                        state.temp_name
                        ? false
                        : true
                    }
                    type="submit"
                  >
                    {loading === true ? (
                      <div className="n--data text-center ">
                        <img
                          alt="No data available"
                          style={{ width: "10%" }}
                          src={loaderImg}
                        />
                      </div>
                    ) : (
                      "Generate Documents"
                    )}
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </ModalBody>
      </Modal> */}

      {/* <Modal
        isOpen={showNoticeM}
        toggle={handleCancelN}
        style={{ width: "96%" }}
      >
        <p className="text-right m-0">
          <span onClick={handleCancelN} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <p className="modal-case-12-heading">Assign hearing date</p>
          <AddForm
            cases={isCheck}
            handleCancel={handleCancel}
            handleCancelN={handleCancelN}
            getTableRecords={getTableRecords}
          />
        </ModalBody>
      </Modal> */}

      {/* <Modal isOpen={showNoticeT} toggle={handleCancelT}>
        <p className="text-right m-0">
          <span onClick={handleCancelT} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <>
            <div className="w-100 float-right">
              <button
                onClick={() => setAdd(!add)}
                className="float-right addtemp"
              >
                + Add New Template
              </button>
            </div>
            {add == true ? (
              <AddEditForm
                hide={setAdd}
                show={add}
                hashhid={editTeId}
                id={props.editId}
                handleCancelT={handleCancelT}
              />
            ) : (
              ""
            )}
            <div className="w-100">
              <FaqList
                id={props.editId}
                hide={setAdd}
                show={add}
                editTid={setEditid}
              />
            </div>
          </>
        </ModalBody>
      </Modal> */}

      {/* <Modal
        isOpen={showNoticeCerti}
        toggle={handleCancelCerti}
        className="generateCerti"
      >
        <p className="text-right m-0">
          <span onClick={handleCancelCerti} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <p className="modal-case-12-heading text-center m-0">
            Please search document
          </p>
          <SelectSearch isCheck={isCheck} />
          <div className="m-auto">
            <form
              className="tempalte-form1"
              onSubmit={
                state.stage && state.temp && state.type ? handleDownload : ""
              }
            >
              <Row>
                <Col md="12" xs="12">
                  <select class="selectpicker" data-live-search="true">
                    <option data-tokens="ketchup mustard">
                      Hot Dog, Fries and a Soda
                    </option>
                    <option data-tokens="mustard">
                      Burger, Shake and a Smile
                    </option>
                    <option data-tokens="frosting">
                      Sugar, Spice and all things nice
                    </option>
                  </select>
                </Col>
                <Col md="12" xs="12">
                  <SELECT
                    label="Stage"
                    name={"stage"}
                    onChange={(event) =>
                      setState((prev) => ({
                        ...prev,
                        stage: event.target.value,
                        disabled: true,
                      }))
                    }
                    data={stage}
                    className="mt-0"
                  />
                </Col>
                {state.stage == "" ? (
                  <span className="errormsg">Please select stage</span>
                ) : (
                  ""
                )}

                <Col md="12" xs="12">
                  <button
                    className={
                      state.stage &&
                      state.temp &&
                      state.type &&
                      state.start_date
                        ? "submit-gnrate3"
                        : "submit-gnrate3 generate-disabled"
                    }
                    disabled={
                      state.stage &&
                      state.temp &&
                      state.type &&
                      state.start_date
                        ? false
                        : true
                    }
                    type="submit"
                  >
                    Generate Notices
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </ModalBody>
      </Modal> */}

      {/* <Modal isOpen={showNoticeH} toggle={handleCancelH}>
        <p className="text-right m-0">
          <span onClick={handleCancelH} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <>
            <div className="w-100">
              <Headersets
                id={props.editId}
                editTid={setEditid}
                detail={detail}
              />
            </div>
          </>
        </ModalBody>
      </Modal> */}

      {/* <Modal
        isOpen={showStatus}
        toggle={handleCancelStatus}
        className="generateCerti"
      >
        <p className="m10">
          <span className="filter-text">Status Update</span>
          <span
            onClick={handleCancelStatus}
            className="modal-cancel12 float-right"
          >
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>

        <ModalBody className="py-0">
          <>
            <div className="">
              <form onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md="12" xs="12">
                    <SELECT
                      label="Status"
                      name={"status_id"}
                      value={status_id}
                      onChange={handleStatusChange}
                      data={caseStatus}
                    />
                  </Col>
                  <Col md="12" xs="12">
                    {" "}
                    <SUBMIT title="Submit" disabled={false} />
                  </Col>
                </Row>
              </form>
            </div>
          </>
        </ModalBody>
      </Modal> */}

      {/* <Modal
        isOpen={showStages}
        toggle={handleCancelStages}
        className="generateCerti"
      >
        <p className="m10">
          <span className="filter-text">Stage Update</span>
          <span
            onClick={handleCancelStages}
            className="modal-cancel12 float-right"
          >
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>

        <ModalBody className="py-0">
          <>
            <div className="">
              <form onSubmit={handleStage} noValidate>
                <Row>
                  <Col md="12" xs="12">
                    <SELECT
                      label="Stages"
                      name={"stageid"}
                      value={stageid}
                      onChange={handleStageChange}
                      data={stagename}
                    />
                  </Col>
                  <Col md="12" xs="12">
                    {" "}
                    <SUBMIT title="Submit" disabled={false} />
                  </Col>
                </Row>
              </form>
            </div>
          </>
        </ModalBody>
      </Modal> */}

      {/* <Modal isOpen={showNoticeA} toggle={handleCancelA}>
        <p className="text-right m-0">
          <span onClick={handleCancelA} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <>
            <div className="w-100">
              <ArbitList
                id={props.editId}
                casids={isCheck}
                detail={detail}
                handleCancelA={handleCancelA}
              />
            </div>
          </>
        </ModalBody>
      </Modal> */}

      {/* <Modal
        isOpen={showRespondentModal}
        toggle={() => setShowRespondentModal(!showRespondentModal)}
      >
        <ModalBody>
          <h5>Select a Respondent You Want to Edit</h5>
          <ul>
            {respondentNames.map((respondent) => (
              <li key={respondent.id}>
                <Link
                  to={`/app/client/admin/edit-respondent/${respondent.id}`}
                  className="btn btn-link"
                  onClick={() => handleRespondentSelect(respondent)}
                >
                  {respondent.name}
                </Link>
              </li>
            ))}
          </ul>
        </ModalBody>
      </Modal> */}

      <Modal isOpen={showConfirmModal} toggle={handleCancelModal}>
        <div className=" modal-top fs-18 d-flex justify-content-between py-1 pt-3 px-3">
          <p className="fs-18 fw-bold">Confirm Restore</p>
          <p className="text-right m-0">
            <span onClick={handleCancelModal} className="modal-cancel12">
              <i className="zmdi zmdi-close"></i>
            </span>
          </p>
        </div>
        <ModalBody className="pt-0">
          <div className="w-100">
            <p>Are you sure you want to restore this case?</p>
            <div className="modal-actions mt-4 d-flex justify-content-center gap-3 align-items-center">
              <button
                className="confirm-btn btns-clr border-0 py-1 px-3 mr-3"
                onClick={() => setConfirmDelete(true)}
              >
                Restore
              </button>
              <button className="cancel-btn btns-clr border-0 py-1 px-3" onClick={handleCancelModal}>
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>


      {/* <Modal isOpen={showConfirmModal} toggle={handleCancelModal}>
        <p className="text-right m-0">
          <span onClick={handleCancelModal} className="modal-cancel12">
            <i className="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <div className="w-100">
            <p>Are you sure you want to restore this case?</p>
            <div className="modal-actions">

              <button
                className="confirm-btn"
                onClick={() => {
                  setConfirmDelete(true);
                  restoreCases();
                }}
                disabled={disabled}
              >
                Restore
              </button>

              <button
                className="cancel-btn"
                onClick={handleCancelModal}
              >
                Cancel
              </button>

            </div>
          </div>
        </ModalBody>
      </Modal> */}

    </section>
  );
};

export default Revenuetable;