import React, { useState, useEffect } from "react";
import { Button, Row, Col, CardText, toast, Loading, Link } from "../../../../../components";
import { ApiUrl, Axios } from "../../../../../components";
const { API_GET_USER_DETAIL, API_APPROVE_USER_LAWFIRM, API_DIS_APPROVE_USER_LAWFIRM } = ApiUrl;

const ViewForm = (props) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    setLoading(true);
    const url = window.location.href;
    const editId = url.split('/').pop();
    try {
      const response = await Axios.Get(API_GET_USER_DETAIL + editId);
      if (response.status === 200) {
        setUserData(response.data.data);
      } else {
        toast.error(response.data.error);
      }
    } catch (err) {
      console.error("Error fetching user details:", err);
      toast.error("Failed to fetch user details");
    } finally {
      setLoading(false);
    }
  };

  const userApprove = async (id, status) => {
    const data = { user_id: id, status: status };
    console.log(data);
    setLoading(true);
    try {
      const res = await Axios.Post(API_APPROVE_USER_LAWFIRM, data);
      if (res.data.success) {
        toast.success(res.data.message);
        props.history.push(`/app/admin/users-approval/advocates`);
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      console.error("Approval error:", error);
      toast.error("Failed to approve user");
    } finally {
      setLoading(false);
    }
  };

  const userDisApprove = async (id, status) => {
    const data = { user_id: id, status: status };
    setLoading(true);
    try {
      const res = await Axios.Post(API_DIS_APPROVE_USER_LAWFIRM, data);
      if (res.data.success) {
        toast.success(res.data.message);
        props.history.push(`/app/admin/users-approval/advocates`);
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      console.error("Disapproval error:", error);
      toast.error("Failed to disapprove user");
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (fileUrl) => {
    if (!fileUrl) {
      return;
    }
    const link = document.createElement("a");
    link.href = fileUrl;
    const fileName = fileUrl.split('/').pop();
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileExtension !== 'pdf') {
      link.setAttribute("download", `${fileName}.pdf`);
    } else {
      link.setAttribute("download", fileName);
    }
    link.setAttribute("target", "_blank");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div className="text-center m-5">
        <Loading />
      </div>
    );
  }

  if (!userData) {
    return <div className="text-center m-5">No data found!</div>;
  }

  const { details_advocate: advocateDetails } = userData;

  return (
    <section className="user-details">
      <div className="bg-white mt-4 mr-3">

        <p className="p-2">
          <Link to="/app/admin/users-approval/advocates" className="back-btn-arw1 ml-3 mt-2">
            <i class="zmdi zmdi-long-arrow-return"></i>
          </Link>
        </p>

        <div className="card-body">
          <Row>
            <Col md={6}>
              <CardText>
                <p><span className="pov123Text">Name:</span> {userData.name}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Email:</span> {userData.email}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Mobile:</span> {userData.phone}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Case Number:</span> {advocateDetails?.case_number}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">City:</span> {advocateDetails?.city}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">State:</span> {advocateDetails?.state}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Pin Code:</span> {advocateDetails?.pin_code}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Address:</span> {advocateDetails?.address}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Gender:</span> {advocateDetails?.gender === '1' ? 'Male' : 'Female'}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Enrollment Number:</span> {advocateDetails?.enrollment_number}</p>
              </CardText>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Button
                  className=" mt-2 mr-5 allow-dis-btn"
                  style={{ backgroundColor: '#28a745', color: '#fff' }}
                  onClick={() => userApprove(userData.id, userData.status)}
                >
                  Approve
                </Button>
                <Button
                  className=" mt-2 mr-5 allow-dis-btn"
                  style={{ backgroundColor: '#dc3545', color: '#fff' }}
                  onClick={() => userDisApprove(userData.id, userData.status)}
                >
                  Dis Approve
                </Button>
              </div>

              <div className="d-flex mt-4">
                <p className="mr-4">
                  <span className="pov123Text">Bar Council License:</span>
                  {advocateDetails?.bar_license ? (
                    <i
                      className="zmdi zmdi-download"
                      onClick={() => downloadFile(advocateDetails.bar_license)}
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  ) : (
                    <span className="ml-2 text-danger">N/A</span>
                  )}
                </p>
                <p>
                  <span className="pov123Text">Power of Attorney:</span>
                  {advocateDetails?.atorney ? (
                    <i
                      className="zmdi zmdi-download"
                      onClick={() => downloadFile(advocateDetails.atorney)}
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  ) : (
                    <span className="ml-2 text-danger">N/A</span>
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default ViewForm;