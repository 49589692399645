import React, { useState, useEffect } from "react";
import { Button, Row, Col, CardText, toast, Loading, Link } from "../../../../../components";
import { ApiUrl, Axios } from "../../../../../components";
const { API_GET_USER_DETAIL, API_APPROVE_USER_LAWFIRM, API_DIS_APPROVE_USER_LAWFIRM } = ApiUrl;

const ViewForm = (props) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    setLoading(true);
    const url = window.location.href;
    const editId = url.split('/').pop();
    try {
      const response = await Axios.Get(API_GET_USER_DETAIL + editId);
      if (response.status === 200) {
        setUserData(response.data.data);
      } else {
        toast.error(response.data.error || "Failed to fetch user details");
      }
    } catch (err) {
      console.error("Error fetching user details:", err);
      toast.error("Failed to fetch user details");
    } finally {
      setLoading(false);
    }
  };

  const userApprove = async (id, status) => {
    const data = { user_id: id, status: status };
    setLoading(true);
    try {
      const res = await Axios.Post(API_APPROVE_USER_LAWFIRM, data);
      if (res.data.success) {
        toast.success(res.data.message);
        props.history.push(`/app/admin/users-approval/arbitrators`);
      } else {
        toast.error(res.data.error || "Failed to approve user");
      }
    } catch (error) {
      console.error("Approval error:", error);
      toast.error("Failed to approve user");
    } finally {
      setLoading(false);
    }
  };

  const userDisApprove = async (id, status) => {
    const data = { user_id: id, status: status };
    setLoading(true);
    try {
      const res = await Axios.Post(API_DIS_APPROVE_USER_LAWFIRM, data);
      if (res.data.success) {
        toast.success(res.data.message);
        props.history.push(`/app/admin/users-approval/arbitrators`);
      } else {
        toast.error(res.data.error || "Failed to disapprove user");
      }
    } catch (error) {
      console.error("Disapproval error:", error);
      toast.error("Failed to disapprove user");
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (fileUrl) => {
    if (!fileUrl) {
      return;
    }
    const link = document.createElement("a");
    link.href = fileUrl;
    const fileName = fileUrl.split('/').pop();
    const fileExtension = fileName.split('.').pop().toLowerCase();
    link.setAttribute("download", fileName);
    link.setAttribute("target", "_blank");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div className="text-center m-5">
        <Loading />
      </div>
    );
  }

  if (!userData) {
    return <div className="text-center m-5">No data found!</div>;
  }

  const { details_arbitrator: arbitratorDetails } = userData;

  return (
    <section className="user-details">
      <div className="bg-white mt-4 mr-3">
        <p className="p-2">
          <Link to="/app/admin/users-approval/arbitrators" className="back-btn-arw1 ml-3 mt-2">
            <i className="zmdi zmdi-long-arrow-return"></i>
          </Link>
        </p>
        <div className="card-body">
          <Row>
            <Col md={6}>
              <CardText>
                <p><span className="pov123Text">Name:</span> {userData.name}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Email:</span> {userData.email}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Mobile:</span> {userData.phone}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">DOB:</span> {arbitratorDetails?.date_of_birth}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Parent Name:</span> {arbitratorDetails?.parent_name}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Age:</span> {arbitratorDetails?.age}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Gender:</span> {arbitratorDetails?.gender === 1 ? 'Male' : 'Female'}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Address:</span> {arbitratorDetails?.address}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Pin Code:</span> {arbitratorDetails?.pin_code}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Occupation:</span> {arbitratorDetails?.occupation}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Purpose of Registration:</span> {arbitratorDetails?.purpose_of_registration}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Other Purpose Details:</span> {arbitratorDetails?.other_purpose_details || 'N/A'}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Details of Arbitrator Conducted:</span> {arbitratorDetails?.details_of_arbitrator_conducted || 'N/A'}</p>
              </CardText>
              <CardText>
                <p><span className="pov123Text">Cases Done As Sole Arbitrator:</span> {arbitratorDetails?.cases_done_as_sole_arbitrator || 'N/A'}</p>
              </CardText>

              <p className="fw-bold">Education History</p>
              {userData.education_history && userData.education_history.length > 0 ? (
                <div className="education-history-container">
                  {userData.education_history.map((history, index) => (
                    <div key={index} className="border p-3 mb-3 rounded shadow-sm">
                      <CardText>
                        <p><span className="pov123Text">Institute:</span> {history.college_institute_name}</p>
                      </CardText>
                      <CardText>
                        <p><span className="pov123Text">University:</span> {history.university_name}</p>
                      </CardText>
                      <CardText>
                        <p><span className="pov123Text">Course:</span> {history.course_name}</p>
                      </CardText>
                      <CardText>
                        <p><span className="pov123Text">Year of Passing:</span> {history.year_of_passing}</p>
                      </CardText>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No education history available.</p>
              )}

              <p className="fw-bold">Training Programs</p>
              {userData.training_programs && userData.training_programs.length > 0 ? (
                <div className="education-history-container">
                  {userData.training_programs.map((program, index) => (
                    <div key={index} className="border p-3 mb-3 rounded shadow-sm">
                      <CardText>
                        <p><span className="pov123Text">Course Name:</span> {program.course_name}</p>
                      </CardText>
                      <CardText>
                        <p><span className="pov123Text">Organizer:</span> {program.organizer_name}</p>
                      </CardText>
                      <CardText>
                        <p><span className="pov123Text">Course Duration:</span> {program.course_duration}</p>
                      </CardText>
                      <CardText>
                        <p><span className="pov123Text">Course Year:</span> {program.course_year}</p>
                      </CardText>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No training programs available.</p>
              )}

            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Button
                  className="mt-2 mr-5 allow-dis-btn"
                  style={{ backgroundColor: '#28a745', color: '#fff' }}
                  onClick={() => userApprove(userData.id, userData.status)}
                >
                  Approve
                </Button>
                <Button
                  className="mt-2 mr-5 allow-dis-btn"
                  style={{ backgroundColor: '#dc3545', color: '#fff' }}
                  onClick={() => userDisApprove(userData.id, userData.status)}
                >
                  Dis Approve
                </Button>
              </div>

              <div className="mt-5">
                <p className="fw-bold">Documents</p>
                {userData.arbitrator_documents && userData.arbitrator_documents.length > 0 ? (
                  <div className="education-history-container">
                    <div className="border p-3 mb-3 rounded shadow-sm">
                      <p className="mr-4">
                        <span className="pov123Text">Matric Document:</span>
                        {userData.arbitrator_documents[0].matric_document ? (
                          <i
                            className="zmdi zmdi-download"
                            onClick={() => downloadFile(userData.arbitrator_documents[0].matric_document)}
                            style={{ fontSize: "25px", cursor: "pointer" }}
                          />
                        ) : (
                          <span className="ml-2 text-danger">N/A</span>
                        )}
                      </p>
                      <p>
                        <span className="pov123Text">Graduation Document:</span>
                        {userData.arbitrator_documents[0].graduation_document ? (
                          <i
                            className="zmdi zmdi-download"
                            onClick={() => downloadFile(userData.arbitrator_documents[0].graduation_document)}
                            style={{ fontSize: "25px", cursor: "pointer" }}
                          />
                        ) : (
                          <span className="ml-2 text-danger">N/A</span>
                        )}
                      </p>
                      <p>
                        <span className="pov123Text">Experience Document:</span>
                        {userData.arbitrator_documents[0].experience_document ? (
                          <i
                            className="zmdi zmdi-download"
                            onClick={() => downloadFile(userData.arbitrator_documents[0].experience_document)}
                            style={{ fontSize: "25px", cursor: "pointer" }}
                          />
                        ) : (
                          <span className="ml-2 text-danger">N/A</span>
                        )}
                      </p>
                      <p>
                        <span className="pov123Text">Training Document:</span>
                        {userData.arbitrator_documents[0].training_document ? (
                          <i
                            className="zmdi zmdi-download"
                            onClick={() => downloadFile(userData.arbitrator_documents[0].training_document)}
                            style={{ fontSize: "25px", cursor: "pointer" }}
                          />
                        ) : (
                          <span className="ml-2 text-danger">N/A</span>
                        )}
                      </p>
                      <p>
                        <span className="pov123Text">ID Proof Document:</span>
                        {userData.arbitrator_documents[0].id_proof_document ? (
                          <i
                            className="zmdi zmdi-download"
                            onClick={() => downloadFile(userData.arbitrator_documents[0].id_proof_document)}
                            style={{ fontSize: "25px", cursor: "pointer" }}
                          />
                        ) : (
                          <span className="ml-2 text-danger">N/A</span>
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p>No documents available</p>
                )}
              </div>

              <div className="mt-5">
                <p className="fw-bold">Professional Experience</p>
                {userData.professional_experience && userData.professional_experience.length > 0 ? (
                  <div className="education-history-container">
                    {userData.professional_experience.map((experience, index) => (
                      <div key={index} className="border p-3 mb-3 rounded shadow-sm">
                        <CardText>
                          <p><span className="pov123Text">Employer:</span> {experience.employer_name}</p>
                        </CardText>
                        <CardText>
                          <p><span className="pov123Text">Address:</span> {experience.employer_address}</p>
                        </CardText>
                        <CardText>
                          <p><span className="pov123Text">Position:</span> {experience.position}</p>
                        </CardText>
                        <CardText>
                          <p><span className="pov123Text">Start Year:</span> {experience.start_year}</p>
                        </CardText>
                        <CardText>
                          <p><span className="pov123Text">End Year:</span> {experience.end_year}</p>
                        </CardText>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No professional experience available.</p>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default ViewForm;