import React, { Component, useState, Fragment } from "react";
import moment from "moment";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Loading } from "../index";
import Status from "../Status";
import Switch from "@material-ui/core/Switch";

import { NavLink, withRouter } from "react-router-dom";

class DataTable extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <Table>{this.props.children}</Table>;
  }
}

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className="inner">
      <p className="text">
        {isReadMore ? text.slice(0, 50) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "  ...Read more" : " show less"}
        </span>
      </p>
    </div>
  );
};

const DataTableHead = (props) => {
  const { columns, sort, orderBy, style } = props;
  const updateProps = (data) => {
    if (props.sort) props.sort(data);
  };
  let rightColums = ["action"];
  return (
    <TableHead>
      <TableRow>
        {columns.length > 0 &&
          columns.map((column) => {
            return (
              <TableCell
                key={column.id}
                style={style}
                align={!rightColums.includes(column.id) ? "left" : "right"}
                padding={column.disablePadding ? "none" : "default"}
                onClick={() =>
                  updateProps({
                    key: column.id,
                    order: parseInt(column.order) === 1 ? -1 : 1,
                  })
                }
              >
                {column.label}
                {orderBy === column.id &&
                  !["action", "activeStatus", "activeStatus"].includes(
                    orderBy.toLowerCase()
                  ) && (
                    <TableSortLabel
                      active={true}
                      direction={column.order == 1 ? "asc" : "desc"}
                    />
                  )}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
};
DataTableHead.defaultProps = {
  style: {},
};

const TableCellPrint = (Dkey, key, index) => {
  console.log(key, "key");
  let notDisplay = ["_id", "action"];
  let noImage = ["", null, undefined, "none"];
  switch (key.type) {
    case "tag": {
      return (
        <TableCell
          padding="none"
          align="left"
          style={key.style ? key.style : {}}
        >
          <span className={`label ${Dkey[key.id]}`}>
            {Status[Dkey[key.id]]
              ? Status[Dkey[key.id]]
              : Dkey[key.id]

            }
            {/* &&
                Dkey[key.id].charAt(0).toUpperCase() + Dkey[key.id].slice(1) */}
          </span>
        </TableCell>
      );
      break;
    }
    case "action": {
      return (
        <TableCell
          padding="none"
          align="left"
          style={key.style ? key.style : {}}
        >
          {key.action(Dkey)}
        </TableCell>
      );
      break;
    }
    case "description": {
      return (
        <td
          className="text-center post-descption-table"
          padding="none"
          align="center"
          key={index}
          style={key.style ? key.style : {}}
        >
          {Dkey[key.id] && Dkey[key.id].length > 80 ? (
            <ReadMore>{Dkey[key.id] || "--"}</ReadMore>
          ) : (
            <div
              className="text-center"
              padding="none"
              align="center"
              style={key.style ? key.style : {}}
            >
              {Dkey[key.id] || "--"}
            </div>
          )}
        </td>
      );
    }
    case "image": {
      return (
        <TableCell
          padding="none"
          key={index}
          align="left"
          style={key.style ? key.style : {}}
        >
          <div className="user-profile d-flex flex-row">
            <Avatar
              alt={Dkey["name"]}
              src={
                !noImage.includes(Dkey[key.image])
                  ? Dkey[key.image]
                  : require("../../assets/images/placeholder.jpg")
              }
              className=" user-avatar"
            />
            <div className="user-detail">
              <h5 className="user-name">{Dkey[key.id]} </h5>
            </div>
          </div>
        </TableCell>
      );
      break;
    }
    case "blogimage": {
      return (
        <TableCell
          padding="none"
          key={index}
          align="left"
          style={key.style ? key.style : {}}
        >
          <div className="user-profile blog-profile d-flex flex-row">
            <Avatar
              alt={Dkey["name"]}
              src={
                !noImage.includes(Dkey[key.image])
                  ? Dkey[key.image]
                  : require("../../assets/images/placeholder.jpg")
              }
              className=" user-avatar"
            />
          </div>
        </TableCell>
      );
      break;
    }
    case "promotionImage": {
      return (
        <TableCell
          padding="none"
          key={index}
          align="left"
          style={key.style ? key.style : {}}
        >
          <div className="user-profile d-flex flex-row">
            <img
              alt={Dkey["name"]}
              src={
                Dkey["promotionImage"]
                  ? Dkey["promotionImage"]
                  : require("../../assets/images/placeholder.jpg")
              }
              className=" user-avatar"
            />
          </div>
        </TableCell>
      );
      break;
    }

    case "date": {
      let format = key.date ? key.date : "YYYY-MM-DD";
      return (
        <TableCell
          padding="none"
          align="left"
          key={index}
          style={key.style ? key.style : {}}
        >
          {moment(Dkey[key.id]).format("DD.MM.YYYY")}
        </TableCell>
      );
      break;
    }

    case "currency": {
      return (
        <TableCell
          padding="none"
          align="left"
          key={index}
          style={key.style ? key.style : {}}
        >
          ${Number(Dkey[key.id] || 0).toFixed(2)}
        </TableCell>
      );
    }

    default: {
      if (Array.isArray(Dkey[key.id]) || key.numeric) {
        return (
          <TableCell
            padding="none"
            align="left"
            key={index}
            style={key.style ? key.style : {}}
          >
            {Dkey[key.id] && Dkey[key.id].length > 0 ? Dkey[key.id].length : 0}
          </TableCell>
        );
      } else {
        return (
          <TableCell
            padding="none"
            align="left"
            key={index}
            style={key.style ? key.style : {}}
          >
            {Dkey[key.id] || "--"}
          </TableCell>
        );
      }

      break;
    }
  }
};
class DataTableBody extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      loading,
      page,
      rowsPerPage,
      data,
      column,
      actions,
      actionBtn,
      popupBtn,
      activeToggleBtn,
      activeToggleBtn1,
    } = this.props;
    let columnNames = column
      .map((key) => {
        return key;
      })
      .filter((key) => key != "_id" && key != "action");
    let notDisplay = ["_id", "action", "activeStatus", "activeStatus1"];
    return (
      <TableBody>
        {console.log(data, column, "PPPPP")}
        {loading === false &&
          data.length > 0 &&
          data.map((Dkey, index) => {
            console.log(Dkey.itemStatus, "Dkey");
            return (
              <TableRow hover tabIndex={-1} key={Dkey._id}>
                <TableCell padding="none" align="left">
                  {++index + page * rowsPerPage}
                </TableCell>
                {column.map((key, index) => {
                  if (!notDisplay.includes(key.id)) {
                    return TableCellPrint(Dkey, key, index);
                  }
                })}
                {activeToggleBtn && (
                  <TableCell padding="none" align="right" key={index}>
                    <Switch
                      color="primary"
                      onClick={(e) => activeToggleBtn.action(e, Dkey)}
                      checked={
                        Dkey.itemStatus === "active"
                      }
                    />
                  </TableCell>
                )}
                {activeToggleBtn1 && (
                  <TableCell padding="none" align="right" key={index}>
                    <Switch
                      color="primary"
                      onClick={(e) => activeToggleBtn1.action(e, Dkey)}
                      checked={
                        Dkey.itemStatus1 === "Online"
                      }
                    />
                  </TableCell>
                )}
                {actions && (
                  <TableCell padding="none" align="right" key={index}>
                    <Action actions={actions} id={Dkey.id} hash_id={Dkey.hash_id} Client_Name={Dkey.name}/>
                  </TableCell>
                )}
                {actionBtn && (
                  <TableCell padding="none" align="right" key={index}>
                    <NavLink to={`${actionBtn.link}${Dkey._id}`}>
                      <Button className="bg-amber text-white CBmargin">
                        <i className={`zmdi ${actionBtn.icon}`} />{" "}
                        {actionBtn.title}
                      </Button>
                    </NavLink>
                  </TableCell>
                )}
                {popupBtn && (
                  <TableCell padding="none" align="right" key={index}>
                    <Button
                      className="bg-amber text-white CBmargin"
                      onClick={(e) => popupBtn.action(true, Dkey._id)}
                    >
                      <i
                        className={`${popupBtn.icon ? popupBtn.icon : "zmdi zmdi-eye"
                          }`}
                      />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            );
          })}

        {loading === false && data.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              No data Found!
            </TableCell>
          </TableRow>
        )}
        {loading === true && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              <Loading />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }
}

class DataTablePagination extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      count,
      page,
      rowsPerPage,
      rowsPerPageOptions,
      onChangePage,
    } = this.props;
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </TableRow>
      </TableFooter>
    );
  }
}

class Action extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      anchorEl: undefined,
      menuState: false,
    };

    this.onOptionMenuSelect = this.onOptionMenuSelect.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  onOptionMenuSelect = (event) => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ menuState: false });
  };

  render() {
    const { id, actions, hash_id, Client_Name } = this.props;
    const { menuState, anchorEl } = this.state;

    return (
      <Fragment>
        <IconButton key={id} onClick={(e) => this.onOptionMenuSelect(e)}>
          <i className="zmdi zmdi-more-vert" />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={menuState}
          onClose={(e) => this.handleRequestClose()}
          MenuListProps={{
            style: {
              width: 93,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          {actions &&
            actions.map((key, index) => {
              if (key.action) {
                return (
                  <MenuItem
                    key={key["label"]}
                    onClick={(e) => key.action(true, id)}
                    className="customIcon"
                  >
                    <ListItemIcon>
                      <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    View
                  </MenuItem>
                );
              } else if (key["label"] === "Delete") {
                return (
                  <MenuItem
                    key={key["label"]}
                    onClick={(e) => this.handleRequestClose()}
                    className="customIcon"
                  >
                    <a href="javascript:void(0)" onClick={() => key.link(id)}>
                      <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                      </ListItemIcon>
                      Delete
                    </a>
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    key={key["label"]}
                    onClick={(e) => {
                      if (key["label"] === "Cases") {
                        localStorage.setItem("hash_id", hash_id);
                        localStorage.setItem("Name", Client_Name);
                        localStorage.setItem("Id", id);
                      }
                      this.handleRequestClose();
                    }}
                    className="customIcon"
                  >
                    <NavLink to={`${key.link}/${key["label"] === "Cases" ? hash_id : id}`}>
                      <ListItemIcon>
                        {key["icon"] !== "" && key["icon"]}
                        {key["label"] === "View" && <VisibilityIcon fontSize="small" />}
                        {key["label"] === "Edit" && <EditIcon fontSize="small" />}
                        {key["label"] === "Clone" && <FileCopyIcon fontSize="small" />}
                        {key["label"] === "Pay" && <MonetizationOnIcon fontSize="small" />}
                      </ListItemIcon>
                      {key["label"]}
                    </NavLink>
                  </MenuItem>
                );
              }
            })}
        </Menu>
      </Fragment>
    );
  }
}

const ActionLink = (props) => {
  const { index, actionBtn, id } = props;
  return (
    <TableCell padding="none" align="right" key={index}>
      <NavLink to={`${actionBtn.link}${id}`}>
        <Button className="bg-amber text-white CBmargin">
          <i className={`zmdi ${actionBtn.icon}`} /> {actionBtn.title}
        </Button>
      </NavLink>
    </TableCell>
  );
};
const ActionPopup = (props) => {
  const { key, action, id, title, icon } = props;
  return (
    <TableCell padding="none" align="right" key={key}>
      <Button
        className="bg-amber text-white CBmargin"
        onClick={() => action(id)}
      >
        <i className={`zmdi ${icon}`} /> {title}
      </Button>
    </TableCell>
  );
};
ActionPopup.defaultProps = {
  key: Math.random(),
  title: "",
  icon: "Zmdi-delete",
};

// DataTable= withRouter(DataTable)
// DataTableHead= withRouter(DataTableHead)
// DataTableBody= withRouter(DataTableBody)
// DataTablePagination= withRouter(DataTablePagination)
export {
  DataTable,
  DataTableHead,
  DataTableBody,
  DataTablePagination,
  Action,
  ActionPopup,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  ActionLink,
  moment,
};
