import React, { Fragment, useState } from "react";
import { withRouter, Row, Col, FILEUPLOAD } from "../../components";
import Addons from "../addons/Addons";
import Experience from "../addons/Experience";
import Mediation from "../addons/Mediation";

const categories = [
  { id: 1, category: "Ex Judge" },
  { id: 2, category: "Advocate" },
  { id: 3, category: "Engineer" },
  { id: 4, category: "Charted Accountant" },
  { id: 5, category: "Executive" },
  { id: 6, category: "Shipping" },
  { id: 7, category: "Businessmen" },
  { id: 8, category: "Foreign Nationals" },
  { id: 9, category: "Other Area" },
];

const Educationdetails = (props) => {
  const [arbitrator_panel_category_id, setArbitratorPanelCategoryId] = useState(null);
  const [field_of_specializations, setFieldOfSpecializations] = useState([]);

  const handleCategoryChange = (categoryId) => {
    setArbitratorPanelCategoryId(categoryId);
    props.onCategoryChange(categoryId); // Pass value to parent component
  };

  const handleSpecializationChange = (specializationId) => {
    // Check if the specialization is already selected
    const isSelected = field_of_specializations.includes(specializationId);

    // If it's selected, remove it; otherwise, add it
    if (isSelected) {
      setFieldOfSpecializations(field_of_specializations.filter(id => id !== specializationId));
    } else {
      setFieldOfSpecializations([...field_of_specializations, specializationId]);
    }
    props.onSpecializationChange([...field_of_specializations, specializationId]);
  };

  return (
    <Fragment>
      <div fluid>
        <Row style={{ padding: "10px 24px" }}>
          <h4 className="terms-txt">Education Details</h4>
          <Addons data={props.data} updateAddons={props.updateAddons} />
          <Experience
            dataExperience={props.dataExperience}
            updateExperience={props.updateExperience}
            optionObj={props.optionObj}
          />
          <div>
            <p className="categories-text1">
              Categories of panel of {props.courseName === "Arbitration" ? "arbitrator:" : "mediator:"}
            </p>
            <p className="categories-select1">
              Please choose one of the following Categories you wish to join
            </p>
            <Row style={{ padding: "0px 10px " }}>
              {categories.map((cat, index) => (
                <Col md="4" xs="12" key={index}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id={`flexRadioDefault${cat.id}`}
                      value={cat.id}
                      checked={arbitrator_panel_category_id === cat.id}
                      onChange={() => handleCategoryChange(cat.id)}
                    />
                    <label className="form-check-label" htmlFor={`flexRadioDefault${cat.id}`}>
                      {cat.category}
                    </label>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div>
            <p className="categories-text1">Fields of Specializations:</p>
            <Row style={{ padding: "0px 10px " }}>
              {props.SpecializationsData.map((item, index) => (
                <Col md="4" xs="12" key={index}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={item.id}
                      id={`checkbox-${item.id}`}
                      checked={field_of_specializations.includes(item.id)}
                      onChange={() => handleSpecializationChange(item.id)}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-${item.id}`}>{item.name}</label>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Mediation
            dataMediation={props.dataMediation}
            updateMediation={props.updateMediation}
            courseName={props.courseName}
          />
        </Row>
      </div>
    </Fragment>
  );
};

export default Educationdetails;
