import React, { Fragment, useState, createRef } from "react";
import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  TEXTFIELD,
  CKEditor,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
  SELECT,
  Link,
  FILEUPLOAD,
} from "../../../../../../components";
//upload Shipment
import eventEmitter from "../../../../../../util/emitter";
const {
  API_UPLOAD_SHIPMENTS,
  API_GET_TEMPLATE,
  API_EDIT_TEMPLATE,
  API_GET_USER_STAGES_URL,
} = ApiUrl;

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.editId,
      templateId: this.props.templateId,
      // id: 33,
      name: "",
      description: "",
      file: [],
      stages: "",
      document_name: "",
      fileData: "",
      editid: "",
      errors: {
        name: "",
        description: "",
        file: {},
        document_name: "",
      },
      process: this.props.role,
      loading: false,
    };
    this.fileInputRef = createRef();
  }

  async componentDidMount() {
    console.log(this.props.templateId, "stateid");
    const { id, process } = this.state;
    this.setState({ loading: true });
    this.getStage();
    if (process == "edit" && id) {
      Axios.get(API_GET_TEMPLATE + id)
        .then((response) => {
          var data = response.data.data;
          this.setState({
            name: data.name,
            description: data.description,
            template: data.template,
            editid: data.uploaded_for.hash_id,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("error");
        });
    }
    this.setState({ loading: false });
  }

  getStage = async () => {
    Axios.get(API_GET_USER_STAGES_URL)
      .then((response) => {
        const stage = response.data;
        const stages = stage.data.map((s) => {
          return { [s.id]: s.stage };
        });
        this.setState({ stages: stages });
      })
      .catch((err) => {
        console.log("");
      });
  };

  handleChange = (event) => {
    console.log(event, "asdasdasdasd");
    event.preventDefault();
    const { name, value, validations, email } = event.target;
    let errors = this.state.errors;
    let finalVal = value;

    switch (name) {
      case "file":
        errors.file = value.length < 1 ? "Required" : "";
        break;
      case "document_name":
        errors.document_name = value.length < 1 ? "Required" : "";
        break;

      case "respondent":
        errors.respondent = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const { process, file, document_name } = this.state;

    // this.setState({ loading: true });
    const data = {
      file: file,
      document_name: document_name,
    };

    // check validations
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      // this.setState({ loading: false });
      return false;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    console.log(data, valid.data);
    let url = API_UPLOAD_SHIPMENTS;
    if (process == "edit") {
      url = API_EDIT_TEMPLATE;
    } else {
      eventEmitter.emit("SHOW_FULL_LOADER");
    }

    Axios.Post(url, formData)
      .then((res) => {
        console.log(res, "true123");
        let response = res.data;
        if (res.data.success == true) {
          this.setState({ file: "", fileName: "", document_name: "" });
          this.fileInputRef.current.value = "";
          toast.success(response.message);
        } else {
          console.log();
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        // toast.error(error);
      })
      .finally(() => {
        // this.setState({ loading: false });
        if (process !== "edit") {
          eventEmitter.emit("HIDE_FULL_LOADER");
        }
      });
  };
  // onChange(evt) {
  //   const newAnswer = evt.editor.getData();
  //   this.setState({
  //     answer: newAnswer,
  //   });
  // }

  handleFile1 = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let files = event.target.files; // Get the FileList object
    let newErr = { ...errors, file: "" };
    console.log(files, "files");

    let fileData = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryString = e.target.result;
        fileData.push(binaryString);
        if (fileData.length === files.length) {
          // All files have been read
          this.setState(
            {
              files: files, // Store the FileList object in the state
              fileData: fileData, // Store binary data of files in the state
              errors: newErr,
            },
            () => {
              let err = checkError(newErr);
              this.setState({ disabled: err.disabled });
            }
          );
        }
      };
      reader.readAsBinaryString(files[i]); // Read each file as binary string
    }
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };
    console.log(file, "filefile");

    this.setState(
      {
        file: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ fileName: file.name });
  };

  render() {
    const {
      loading,
      document_name,
      description,
      template,
      fileName,
      disabled,
      stages,
      type,
      errors,
      templateId,
    } = this.state;

    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}

        <form
          noValidate
          autoComplete="off"
          onSubmit={this.onFormSubmit.bind(this)}
          style={{ padding: "20px" }}
        >
          <Row>
            <Col md="4">
              <TEXTFIELD
                label="Document Name"
                value={document_name}
                name={"document_name"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>

            <Col md="6" xs="12" className="mt-3">
              <div class="file-upload-cus mb-2">
                <div class="file-select">
                  <div class="file-select-button" id="fileName">
                    Choose File
                  </div>
                  <div class="file-select-name" id="noFile">
                    {fileName ? fileName : "No file chosen..."}
                  </div>
                  <div class="importcus">
                    <input
                      type="file"
                      name={"file"}
                      id="chooseFile"
                      accept=".xlsx , .xls , .csv"
                      onChange={this.handleFile}
                      ref={this.fileInputRef}
                    />
                  </div>
                </div>
              </div>
              <a
                href="https://justsettle.in/backend/storage/templates/Shipment-template.xlsx"
                className="ml-1 download-templbrtn"
              >
                Download Template For Reference
              </a>
            </Col>

            <Col md="3" className="mt-1">
              <SUBMIT title="Upload" disabled={disabled} />
            </Col>
          </Row>
        </form>
      </Fragment>
    );
  }
}

export default withRouter(AddEditForm);
