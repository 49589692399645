import React from "react";
import editRespondent from "../../../../app/routes/Lawfirm/clients/routes/Components/files/editrespondent";
import EditVenue from "../../../../app/routes/Lawfirm/clients/routes/Components/files/editVenue";
import RevenueTableDeleted from "../../../../app/routes/Lawfirm/clients/routes/Components/files/revenueTabledeletedcases";
import { Redirect, Route, Switch, asyncComponent } from "../../../../components";
const Customers = ({ match }) => (
  <div className="app-wrapper">

    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
      <Route
        path={`${match.url}/list`}
        component={asyncComponent(() => import("./routes/list"))}
      />
      <Route
        path={`${match.url}/cases`}
        component={asyncComponent(() => import("./routes/cases"))}
      />
      <Route
        path={`${match.url}/add`}
        component={asyncComponent(() => import("./routes/add"))}
      />
      <Route
        path={`${match.url}/approved`}
        component={asyncComponent(() => import("./routes/approved"))}
      />
      <Route
        path={`${match.url}/new`}
        component={asyncComponent(() => import("./routes/new"))}
      />

      <Route
        path={`${match.url}/case-list/:editId`}
        component={asyncComponent(() => import("./routes/viewcases"))}
      />

      <Route
        path={`${match.url}/admin/cases-list/:editId`}
        component={asyncComponent(() => import("./routes/viewcasesadmin"))}
      />

      <Route
        path={`${match.url}/admin/deleted/cases/:editId`}
        component={RevenueTableDeleted}
      />

      <Route
        path={`${match.url}/case-detail/:editId`}
        component={asyncComponent(() => import("./routes/singlecase"))}
      />

      {/* dharmani */}
      <Route
        path={`${match.url}/admin/case-detail/:editId`}
        component={asyncComponent(() => import("./routes/singlecaseadmin"))}
      />

      <Route
        path={`${match.url}/admin/edit-respondent/:id`}
        component={editRespondent}
      />

      <Route
        path={`${match.url}/admin/edit-venue/:id`}
        component={EditVenue}
      />

      <Route
        path={`${match.url}/edit/:editId`}
        component={asyncComponent(() => import("./routes/add"))}
      />

      <Route
        path={`${match.url}/case/add/:editId`}
        component={asyncComponent(() => import("./routes/addNotes"))}
      />
      <Route
        path={`${match.url}/template/list/:editId`}
        component={asyncComponent(() => import("../../template/index"))}
      />
      <Route
        path={`${match.url}/headersets/:editId`}
        component={asyncComponent(() => import("./routes/headersets"))}
      />
      <Route
        path={`${match.url}/notes/edit/:noteId`}
        component={asyncComponent(() => import("./routes/addNotes"))}
      />
      <Route
        path={`${match.url}/excel/:editId`}
        component={asyncComponent(() => import("./routes/notes"))}
      />

      <Route
        component={asyncComponent(() =>
          import("../../../../app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Customers;