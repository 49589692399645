import React from 'react';

const NotFound = () => (
  <div className="full-page-loader">
    <img
      alt="Loading..."
      className="loader-img"
      src={require('../../../assets/images/loading.gif')}
    />
  </div>
);

export default NotFound;
