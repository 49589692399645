import React, { useState, useEffect, Fragment } from "react";
import {
  Row,
  Col,
  TEXTFIELD,
} from "../../components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const itemsOBJ_new = {
  user_id: "",
  employer_name: "",
  employer_address: "",
  position: "",
  start_year: "",
  end_year: "",
};

const AddonObj = {
  catName: "",
  items: [{ ...itemsOBJ_new }],
};

const Customize = ({ dataExperience, updateExperience, optionObj }) => {
  const [state, setState] = useState({
    addons: dataExperience,
    errors: {}
  });

  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    setState(prev => ({ ...prev, addons: dataExperience }));
  }, [dataExperience]);

  useEffect(() => {
    if (isChange) {
      updateExperience(state.addons);
      setIsChange(false);
    }
  }, [isChange, state.addons, updateExperience]);

  const validateField = (name, value, index) => {
    let error = "";
    switch (name) {
      case "employer_name":
        error = value.trim() === "" ? "Required" : "";
        break;
      case "employer_address":
        error = value.trim() === "" ? "Required" : "";
        break;
      case "position":
        error = value.trim() === "" ? "Required" : "";
        break;
      case "start_year":
        const currentYear = new Date().getFullYear();
        if (value.trim() === "") {
          error = "Required";
        } else if (!/^\d{4}$/.test(value.trim())) {
          error = "Must be 4-digit";
        } else {
          const year = Number(value.trim());
          if (year < 1900) {
            error = "Must be 1900 or later";
          } else if (year > currentYear) {
            error = "Future years not allowed";
          } else {
            error = "";
          }
        }
        break;
      case "end_year":
        const currentYearr = new Date().getFullYear();
        if (value.trim() === "") {
          error = "Required";
        } else if (!/^\d{4}$/.test(value.trim())) {
          error = "Must be 4-digit year";
        } else {
          const year = Number(value.trim());
          if (year < 1900) {
            error = "Must be 1900 or later";
          } else if (year > currentYearr) {
            error = "Future year not allowed";
          } else if (state.addons[index].start_year && year < Number(state.addons[index].start_year)) {
            error = "End year must be greater";
          } else {
            error = "";
          }
        }
        break;
      default:
        break;
    }
    return error;
  };

  const changeRowvalue = (e, i) => {
    e.preventDefault();
    const { name, value } = e.target;
    const error = validateField(name, value, i);
    const newErrors = {
      ...state.errors,
      [`${name}_${i}`]: error
    };
    setState(prev => ({
      ...prev,
      errors: newErrors,
      addons: [
        ...prev.addons.slice(0, i),
        { ...prev.addons[i], [name]: value },
        ...prev.addons.slice(i + 1)
      ]
    }));
    setIsChange(true);
  };

  const addRow = (e) => {
    e.preventDefault();
    setState(prev => ({
      ...prev,
      addons: [
        ...prev.addons,
        { ...AddonObj, options: [JSON.parse(JSON.stringify(optionObj))] }
      ],
    }));
    setIsChange(true);
  };

  const deleteRow = (e, index) => {
    e.preventDefault();
    const newAddons = state.addons.filter((_, i) => i !== index);
    const newErrors = { ...state.errors };
    Object.keys(newErrors).forEach(key => {
      if (key.endsWith(`_${index}`)) {
        delete newErrors[key];
      }
    });
    setState({
      addons: newAddons,
      errors: newErrors
    });
    setIsChange(true);
  };

  return (
    <Fragment>
      {state.addons &&
        state.addons.map((s, i) => (
          <div className="uper-multi-row" key={i}>
            <Row>
              <Col lg="12" className={"my-2"}>
                <h2 className="m-0 fw-bold font-weight-bold">Experience</h2>
              </Col>
              <Col md="3" xs="3">
                <TEXTFIELD className="inputPadding"
                  label="Company Name"
                  value={s.employer_name}
                  name={"employer_name"}
                  onChange={(e) => changeRowvalue(e, i)}
                  errors={{ employer_name: state.errors[`employer_name_${i}`] }}
                  required
                />
              </Col>
              <Col md="3" xs="3">
                <TEXTFIELD className="inputPadding"
                  label="Address"
                  value={s.employer_address}
                  name={"employer_address"}
                  type="text"
                  onChange={(e) => changeRowvalue(e, i)}
                  errors={{ employer_address: state.errors[`employer_address_${i}`] }}
                  required
                />
              </Col>
              <Col md="3" xs="3">
                <TEXTFIELD className="inputPadding"
                  label="Position"
                  value={s.position}
                  name={"position"}
                  type="number"
                  inputProps={{ min: "0", step: "1" }}
                  onChange={(e) => changeRowvalue(e, i)}
                  errors={{ position: state.errors[`position_${i}`] }}
                  required
                />
              </Col>
              <Col md="3" xs="3">
                <TEXTFIELD className="inputPadding"
                  label="Start Year"
                  value={s.start_year}
                  name={"start_year"}
                  onChange={(e) => changeRowvalue(e, i)}
                  errors={{ start_year: state.errors[`start_year_${i}`] }}
                  required
                  placeholder="YYYY"
                />
              </Col>
              <Col md="3" xs="3">
                <TEXTFIELD className="inputPadding"
                  label="End Year"
                  value={s.end_year}
                  name={"end_year"}
                  onChange={(e) => changeRowvalue(e, i)}
                  errors={{ end_year: state.errors[`end_year_${i}`] }}
                  required
                  placeholder="YYYY"
                />
              </Col>
              <Col md="2" xs="12" className={"align-self-end"}>
                <div className="d-flex align-items-center gap-10 mb-2">
                  {state.addons?.length > 1 && (
                    <IconButton
                      aria-label="Add"
                      onClick={(e) => deleteRow(e, i)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                  {i === state.addons.length - 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => addRow(e, i)}
                    >
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ))}
    </Fragment>
  );
};

export default React.memo(Customize);