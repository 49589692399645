import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import SidenavContent from "./SidenavContent";
import axios from "axios";
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../../constants/ActionTypes";
import { toggleCollapsedNav, updateWindowWidth } from "../../actions/Setting";
import { ApiUrl } from "../../components";
import eventEmitter from "../../../src/util/emitter";

const { API_URL, API_VERSION, API_SET_APP_NAME_LOGO } = ApiUrl;

class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      appName: "",
      appLogo: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
    eventEmitter.on("appDataUpdated", this.handleAppDataUpdated);
    this.fetchAppData();
  }

  componentWillUnmount() {
    eventEmitter.removeListener("appDataUpdated", this.handleAppDataUpdated);
  }

  handleAppDataUpdated = (updatedAppName, updatedAppLogo) => {
    console.log("Received updated app data:", updatedAppName, updatedAppLogo);
    this.setState({ appName: updatedAppName, appLogo: updatedAppLogo });
    this.fetchAppData();
  };

  fetchAppData = async () => {
    const role = localStorage.getItem("role");
    const apiUrl = `${API_URL}${API_VERSION}${API_SET_APP_NAME_LOGO}?role=${role}`;
    try {
      const response = await axios.get(apiUrl);
      if (response.data.success) {
        const { app_name, app_logo } = response.data.data;
        this.setState({ appName: app_name, appLogo: app_logo });
      }
    } catch (error) {
      console.error("Error fetching app data:", error);
    }
  };

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  toggleDrawer = (drawerOpen, open) => () => {
    this.setState({
      [drawerOpen]: open,
    });
  };

  render() {
    const lawMediation = localStorage.getItem("lawMediation");

    const { navCollapsed, drawerType, width, navigationStyle } = this.props;

    let drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-xl-flex"
      : drawerType.includes(COLLAPSED_DRAWER)
        ? ""
        : "d-flex";
    let type = "permanent";
    if (
      drawerType.includes(COLLAPSED_DRAWER) ||
      (drawerType.includes(FIXED_DRAWER) && width < 1200)
    ) {
      type = "temporary";
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = "";
      type = "temporary";
    }

    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes("temporary") ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: "side-nav",
          }}
        >
          <div
            className={
              lawMediation === "true" || lawMediation === null
                ? "app-logo manage-logo d-none d-sm-flex align-items-center justify-content-center clr-logo-hdr"
                : "app-logo clr-mediation-hdr d-none d-sm-block"
            }
            to=""
          >
            <img
              src={this.state.appLogo}
              className="imageClass"
              alt="Logo"
            />
            <span className="ml-2">{this.state.appName}</span>
          </div>
          <SidenavContent />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  return { navCollapsed, drawerType, width, navigationStyle };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav)
);