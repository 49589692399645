import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Checkbox from "../../common/checkbox";

import {
  Link,
  ApiUrl,
  Axios,
  DialogBox,
  Row,
  Col,
  SELECT,
  toast,
  Loading,
  moment,
} from "../../../../../components";

import Tooltip from "@material-ui/core/Tooltip";

const {
  API_GET_LOTS_TRASH_CASES,
  API_GENERATE_CASES,
  API_RESTORED_LOT_CASES,
  API_GET_USER_TEMPLATES_URL,
} = ApiUrl;

const Revenuetable = (props) => {
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    temp: "",
    disabled: false,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [showVenue, setVenue] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [temp, setTemp] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const toggleSearchInput = () => {
    setShowSearch((prev) => !prev);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handlePreview = (id) => {
    setShow(true);
  };

  const showErrorN = () => {
    toast.error("Please select checkbox first");
  };

  // useEffect(() => {
  //   getTemplates();
  // }, []);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  useEffect(() => {
    if (list.length === 0) {
      setIsCheckAll(false);
    }
  }, [list]);

  const handleClickOpen = () => {
    setModalVisible(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClicked = (e, id) => {
    if (e.target.checked) {
      setIsCheck([...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const getTableRecords = async () => {
    setLoading(true);
    Axios.Get(`${API_GET_LOTS_TRASH_CASES}?title=${searchTerm}`)
      .then((response) => {
        const resData = response.data.data;
        if (response.status == 200) {
          setList(resData.data || resData);
          setLoading(false);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };

  useEffect(() => {
    getTableRecords();
  }, [searchTerm]);

  const getTemplates = async () => {
    Axios.post(API_GET_USER_TEMPLATES_URL + props.editId)
      .then((response) => {
        const temp = response.data;
        const temps = temp.data.map((t) => {
          return { [t.id]: t.name };
        });
        setTemp(temps);
      })
      .catch((err) => {
        console.log("");
      });
  };

  const refresh = () => {
    getTableRecords();
  };

  const handleDownload = async () => {
    try {
      const data = {
        cases_id: isCheck,
        template_id: state.temp,
      };
      const response = await Axios.Post(API_GENERATE_CASES, data, {
        responseType: "blob",
      });
      console.log(response, "response123");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");

      a.href = url;
      a.download = "files.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading ZIP file:", error.message);
    }
  };

  const restoreAction = () => {
    const payload = {
      lot_ids: isCheck,
    };
    Axios.post(API_RESTORED_LOT_CASES, payload)
      .then((res) => {
        setModalVisible(false);
        toast.success(res.data.message);
        const updatedList = list.filter((item) => !isCheck.includes(item.id));
        setList(updatedList);
        setIsCheck([]);
        getTableRecords();
      })
      .catch((err) => {
        setModalVisible(false);
        toast.error("Cases does not restored!");
      });
  };

  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };

  const cataloge =
    list &&
    list.length > 0 &&
    list.slice(0, limit).map((item, index) => {
      return (
        <div className="timline-content" key={index}>
          <div class="px-1 time-name d-flex justify-content-between">
            <div class="d-flex">
              <Checkbox
                key={item.id}
                type="checkbox"
                name={item.label}
                id={item.id}
                handleClick={(e) => handleClicked(e, item.id)}
                isChecked={isCheck.includes(item.id)}
              // handleClick={handleClick}
              />
              <h4>
                {" "}
                <Link
                  to={{
                    pathname: `/app/admin/lot-cases/case-details/${item.id}`,
                  }}
                  onClick={() => {
                    localStorage.setItem('Pname', item.title);
                    localStorage.setItem('description', item.description);
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Case No."
                  className="d-flex"
                >
                  <span className="text-capitalize ml-3">{item.title}</span>
                </Link>
              </h4>
            </div>
            {/* <div class="right-text d-flex align-items-center">
            </div> */}
          </div>
          <div className="filesrevenue-subconatiner">
            <div className="pov123 mt-3">
              <p>
                <span className="pov123Text">Client:</span>
                {item?.users?.name}
              </p>
              <p>
                <span className="pov123Text">LOT:</span>
                {item.id}
              </p>
              <p>
                <span className="pov123Text">Created By:</span>
                {item.created_by.name}
              </p>
              <p>
                <span className="pov123Text">Created On:</span>
                {moment(item.created_at).format("DD.MM.YYYY")}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center py-2">
            <div className="">
              <div className="time-leftcontent mb-3">
                <div className="d-flex mt-2">
                  <p>
                    <span className="pov123Text">Description:</span>
                    <font>{item.description} </font>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <section className="Sales_analytics">
      {/* <DialogBox
        title={"Cases"}
        open={open}
        onClose={handleClose}
        restoreAction={restoreAction}
      /> */}
      <div fluid>
        {/* <Col sm="10">
          <Link
            to="/app/client/list"
            style={{
              color: "grey",
              fontSize: "32px",
            }}
          >
            <i
              class="zmdi zmdi-arrow-left"
              style={{ fontSize: "22px", marginTop: "2px" }}
            ></i>
          </Link>
        </Col>
        <Row>
          <Col md="3" xs="12">
            {temp.length > 0 && (
              <SELECT
                label="Template"
                name={"name"}
                onChange={(event) =>
                  setState({ temp: event.target.value, disabled: true })
                }
                data={temp}
              />
            )}
          </Col>
          <Col md="9" xs="12">
           
            <Tooltip title="Need to select Template and all checkboxes" arrow>
              <Button
                className={
                  state.disabled == true
                    ? "float-right mt-4 mr-5"
                    : "float-right mt-4 mr-5 generate-disabled"
                }
                onClick={state.disabled == true ? handleDownload : ""}
              >
                Generate
              </Button>
            </Tooltip>
          </Col>
        </Row> */}
        <div className="revenueTable-conatiner mt-4">
          <div className="revenueTable-pagination-box">
            <div className="pgination-1st">
              {" "}
              <span className="check-po-icon">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                  />
                </div>
              </span>
              <span className="check-po-icon" onClick={refresh}>
                <i class="zmdi zmdi-refresh-alt"></i>
              </span>

              <span
                className="check-po-icon"
                onClick={isCheck == "" ? showErrorN : handleClickOpen}
              >
                <i className="zmdi zmdi-undo ml-2"></i>
              </span>

              {showSearch && (
                <div>
                  <input
                    type="text"
                    className="search-input-trash1"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by Lot name"
                  />
                  <i
                    className="zmdi zmdi-close cls-btn text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                    style={{ fontSize: '20px' }}
                    onClick={() => {
                      setSearchTerm("");
                      toggleSearchInput();
                    }}
                  />
                </div>
              )}

              <span className="ml-2 mt-2 mr-1 se12 m-0 p-0" style={{ fontSize: '15px', fontWeight: 'bold' }}>Search</span>

              <span className="search56">
                <i
                  className="zmdi zmdi-search text-gray-500 m-0 p-o"
                  style={{ fontSize: '23px' }}
                  onClick={toggleSearchInput}
                />
              </span>

              {/* <span className="check-po-icon" onClick={handlePreview}>
                <i class="zmdi zmdi-filter-b-and-w"></i>
              </span> */}
            </div>

            <div className="pagination-container">
              <div className="d-flex pagination-text">
                <span className="pto-5">Show</span>
                <select
                  className="form-select page-select12"
                  aria-label="Default select example"
                  onChange={(event) => setLimit(event.target.value)}
                  value={limit || (list?.length > 10 ? "20" : list?.length)}
                >
                  {list?.length >= 10 && <option value="20">20</option>}
                  {list?.length >= 50 && <option value="50">50</option>}
                  {list?.length >= 100 && <option value="100">100</option>}
                  {list?.length >= 200 && <option value="200">200</option>}
                  {list?.length >= 500 && <option value="500">500</option>}
                  <option value={list?.length}>
                    {list === 0 ? 0 : list?.length}
                  </option>
                </select>
                <span className="pto-5">
                  per page from {list === 0 ? 0 : list && list?.length} results
                </span>
              </div>
            </div>
          </div>
          {loading === false && list && list?.length === 0 && (
            <div className="n--data text-center m-5">No data Found!</div>
          )}
          {loading === true && (
            <div className="n--data text-center m-5">
              <Loading />
            </div>
          )}
          <div className="revenue-scroll-container">{cataloge}</div>
        </div>
      </div>

      <Modal isOpen={modalVisible} toggle={handleCancel}>
        <div class=" modal-top d-flex justify-content-between py-1 pt-3 px-3">
          <p class="fs-18 fw-bold">Confirm Restore</p>
          <p className="text-right m-0">
            <span onClick={handleCancel} className="modal-cancel12">
              <i className="zmdi zmdi-close"></i>
            </span>
          </p>
        </div>
        <ModalBody className="pt-0">
          <p>Are you sure you want to restore?</p>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="modal-actions mt-4 d-flex justify-content-center gap-3 align-items-center">

              <button
                onClick={restoreAction}
                className="bg-blue-500 text-white btns-clr border-0  py-1 px-4 mr-2"
              >
                Yes
              </button>

              <button
                onClick={handleCancel}
                className="bg-gray-500 text-white btns-clr border-0  py-1 px-4 mr-2"
              >
                No
              </button>

            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal isOpen={show} toggle={handleCancel}>
        <p className="text-right m-0">
          <span onClick={handleCancel} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <p className="modal-case-12-heading">Case Limitation</p>
          <form>
            <Row>
              <Col md="4" xs="12">
                <SELECT
                  label="Chamber of arbitration"
                  name={"role"}
                  // value={role}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Source Law Firm"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Arbitrator"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Arbitrator"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </section>
  );
};

export default Revenuetable;