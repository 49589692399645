import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure({
  position: toast.POSITION.TOP_RIGHT,
});
import {
  Row,
  Col,
  Axios,
  TEXTFIELD,
  PASSWORDFIELD,
  SELECT,
  SUBMIT,
  RESET,
  NEXT,
  PREV,
  Button,
  ApiUrl,
  checkError,
  FILEUPLOAD,
  Loading,
} from "../components";
import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";
import Educationdetails from "./form-steps/educationdetails";
const {
  API_SEND_OTP,
  API_VERIFY_OTP,
  API_ARBITRATOR_REGISTER,
  EMAIL_REGEX,
  Numeric,
  PASS_VALIDATION_MSG,
  PASS_REGEX,
  API_CHECK_EMAIL
} = ApiUrl;

const SignUp = (props) => {
  const [process, setProcess] = useState(props.role);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showNext, setShowNext] = useState(true);
  const [showPrev, setShowPrev] = useState(false);
  const [showMed, setShowMed] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [phone, setPhone] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [parent_name, setParentName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [address, setAddress] = useState("");
  const [pin_code, setPin_Code] = useState("");
  const [occupation, setOccupation] = useState("");
  const [casesDoneAsSoleArbitrator, setCasesDoneAsSoleArbitrator] = useState('');
  const [allChecked, setAllChecked] = useState([]);
  const [detailsOfArbitratorConducted, setDetailsOfArbitratorConducted] = useState('');
  const [fieldOfSpecializations, setFieldOfSpecializations] = useState([]);
  const [arbitratorPanelCategoryId, setArbitratorPanelCategoryId] = useState(null);
  const [purposeOfRegistration, setPurposeOfRegistration] = useState('');
  const [otherPurposeDetails, setOtherPurposeDetails] = useState('');
  const [files, setFiles] = useState([]);
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [verifiedPhone, setVerifiedPhone] = useState("");
  const [fileNames, setFileNames] = useState({});
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const handleCategoryChange = (categoryId) => {
    setArbitratorPanelCategoryId(categoryId);
  };

  const handleSpecializationChange = (specializations) => {
    setFieldOfSpecializations(specializations);
  };

  const handleSendOtp = () => {
    const data = { phone };
    setLoading(true);
    setDisabled(true);
    Axios.post(API_SEND_OTP, data)
      .then((res) => {
        const response = res.data;
        setLoading(false);
        setDisabled(false);
        if (response.success) {
          toast.success(response.message);
          setTimeout(() => {
            setShowOtpField(true);
          }, 1000);
        } else {
          let errorMessage = "";
          if (typeof response.error === "string") {
            errorMessage = response.error;
          } else {
            errorMessage = Object.values(response.error).flat().join(' ') || 'Something went wrong';
          }
          toast.error(errorMessage);
        }
      })
      .catch((error) => {
        setLoading(false);
        setDisabled(false);
        toast.error("Fail to Send Otp");
      });
  };

  const handleVerifyOtp = () => {
    if (!otp.trim()) {
      toast.error("Please enter OTP");
      return;
    }
    const data = {
      otp: otp,
      phone: phone,
    };
    setLoading(true);
    setDisabled(true);
    Axios.post(API_VERIFY_OTP, data)
      .then((res) => {
        console.log(res, "response");
        setLoading(false);
        setDisabled(false);
        if (res.status === 422) {
          toast.error("Invalid OTP");
          return;
        }
        let response = res.data;
        toast.success(response.message);
        if (response.success === true) {
          setShowOtpField(false);
          setOtpVerified(true);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setDisabled(false);
      });
  };

  const handleEmailBlur = async () => {
    if (!email.trim()) {
      return;
    }
    setLoading(true);
    try {
      const response = await Axios.post(API_CHECK_EMAIL, { email });
      if (response.status === 403) {
        toast.error(response.data.error);
        setIsEmailVerified(false);
      } else {
        toast.success("Email Validation Successful");
        setIsEmailVerified(true);
      }
    } catch (error) {
      toast.error("Failed to validate email. Please try again later.");
      console.error("Email validation error:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleCertificates = (event, type) => {
  //   const file = event.target.files[0];
  //   let newErrors = { ...fileErrors };
  //   let newFileNames = { ...fileNames };
  //   if (file) {
  //     if (file.size > 5 * 1024 * 1024) {
  //       newErrors[type] = "File size should not exceed 5MB.";
  //       newFileNames[type] = "";
  //     } else if (!file.name.match(/\.(pdf)$/i)) {
  //       newErrors[type] = "Only PDF files are allowed.";
  //       newFileNames[type] = "";
  //     } else {
  //       newErrors[type] = "";
  //       newFileNames[type] = file.name;
  //       setFiles((prevFiles) => ({
  //         ...prevFiles,
  //         [type]: file,
  //       }));
  //     }
  //   }
  //   setFileErrors(newErrors);
  //   setFileNames(newFileNames);
  //   setDisabled(Object.values(newErrors).some((error) => error !== ""));
  // };

  const handleCertificates = (event, type) => {
    const file = event.target.files[0];
    let newErrors = { ...fileErrors };
    let newFileNames = { ...fileNames };
    if (!file) {
      newErrors[type] = "Required";
      newFileNames[type] = "";
    } else if (file.size > 5 * 1024 * 1024) {
      newErrors[type] = "File size should not exceed 5MB.";
      newFileNames[type] = "";
    } else if (!file.name.match(/\.(pdf)$/i)) {
      newErrors[type] = "Only PDF files are allowed.";
      newFileNames[type] = "";
    } else {
      newErrors[type] = "";
      newFileNames[type] = file.name;
      setFiles((prevFiles) => ({
        ...prevFiles,
        [type]: file,
      }));
    }
    setFileErrors(newErrors);
    setFileNames(newFileNames);
    setDisabled(Object.values(newErrors).some((error) => error !== ""));
  };

  useEffect(() => {
    setOtp('');
    setShowOtpField(false);
  }, [phone]);

  const itemsOBJ = {
    user_id: "",
    college_institute_name: "",
    university_name: "",
    course_name: "",
    year_of_passing: "",
  };

  const itemsOBJ2 = {
    user_id: "",
    employer_name: "",
    employer_address: "",
    position: "",
    start_year: "",
    end_year: "",
  };

  const itemsMED = {
    course_name: "",
    organiser_name: "",
    course_duration: "",
    course_year: "",
  };

  const SpecializationsData = [
    { id: 1, name: "Agency & Distribution" },
    { id: 2, name: "Engeneering/Technical" },
    { id: 3, name: "Intellectual Property" },
    { id: 4, name: "Joint Venture" },
    { id: 5, name: "Aviation" },
    { id: 6, name: "Finance Banking" },
    { id: 7, name: "International Investments" },
    { id: 8, name: "Maritime Shipping" },
    { id: 9, name: "Telecommunications" },
    { id: 10, name: "Construction" },
    { id: 11, name: "Insurance" },
  ];

  const optionObj = {
    name: "",
    price: "",
    size: "",
  };
  const itemsOBJ_new = Object.assign({}, itemsOBJ);
  const itemsOBJ_new2 = Object.assign({}, itemsOBJ2);
  const itemsOBJ_med = Object.assign({}, itemsMED);
  const AddonObj = {
    items: [{ ...itemsOBJ_new }],
  };

  const ExperienceObj = {
    catName: "",
    items: [{ ...itemsOBJ_new2 }],
    options: [{ ...optionObj }],
  };

  const MediationObj = {
    items: [{ ...itemsOBJ_med }],
  };

  const AddonObj_new = Object.assign({}, AddonObj);
  const experinenceObj_new = Object.assign({}, ExperienceObj);
  const mediationObj_new = Object.assign({}, MediationObj);

  const [education_data, setEducationData] = useState([{ ...AddonObj_new }]);
  const [experience_data, setExperienceData] = useState([
    { ...experinenceObj_new },
  ]);
  const [mediation_data, setMediationData] = useState([
    { ...mediationObj_new },
  ]);
  const [list, setList] = useState([...SpecializationsData]);
  const [date_of_birth, setDateOfBirth] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    phone: "",
    enrollmentNumber: "",
    gender: "",
    date_of_birth: "",
    pinCode: "",
    barLicense: "",
    address: "",
  });
  // const [fileErrors, setFileErrors] = useState({});
  const [fileErrors, setFileErrors] = useState({
    matric_document: "Required",
    graduation_document: "Required",
    id_proof_document: "Required",
    experience_document: "",
    training_document: "",
  });

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setTermsAccepted(checked);
    updateDisabled(!checked);
  };

  const specialization = (e) => {
    if (e.target && e.target.checked) {
      setAllChecked((prevChecked) => [...prevChecked, e.target?.value]);
    } else if (e.target) {
      setAllChecked((prevChecked) =>
        prevChecked.filter((item) => item !== e.target?.value)
      );
    }
  };

  const updateDisabled = (value) => {
    setDisabled(value);
  };

  const nextStep = () => {
    if (step === 1 && !isStep1Completed()) {
      toast.info("Step 1 is not completed yet.");
      return;
    }

    if (step === 1 && !otpVerified) {
      toast.info("Please verify the OTP before proceeding.");
      return;
    }

    if (step === 1 && !isEmailVerified) {
      toast.info("Please Enter Valid email before proceeding.");
      return;
    }

    // if (step === 2 && !isStep2Completed()) {
    //   toast.info("Step 2 is not completed yet.");
    //   return;
    // }

    if (step === 2) {
      if (!isStep2Completed()) {
        toast.info("Step 2 is not completed yet.");
        return;
      }
      const requiredFiles = ["matric_document", "graduation_document", "id_proof_document"];
      let missingFiles = [];

      requiredFiles.forEach((field) => {
        if (!files[field]) {
          missingFiles.push(field.replace("_document", "").replace(/^\w/, c => c.toUpperCase()));
          setFileErrors((prev) => ({
            ...prev,
            [field]: "Required",
          }));
        }
      });
      if (missingFiles.length > 0) {
        toast.error(`Please upload the following required documents: ${missingFiles.join(", ")}.`);
        return;
      }
    }

    setStep(step + 1);
    if (step === 1) {
      setShowMed("med");
      setShowPrev(true);
    } else {
      setShowMed("");
    }
    if (step === 3) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }
  };

  const isStep2Completed = () => {
    const isEducationCompleted = education_data.every(
      item =>
        item &&
        item.college_institute_name &&
        item.college_institute_name.trim() !== "" &&
        item.university_name &&
        item.university_name.trim() !== "" &&
        item.course_name &&
        item.course_name.trim() !== "" &&
        item.year_of_passing &&
        item.year_of_passing.trim() !== ""
    );

    const isExperienceCompleted = experience_data.every(
      exp =>
        exp &&
        exp.employer_name &&
        exp.employer_name.trim() !== "" &&
        exp.employer_address &&
        exp.employer_address.trim() !== "" &&
        exp.position &&
        exp.position.trim() !== "" &&
        exp.start_year &&
        exp.start_year.trim() !== "" &&
        exp.end_year &&
        exp.end_year.trim() !== ""
    );

    const isDetailsCompleted =
      casesDoneAsSoleArbitrator &&
      casesDoneAsSoleArbitrator.trim() !== "" &&
      detailsOfArbitratorConducted &&
      detailsOfArbitratorConducted.trim() !== "";
    const isArbitratorPanelIdSelected = arbitratorPanelCategoryId !== null;
    const isFieldOfSpecializationsSelected = Array.isArray(fieldOfSpecializations) && fieldOfSpecializations.length > 0;
    return isEducationCompleted && isExperienceCompleted && isDetailsCompleted && isArbitratorPanelIdSelected && isFieldOfSpecializationsSelected;
  };

  const isStep1Completed = () => {
    return (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      parent_name !== "" &&
      age !== "" &&
      gender !== "" &&
      address !== "" &&
      pin_code !== "" &&
      occupation !== "" &&
      date_of_birth !== ""
    );
  };

  const prevStep = () => {
    setStep(step - 1);
    if (step == 1 && step == 2) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let newErrors = { ...errors };

    switch (name) {
      case "name":
        newErrors.name = value.trim().length < 1 ? "Required" : "";
        setName(value);
        break;
      case "address":
        newErrors.address = value.trim().length < 1 ? "Required" : "";
        setAddress(value);
        break;
      case "role":
        newErrors.role = value.trim().length < 1 ? "Required" : "";
        break;
      case "email":
        newErrors.email = value.trim().length < 1 ? "Required" : !EMAIL_REGEX.test(value) ? "Invalid email" : "";
        setEmail(value.trim());
        break;
      case "phone":
        const newPhone = value.trim();
        if (newPhone.length < 1) {
          newErrors.phone = "Required";
        } else if (!Numeric.test(newPhone)) {
          newErrors.phone = "Only numeric allowed";
        } else if (newPhone.length < 10 || newPhone.length > 10) {
          newErrors.phone = "Minimum 10 digits required";
        } else {
          newErrors.phone = "";
        }
        if (newPhone !== phone) {
          setOtpVerified(false);
        }
        setPhone(newPhone);
        break;
      case "password":
        newErrors.password = value.trim().length < 1 ? "Required" : !PASS_REGEX.test(value) ? "Invalid format" : "";
        setPassword(value.trim());
        if (password_confirmation && value.trim() !== password_confirmation.trim()) {
          newErrors.password_confirmation = "Passwords do not match";
        } else {
          newErrors.password_confirmation = "";
        }
        break;
      case "password_confirmation":
        newErrors.password_confirmation = value.trim().length < 1 ? "Required" : value.trim() !== password.trim() ? "Passwords do not match" : "";
        setPasswordConfirmation(value.trim());
        break;
      case "date_of_birth":
        if (value.trim().length < 1) {
          newErrors.date_of_birth = "Required";
        } else if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(value)) {
          newErrors.date_of_birth = "Invalid date format (YYYY-MM-DD)";
        } else {
          const [year, month, day] = value.split("-").map(Number);
          const enteredDate = new Date(year, month - 1, day);
          const today = new Date();
          if (month < 1 || month > 12) {
            newErrors.date_of_birth = "Month must be between 1 and 12";
          } else if (day < 1 || day > 31) {
            newErrors.date_of_birth = "Day must be between 1 and 31";
          } else if (enteredDate.getFullYear() !== year || enteredDate.getMonth() + 1 !== month || enteredDate.getDate() !== day) {
            newErrors.date_of_birth = "Invalid date";
          } else if (enteredDate > today) {
            newErrors.date_of_birth = "Future dates are not allowed";
          } else {
            newErrors.date_of_birth = "";
          }
        }
        setDateOfBirth(value.trim());
        break;
      case "parent_name":
        newErrors.parent_name = value.trim().length < 1 ? "Required" : "";
        setParentName(value);
        break;
      case "age":
        if (value.trim().length < 1) {
          newErrors.age = "Required";
        } else if (!Numeric.test(value.trim())) {
          newErrors.age = "Only numeric allowed";
        } else {
          newErrors.age = "";
        }
        setAge(value.trim());
        break;
      case "occupation":
        newErrors.occupation = value.trim().length < 1 ? "Required" : "";
        setOccupation(value);
        break;
      case "pin_code":
        if (value.trim().length < 1) {
          newErrors.pin_code = "Required";
        } else if (!/^\d{6}$/.test(value.trim())) {
          newErrors.pin_code = "Pin code must be 6 digits";
        } else {
          newErrors.pin_code = "";
        }
        setPin_Code(value.trim());
        break;
      case "gender":
        newErrors.gender = value.trim().length < 1 ? "Required" : "";
        setGender(value.trim());
        break;
      case "nationality":
        newErrors.nationality = value.trim().length < 1 ? "Required" : "";
        setNationality(value.trim());
        break;
      case "purpose_of_registration":
        newErrors.purpose_of_registration = value.trim().length < 1 ? "Required" : "";
        setPurposeOfRegistration(value.trim());
        break;
      case "other_purpose_details":
        newErrors.other_purpose_details = value.trim().length < 1 && purposeOfRegistration === "Other" ? "Required" : "";
        setOtherPurposeDetails(value);
        break;
      case "cases_done_as_sole_arbitrator":
        if (!/^\d*$/.test(value.trim())) {
          newErrors.cases_done_as_sole_arbitrator = "Only digits are allowed";
        } else {
          setCasesDoneAsSoleArbitrator(value.trim());
          newErrors.cases_done_as_sole_arbitrator = value.trim().length === 0 ? "Required" : "";
        }
        break;
      case "details_of_arbitrator_conducted":
        setDetailsOfArbitratorConducted(value);
        newErrors.details_of_arbitrator_conducted = value.trim().length === 0 ? "Required" : "";
        break;
      case "arbitrator_Panel_Category_Id":
        newErrors.arbitrator_Panel_Category_Id = value.trim().length < 1 ? "Required" : "";
        break;
      case "field_of_specializations":
        newErrors.field_of_specializations = value.trim().length < 1 ? "Required" : "";
        break;
      default:
        break;
    }
    setErrors(newErrors);
    setDisabled(checkError(newErrors).disabled || Object.values(fileErrors).some((error) => error !== ""));
    // setDisabled(checkError(newErrors).disabled);
  };

  const updateAddons = (education_data) => {
    setEducationData(education_data);
    setDisabled(false);
  };

  const updateExperience = (experience_data) => {
    setExperienceData(experience_data);
    setDisabled(false);
  };

  const updateMediation = (mediation_data) => {
    setMediationData(mediation_data);
    setDisabled(false);
  };

  const redirectPage = () => {
    props.history.push("/app/signin");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setDisabled(true);

    if (!termsAccepted) {
      toast.info('Please agree to the terms and conditions.');
      setLoading(false);
      setDisabled(false);
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("password_confirmation", password_confirmation);
    formData.append("phone", phone);
    formData.append("date_of_birth", date_of_birth);
    formData.append("parent_name", parent_name);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("nationality", nationality);
    formData.append("address", address);
    formData.append("pin_code", pin_code);
    formData.append("occupation", occupation);
    formData.append("purpose_of_registration", purposeOfRegistration);
    formData.append("other_purpose_details", otherPurposeDetails);
    formData.append("arbitrator_panel_category_id", arbitratorPanelCategoryId);
    formData.append("document_type", "certificates");
    formData.append(
      "field_of_specializations",
      Array.isArray(fieldOfSpecializations)
        ? fieldOfSpecializations.join(", ")
        : fieldOfSpecializations
    );
    formData.append("cases_done_as_sole_arbitrator", casesDoneAsSoleArbitrator);
    formData.append(
      "details_of_arbitrator_conducted",
      detailsOfArbitratorConducted
    );
    education_data.forEach((item, index) => {
      formData.append(`education_data[${index}][college_institute_name]`, item.college_institute_name);
      formData.append(`education_data[${index}][university_name]`, item.university_name);
      formData.append(`education_data[${index}][course_name]`, item.course_name);
      formData.append(`education_data[${index}][year_of_passing]`, item.year_of_passing);
    });
    experience_data.forEach((exp, index) => {
      formData.append(`experience_data[${index}][employer_name]`, exp.employer_name);
      formData.append(`experience_data[${index}][employer_address]`, exp.employer_address);
      formData.append(`experience_data[${index}][position]`, exp.position);
      formData.append(`experience_data[${index}][start_year]`, exp.start_year);
      formData.append(`experience_data[${index}][end_year]`, exp.end_year);
    });
    mediation_data.forEach((item, index) => {
      formData.append(`training_data[${index}][course_name]`, item.course_name);
      formData.append(`training_data[${index}][organizer_name]`, item.organiser_name);
      formData.append(`training_data[${index}][course_duration]`, item.course_duration);
      formData.append(`training_data[${index}][course_year]`, item.course_year);
    });
    Object.entries(files).forEach(([key, file]) => {
      if (file) {
        formData.append(key, file);
      }
    });

    Axios.post(API_ARBITRATOR_REGISTER, formData)
      .then((res) => {
        if (res.status === 422) {
          toast.error("Mobile Verification Pending");
          setLoading(false);
          setDisabled(false);
          return;
        }
        const response = res.data;
        setLoading(false);
        setDisabled(false);
        if (response.sucess) {
          toast.success("Registration successful!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            redirectPage();
          }, 1000);
        } else if (response.error) {
          toast.error(response.errors.phone[0], {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Registration failed. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        const res = error.response;
        console.error("Error:", error);
        if (res && res.message) {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setLoading(false);
        setDisabled(false);
      });
  };

  const showSteps = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Name"
                value={name}
                name={"name"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Email"
                value={email}
                name={"email"}
                onChange={handleChange}
                onBlur={handleEmailBlur}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Mobile No."
                name="phone"
                onChange={handleChange}
                value={phone}
                errors={errors}
              />
              {phone.length === 10 && !showOtpField && !otpVerified && phone !== verifiedPhone && (
                <Button variant="primary" className="mt-2" onClick={handleSendOtp}>
                  Send OTP
                </Button>
              )}
            </Col>
            {showOtpField && (
              <Col md="4" xs="12">
                <TEXTFIELD
                  label="Enter OTP"
                  name="otp"
                  onChange={(e) => setOtp(e.target.value)}
                  value={otp}
                  errors={{}}
                />
                <Button variant="success" className="mt-2" onClick={handleVerifyOtp}>
                  Verify OTP
                </Button>
              </Col>
            )}
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Address"
                value={address}
                name={"address"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Date of Birth"
                placeholder="XXXX-XX-XX"
                value={date_of_birth}
                name={"date_of_birth"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Parent Name"
                value={parent_name}
                name={"parent_name"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Age"
                placeholder="age in years"
                value={age}
                name={"age"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                label="Occupation"
                value={occupation}
                name={"occupation"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <TEXTFIELD
                placeholder="XXXXXX"
                label="Pin Code"
                value={pin_code}
                name={"pin_code"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12" className="mt-3">
              <SELECT
                label="Gender"
                value={gender}
                name={"gender"}
                onChange={handleChange}
                errors={errors}
                data={[{ 1: "Male" }, { 0: "Female" }]}
              />
            </Col>
            <Col md="4" xs="12" className="mt-3">
              <SELECT
                label="Nationality"
                value={nationality}
                name="nationality"
                onChange={handleChange}
                errors={errors}
                data={[{ 1: "Indian" }]}
              />
            </Col>
            <Col md="4" xs="12">
              <PASSWORDFIELD
                label="Password"
                value={password}
                name={"password"}
                edit={process === "edit"}
                onChange={handleChange}
                note={PASS_VALIDATION_MSG}
                errors={errors}
              />
            </Col>
            <Col md="4" xs="12">
              <PASSWORDFIELD
                label="Confirm Password"
                value={password_confirmation}
                name={"password_confirmation"}
                edit={process === "edit"}
                onChange={handleChange}
                note={PASS_VALIDATION_MSG}
                errors={errors}
              />
            </Col>
          </>
        );
      case 2:
        return (
          <>
            <Educationdetails
              data={education_data}
              updateAddons={updateAddons}
              dataExperience={experience_data}
              updateExperience={updateExperience}
              optionObj={optionObj}
              dataMediation={mediation_data}
              updateMediation={updateMediation}
              specialization={specialization}
              SpecializationsData={list}
              arbitratorPanelCategoryId={arbitratorPanelCategoryId}
              fieldOfSpecializations={fieldOfSpecializations}
              onCategoryChange={handleCategoryChange}
              onSpecializationChange={handleSpecializationChange}
              handleChange={handleChange}
            />

            <p className="w-100 exp-txt ml-2">Arbitration Experience</p>
            <Col md="3" xs="12">
              <TEXTFIELD
                label="No. of cases done as Sole Arbitrator"
                value={casesDoneAsSoleArbitrator}
                name={"cases_done_as_sole_arbitrator"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>
            <Col md="6" xs="12">
              <TEXTFIELD
                label="Details of Arbitrator conducted"
                value={detailsOfArbitratorConducted}
                name={"details_of_arbitrator_conducted"}
                onChange={handleChange}
                errors={errors}
              />
            </Col>

            <p className="w-100 exp-txt ml-2 mt-3">Purpose of Registration</p>
            <Col md="4" xs="12">
              <select
                name="purpose_of_registration"
                value={purposeOfRegistration}
                onChange={handleChange}
                className="form-control dropdpwn-form"
              >
                <option value="">Select Purpose</option>
                <option value="For registration as arbitrator on JustSettle_ODR">For Online Dispute Resolution</option>
                <option value="For registration as an arbitrator for an adhoc case">For arbitrator for an adhoc case</option>
                <option value="Other">Other</option>
              </select>
            </Col>
            {purposeOfRegistration === "Other" && (
              <Col md="6" xs="12">
                <TEXTFIELD
                  label="Other Purpose Details"
                  value={otherPurposeDetails}
                  name="other_purpose_details"
                  onChange={handleChange}
                  errors={errors}
                  className="purpose-dtl"
                />
              </Col>
            )}

            <p className="w-100 exp-txt ml-2 mt-3 mb-0">
              Please Upload Educational and Experience Certificates In PDF.
            </p>
            <Row>
              <Col md="4" xs="12">
                <FILEUPLOAD
                  label="Matric"
                  type="file"
                  name="matric_document"
                  onChange={(e) => handleCertificates(e, "matric_document")}
                  accept="application/pdf"
                />
                {fileNames.matric_document && (
                  <p className="text-success mt-2">{fileNames.matric_document}</p>
                )}
                {fileErrors.matric_document && (
                  <p className="text-danger mt-2">{fileErrors.matric_document}</p>
                )}
              </Col>
              <Col md="4" xs="12">
                <FILEUPLOAD
                  label="Graduation"
                  type="file"
                  name="graduation_document"
                  onChange={(e) => handleCertificates(e, "graduation_document")}
                  accept="application/pdf"
                />
                {fileNames.graduation_document && (
                  <p className="text-success mt-2">{fileNames.graduation_document}</p>
                )}
                {fileErrors.graduation_document && (
                  <p className="text-danger mt-2">{fileErrors.graduation_document}</p>
                )}
              </Col>
              <Col md="4" xs="12">
                <FILEUPLOAD
                  label="Experience"
                  type="file"
                  name="experience_document"
                  onChange={(e) => handleCertificates(e, "experience_document")}
                  accept="application/pdf"
                />
                {fileNames.experience_document && (
                  <p className="text-success mt-2">{fileNames.experience_document}</p>
                )}
                {fileErrors.experience_document && (
                  <p className="text-danger mt-2">{fileErrors.experience_document}</p>
                )}
              </Col>
              <Col md="6" xs="12">
                <FILEUPLOAD
                  label="Training"
                  type="file"
                  name="training_document"
                  onChange={(e) => handleCertificates(e, "training_document")}
                  accept="application/pdf"
                />
                {fileNames.training_document && (
                  <p className="text-success mt-2">{fileNames.training_document}</p>
                )}
                {fileErrors.training_document && (
                  <p className="text-danger mt-2">{fileErrors.training_document}</p>
                )}
              </Col>
              <Col md="6" xs="12">
                <FILEUPLOAD
                  label="ID Proof Document"
                  type="file"
                  name="id_proof_document"
                  onChange={(e) => handleCertificates(e, "id_proof_document")}
                  accept="application/pdf"
                />
                {fileNames.id_proof_document && (
                  <p className="text-success mt-2">{fileNames.id_proof_document}</p>
                )}
                {fileErrors.id_proof_document && (
                  <p className="text-danger mt-2">{fileErrors.id_proof_document}</p>
                )}
              </Col>
            </Row>
          </>
        );
      case 3:
        return (
          <Fragment>
            <div fluid>
              <Row>
                <h4 className="terms-txt">Terms & Conditions</h4>
                <Col md="12" xs="12">
                  <div>
                    <p className="undertaking1 ml-3">Undertaking:-</p>
                    <ul className="undertaking1-list">
                      <li>
                        I agree to abide by the rules of Mediation of the Just Settle
                        including Guidelines and Code of Conduct.
                      </li>
                      <li>
                        I shall take up and complete the Mediation assigned to me with
                        utmost deligence and expedition.
                      </li>
                      <li>
                        All mediators being of equal status, I shall not object to my
                        appointment to as mediator in a particular case on the basis
                        of my previous status or that of any appointed mediator.
                      </li>
                      <li>
                        I have not been convicted or charged of any offence o any
                        criminal investigation or vegilance enquiry pending against
                        me.
                      </li>
                      <li>
                        I declare that i have not been removed as mediator in
                        circumstances where moral probity or incompetence were an
                        issue.{" "}
                      </li>
                      <li>I declare that above particulars furnished are true and correct. </li>
                    </ul>
                    <div class="form-check ml-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={termsAccepted}
                        onChange={handleCheckboxChange}
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        I Agree
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <div style={{ paddingTop: "17vh" }}></div>
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <Loading />
          </div>
        )}
        <div className="app-login-container mediator-signup-form d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-login-content w-100">
              <div className="">
                <h1>Sign Up as Arbitrator</h1>
              </div>
              <div className="app-login-forma">
                <form onSubmit={handleSubmit} noValidate>
                  <ToastContainer style={{ top: "5em" }} />
                  <Row>{showSteps()}</Row>
                  <Row>
                    {step > 1 && (
                      <Col md="3" xs="12">
                        <PREV className="next-btn1" onClick={prevStep}>
                          Prev
                          <i class="zmdi zmdi-arrow-right"></i>
                        </PREV>
                      </Col>
                    )}
                    {step === 3 ? (
                      <Col md="3" xs="12">
                        <SUBMIT title="Submit" disabled={disabled} />
                      </Col>
                    ) : (
                      <Col md="3" xs="12">
                        <NEXT className="next-btn1" onClick={nextStep}>
                          Next <i class="zmdi zmdi-arrow-right"></i>
                        </NEXT>
                      </Col>
                    )}
                  </Row>
                  <Link to="/signin">Already have an account</Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      <div style={{ paddingTop: "17vh" }}></div>
      <Footer />
    </>
  );
};

export default withRouter(SignUp);