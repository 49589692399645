import React, { useState, useEffect } from "react";
import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  SELECT,
  TEXTFIELD,
  SUBMIT,
  RESET,
  ApiUrl,
  Loading,
} from "../../../../../components";

import eventEmitter from "../../../../../../src/util/emitter";

const { API_SET_APP_NAME_LOGO, API_GET_ALL_ROLES } = ApiUrl;

const AppSetting = () => {
  const [appName, setAppName] = useState("");
  const [logo, setLogo] = useState(null);
  const [fileName, setFileName] = useState("");
  const [logoPreview, setLogoPreview] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [description, setDescription] = useState("");
  
  const fetchAppDetails = async (role) => {
    try {
      setLoading(true);
      const response = await Axios.get(`${API_SET_APP_NAME_LOGO}?role=${role}`);
      const { success, message, data } = response.data;
      if (success) {
        setAppName(data.app_name);
        setFileName(data.app_logo);
        setLogo(data.app_logo);
        setDescription(data.description);
        setLogoPreview(data.app_logo);
        setSelectedRole(data.role);
      } else {
        toast.error(message || "Failed to fetch app details");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "No data found for the given role");
      setAppName("");
      setLogo(null);
      setFileName("");
      setLogoPreview("");
      setDescription("");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppDetails(selectedRole || "Superadmin");
  }, [selectedRole]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(API_GET_ALL_ROLES);
      const { success, message, data } = response.data;
      if (success) {
        const roles = data.map((c) => {
          return { [c.name]: c.name };
        });
        setRoleList(roles);
      } else {
        toast.error(message || "Failed to fetch roles");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching roles");
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = (e) => {
    const newRole = e.target.value;
    setSelectedRole(newRole);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!logo) {
      toast.error("App Logo is required");
      return;
    }
    try {
      setLoading(true);
      setDisabled(true);
      const formData = new FormData();
      formData.append("app_name", appName);
      formData.append("app_logo", logo);
      formData.append("description", description);
      formData.append("role", selectedRole);
      const response = await Axios.post(API_SET_APP_NAME_LOGO, formData);
      const { success, message, data } = response.data;
      if (success) {
        toast.success(message);
        if (selectedRole === "Superadmin") {
        eventEmitter.emit("appDataUpdated", data.app_name, data.app_logo);
        }
        fetchAppDetails(selectedRole);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];

      if (!validImageTypes.includes(file.type)) {
        toast.error("Please select a valid image file (JPEG, PNG, GIF, WEBP)");
        e.target.value = "";
        return;
      }
      setLogo(file);
      setFileName(file.name);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const resetForm = () => {
    setAppName("");
    setLogo(null);
    setFileName("");
    setLogoPreview("");
    // setSelectedRole("");
    setDescription("");
    fetchAppDetails(selectedRole);
    document.getElementById("fileInput").value = "";
  };

  return (
    <div style={{ backgroundColor: "white", padding: "20px", marginTop: "20px", marginRight: "20px" }}>
      {loading && <div className="text-center w-100"><Loading /></div>}

      <form onSubmit={handleSubmit} noValidate>

        <Row>
          <Col md="9" xs="12">
            <Row>
              <Col md="6" xs="12">
                <TEXTFIELD
                  label="App Name"
                  value={appName}
                  name="appName"
                  onChange={(e) => setAppName(e.target.value)}
                />
              </Col>
              <Col md="6" xs="12">
                <TEXTFIELD
                  label="Title"
                  value={description}
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
              <Col md="6" xs="12" className="mt-2">
                <div className="file-upload-cus">
                  <div className="file-select">
                    <div className="file-select-button">Choose logo</div>
                    <div className="file-select-name">{fileName || "No logo chosen..."}</div>
                    <input
                      type="file"
                      accept="image/*"
                      id="fileInput"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </Col>
              <Col md="6" xs="12" className="mt-3">
                <SELECT
                  label="Role"
                  value={selectedRole}
                  name="role"
                  onChange={handleRoleChange}
                  data={[{ Home: "Home", value: "home" }, ...roleList]}
                />
              </Col>
            </Row>
          </Col>
          <Col md="3" xs="12" className="align-items-center d-flex justify-content-center text-center text-center">
            {logoPreview && (
              <img
                src={logoPreview}
                alt="App Logo"
                style={{ maxWidth: "150px", maxHeight: "150px", objectFit: "cover", border: "1px solid #ccc", borderRadius: "5px" }}
              />
            )}
          </Col>

        </Row>
        <Row>
          <Col md="2" xs="12">
            <SUBMIT title="Submit" disabled={disabled} />
          </Col>
          <Col md="2" xs="12">
            <RESET title="Reset" onClick={resetForm} />
          </Col>
        </Row>

      </form>
    </div>
  );
};

export default withRouter(AppSetting);