import React, { useEffect, useState, useRef } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from "classnames";

import {
  Axios,
  Loading,
  Row,
  Col,
  TEXTFIELD,
  SUBMIT,
  toast
} from "../../../../../../../components";

import {
  API_GET_CASE_STATUS,
  API_GET_USER_STAGES_URL,
  API_GET_DOCUMENTS_URL,
  API_CREATE_STATUS,
  API_UPDATE_STATUS,
  API_DELETE_STATUS,
  API_CREATE_STAGE,
  API_UPDATE_STAGE_ADMIN,
  API_DELETE_STAGE,
  API_CREATE_DOCUMENT,
  API_UPDATE_DOCUMENT,
  API_DELETE_DOCUMENT
} from "../../../../../../../ApiUrl";

const Casetabs = () => {
  const headerRef = useRef(null);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [caseStatus, setCaseStatus] = useState([]);
  const [documentStage, setDocumentStage] = useState([]);
  const [documentName, setDocumentName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const [addforStage, setaddforStage] = useState(false);
  const [addforDocument, setaddforDocument] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ name: "", type: "" });
  const [editId, setEditId] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermStage, setsearchTermStage] = useState("");
  const [searchTermDocument, setsearchTermDocument] = useState("");
  const [currentPageCaseStatus, setCurrentPageCaseStatus] = useState(1);
  const [limitCaseStatus, setLimitCaseStatus] = useState(10);
  const [totalRecordsCaseStatus, setTotalRecordsCaseStatus] = useState(0);
  const [currentPageDocumentStage, setCurrentPageDocumentStage] = useState(1);
  const [limitDocumentStage, setLimitDocumentStage] = useState(10);
  const [totalRecordsDocumentStage, setTotalRecordsDocumentStage] = useState(0);
  const [currentPageDocumentName, setCurrentPageDocumentName] = useState(1);
  const [limitDocumentName, setLimitDocumentName] = useState(10);
  const [totalRecordsDocumentName, setTotalRecordsDocumentName] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchStage, setShowSearchStage] = useState(false);
  const [showSearchDocument, setShowSearchDocument] = useState(false);

  useEffect(() => {
    const savedTab = localStorage.getItem("CRUDActiveTab");
    if (savedTab) {
      setCurrentActiveTab(savedTab);
    }
  }, []);

  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    localStorage.setItem("CRUDActiveTab", tab);
  };

  const toggleSearchInput = () => {
    setShowSearch((prev) => !prev);
  };

  const toggleSearchInputStage = () => {
    setShowSearchStage((prev) => !prev);
  };

  const toggleSearchInputDocument = () => {
    setShowSearchDocument((prev) => !prev);
  };

  useEffect(() => {
    fetchCaseStatus();
    fetchDocumentStage();
    fetchDocumentName();
  }, []);

  useEffect(() => {
    fetchCaseStatus();
  }, [currentPageCaseStatus, searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    fetchDocumentStage();
  }, [currentPageDocumentStage, searchTermStage]);

  const handleSearchChangeStage = (e) => {
    setsearchTermStage(e.target.value);
  };

  useEffect(() => {
    fetchDocumentName();
  }, [currentPageDocumentName, searchTermDocument]);

  const handleSearchChangeDocument = (e) => {
    setsearchTermDocument(e.target.value);
  };

  const fetchCaseStatus = async () => {
    setLoading(true);
    try {
      const url = searchTerm
        ? `${API_GET_CASE_STATUS}?search=${searchTerm}`
        : `${API_GET_CASE_STATUS}?search=${searchTerm}&limit=${limitCaseStatus}&page=${currentPageCaseStatus}`;
      const response = await Axios.get(url);
      setCaseStatus(response.data.data);
      setTotalRecordsCaseStatus(response.data.total);
    } catch (error) {
      console.error("Error fetching case status:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentStage = async () => {
    setLoading(true);
    try {
      const url = searchTermStage
        ? `${API_GET_USER_STAGES_URL}?search=${searchTermStage}`
        : `${API_GET_USER_STAGES_URL}?search=${searchTermStage}&limit=${limitDocumentStage}&page=${currentPageDocumentStage}`;
      const response = await Axios.get(url);
      setDocumentStage(response.data.data);
      setTotalRecordsDocumentStage(response.data.total);
    } catch (error) {
      console.error("Error fetching document stages:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentName = async () => {
    setLoading(true);
    try {
      const url = searchTermDocument
        ? `${API_GET_DOCUMENTS_URL}?search=${searchTermDocument}`
        : `${API_GET_DOCUMENTS_URL}?search=${searchTermDocument}&limit=${limitDocumentName}&page=${currentPageDocumentName}`;
      const response = await Axios.get(url);
      setDocumentName(response.data.data);
      setTotalRecordsDocumentName(response.data.total);
    } catch (error) {
      console.error("Error fetching document names:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChangeCaseStatus = (direction) => {
    if (direction === "next") {
      setCurrentPageCaseStatus((prev) => prev + 1);
    } else {
      setCurrentPageCaseStatus((prev) => prev - 1);
    }
  };

  const handlePageChangeDocumentStage = (direction) => {
    if (direction === "next") {
      setCurrentPageDocumentStage((prev) => prev + 1);
    } else {
      setCurrentPageDocumentStage((prev) => prev - 1);
    }
  };

  const handlePageChangeDocumentName = (direction) => {
    if (direction === "next") {
      setCurrentPageDocumentName((prev) => prev + 1);
    } else {
      setCurrentPageDocumentName((prev) => prev - 1);
    }
  };

  const handleEdit = (status) => {
    setEditMode(true);
    setEditId(status.id);
    setFormData({ name: status.name, type: status.type });
    setAdd(true);
    setTimeout(() => {
      headerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this case status?")) {
      return;
    }
    try {
      await Axios.post(`${API_DELETE_STATUS}/${id}`);
      toast.success("Status Deleted Successfully!");
      setAdd(false);
      fetchCaseStatus();
    } catch (error) {
      console.error("Error deleting case status:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (editMode) {
        response = await Axios.post(`${API_UPDATE_STATUS}/${editId}`, formData);
        if (response.data.success === false) {
          const errorMessage = response.data.error?.name?.[0];
          toast.error(errorMessage);
          return;
        }
        toast.success("Status Updated Successfully!");
      } else {
        response = await Axios.post(API_CREATE_STATUS, formData);
        if (response.data.success === false) {
          const errorMessage = response.data.error?.name?.[0];
          toast.error(errorMessage);
          return;
        }
        toast.success("Status Created Successfully!");
      }
      fetchCaseStatus();
      setAdd(false);
      setEditMode(false);
      setFormData({ name: "", type: "" });
    } catch (error) {
      console.error("Error saving case status:", error);
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data?.error?.name?.[0] || "Forbidden: Action not allowed.";
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleEditStage = (stage) => {
    setEditMode(true);
    setEditId(stage.id);
    setFormData({ stage: stage.stage, for: stage.for });
    setaddforStage(true);
    setTimeout(() => {
      headerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  const handleDeleteStage = async (id) => {
    if (!window.confirm("Are you sure you want to delete this document stage?")) {
      return;
    }
    try {
      await Axios.post(`${API_DELETE_STAGE}/${id}`);
      toast.success("Stage Deleted Successfully!");
      setaddforStage(false);
      fetchDocumentStage();
    } catch (error) {
      console.error("Error deleting document stage:", error);
    }
  };

  const handleSubmitStage = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (editMode) {
        response = await Axios.post(`${API_UPDATE_STAGE_ADMIN}/${editId}`, formData);
        if (response.data.success === false) {
          const errorMessage = response.data.error?.stage?.[0];
          toast.error(errorMessage);
          return;
        }
        toast.success("Stage Updated Successfully!");
      } else {
        response = await Axios.post(API_CREATE_STAGE, formData);
        if (response.data.success === false) {
          const errorMessage = response.data.error?.stage?.[0];
          toast.error(errorMessage);
          return;
        }
        toast.success("Stage Created Successfully!");
      }
      fetchDocumentStage();
      setaddforStage(false);
      setEditMode(false);
      setFormData({ stage: "", for: "" });
    } catch (error) {
      console.error("Error saving document stage:", error);
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data?.error?.stage?.[0] || "Forbidden: Action not allowed.";
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleEditDocument = (doc) => {
    setEditMode(true);
    setEditId(doc.id);
    setFormData({ name: doc.name, type: doc.type, document_type: doc.document_type, for: doc.for });
    setaddforDocument(true);
    setTimeout(() => {
      headerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  const handleDeleteDocument = async (id) => {
    if (!window.confirm("Are you sure you want to delete this document?")) {
      return;
    }
    try {
      await Axios.post(`${API_DELETE_DOCUMENT}/${id}`);
      toast.success("Document Deleted Successfully!");
      setaddforDocument(false);
      fetchDocumentName();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleSubmitDocument = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (editMode) {
        response = await Axios.post(`${API_UPDATE_DOCUMENT}/${editId}`, formData);
        if (response.data.success === false) {
          const errorMessage = response.data.error?.name?.[0];
          toast.error(errorMessage);
          return;
        }
        toast.success("Document Updated Successfully!");
      } else {
        response = await Axios.post(API_CREATE_DOCUMENT, formData);
        if (response.data.success === false) {
          const errorMessage = response.data.error?.name?.[0];
          toast.error(errorMessage);
          return;
        }
        toast.success("Document Created Successfully!");
      }
      fetchDocumentName();
      setaddforDocument(false);
      setEditMode(false);
      setFormData({ name: "", type: "", document_type: "", for: "" });
    } catch (error) {
      console.error("Error saving document:", error);
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data?.error?.name?.[0] || "Forbidden: Action not allowed.";
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div ref={headerRef} className="mt-3 mr-3 mb-3">
      <div className="padding-30 bg-white">
        <Nav tabs className="mb-1">
          <NavItem>
            <NavLink
              className={classnames({ active: currentActiveTab === "1" })}
              onClick={() => toggle("1")}
            >
              Case Status
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: currentActiveTab === "2" })}
              onClick={() => toggle("2")}
            >
              Document Stage
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: currentActiveTab === "3" })}
              onClick={() => toggle("3")}
            >
              Document Name
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={currentActiveTab}>

          <TabPane tabId="1">
            <div className="w-100 float-right row m-0">
              {showSearch ? (
                <div className="col-4 d-flex align-items-center position-relative">
                  <input
                    type="text"
                    className="search-input-trash1"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search By Status Name"
                  />
                  <i
                    className="zmdi zmdi-close text-gray-500 cursor-pointer  right-3 top-1/2 transform -translate-y-1/2"
                    style={{ fontSize: '20px' }}
                    onClick={() => {
                      setSearchTerm("");
                      toggleSearchInput();
                    }}
                  />
                </div>
              ) : (
                <div className="flex items-center col-4 d-flex align-items-center">
                  <span className="ml-2 mt-2 mr-1 se12 m-0 p-0" style={{ fontSize: '15px', fontWeight: 'bold' }}>Search</span>
                  <span className="search56">
                    <i
                      className="zmdi zmdi-search text-gray-500 m-0 p-0 cursor-pointer"
                      style={{ fontSize: '23px' }}
                      onClick={toggleSearchInput}
                    />
                  </span>
                </div>
              )}
              <div className="col-8 text-end mb-3">
                <button onClick={() => { setAdd(!add); setEditMode(false); setFormData({ name: "", type: "" }); }} className="float-right addtemp">
                  {add ? "Cancel" : "+ Add New Status"}
                </button>
              </div>
            </div>
            {add && (
              <form onSubmit={handleSubmit}>
                <Row className="utility-management-row">
                  <Col md="4">
                    <TEXTFIELD
                      label="Status Name"
                      value={formData.name}
                      name="name"
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </Col>
                  <Col md="4">
                    <select
                      name="type"
                      className="form-control dropdpwn-status"
                      value={formData.type}
                      onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    >
                      <option value="">Select Role</option>
                      <option value="arbitration">Arbitration</option>
                      <option value="mediation">Mediation</option>
                    </select>
                  </Col>
                  <Col md="4" className="mt-2">
                    <SUBMIT title={editMode ? "Update" : "Save"} disabled={!formData.name || !formData.type} />
                  </Col>
                </Row>
              </form>
            )}
            <div className="row m-0 px-2 mt-3">
              <div className="col-2"><h6 className="m-0 highlightHead">#</h6></div>
              <div className="col-4"><h6 className="m-0 highlightHead">Name</h6></div>
              <div className="col-3"><h6 className="m-0 highlightHead">Role</h6></div>
              <div className="col-3"><h6 className="m-0 highlightHead">Action</h6></div>
            </div>
            <div className="underline-table"></div>
            <div>
              {loading ? (
                <div className="text-center p-8 mt-4">
                  <Loading />
                </div>
              ) : caseStatus.length === 0 ? (
                <div className="n--data text-center mt-4">No data Found!</div>
              ) : (
                caseStatus.map((status, index) => (
                  <div
                    className="listing-main w-100 p-2 mt-3 d-md-flex justify-content-between align-items-center"
                    key={status.id}
                  >
                    <div className="client-listing w-100">
                      <div className="row w-100 m-0">
                        <div className="main-title col-2">{limitCaseStatus * (currentPageCaseStatus - 1) + (index + 1)}</div>
                        <div className="main-title col-4">{status.name}</div>
                        <div className="main-title col-3">{status.type.charAt(0).toUpperCase() + status.type.slice(1)}</div>
                        <div className="right-unlist-1 d-flex align-items-center bg-transparent col-3">
                          <button className="make-aapbtn py-2" onClick={() => handleEdit(status)}>Edit</button>
                          <button className="make-aapbtn ml-2 py-2" onClick={() => handleDelete(status.id)}>Delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="pagination-container d-flex justify-content-end align-items-center mt-3">
              <span>
                {limitCaseStatus * (currentPageCaseStatus - 1) + 1}-
                {Math.min(limitCaseStatus * currentPageCaseStatus, totalRecordsCaseStatus)} of {totalRecordsCaseStatus}
              </span>
              <button className="btn btn-link ml-2" onClick={() => handlePageChangeCaseStatus("prev")} disabled={currentPageCaseStatus === 1}>
                &lt;
              </button>
              <button className="btn btn-link" onClick={() => handlePageChangeCaseStatus("next")} disabled={currentPageCaseStatus >= Math.ceil(totalRecordsCaseStatus / limitCaseStatus)}>
                &gt;
              </button>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div className="w-100 float-right row m-0">
              {showSearchStage ? (
                <div className="col-4 d-flex align-items-center position-relative">
                  <input
                    type="text"
                    className="search-input-trash1"
                    value={searchTermStage}
                    onChange={handleSearchChangeStage}
                    placeholder="Search By Stage Name"
                  />
                  <i
                    className="zmdi zmdi-close text-gray-500 cursor-pointer  right-3 top-1/2 transform -translate-y-1/2"
                    style={{ fontSize: '20px' }}
                    onClick={() => {
                      setsearchTermStage("");
                      toggleSearchInputStage();
                    }}
                  />
                </div>
              ) : (
                <div className="flex items-center col-4 d-flex align-items-center">
                  <span className="ml-2 mt-2 mr-1 se12 m-0 p-0" style={{ fontSize: '15px', fontWeight: 'bold' }}>Search</span>
                  <span className="search56">
                    <i
                      className="zmdi zmdi-search text-gray-500 m-0 p-0 cursor-pointer"
                      style={{ fontSize: '23px' }}
                      onClick={toggleSearchInputStage}
                    />
                  </span>
                </div>
              )}
              <div className="col-8 text-end mb-3">
                <button onClick={() => { setaddforStage(!addforStage); setEditMode(false); setFormData({ stage: "", for: "" }); }} className="float-right addtemp">
                  {addforStage ? "Cancel" : "+ Add New Stage"}
                </button>
              </div>
            </div>
            {addforStage && (
              <form onSubmit={handleSubmitStage}>
                <Row className="utility-management-row">
                  <Col md="4">
                    <TEXTFIELD
                      label="Stage Name"
                      value={formData.stage}
                      name="stage"
                      onChange={(e) => setFormData({ ...formData, stage: e.target.value })}
                    />
                  </Col>
                  <Col md="4">
                    <select
                      name="for"
                      className="form-control dropdpwn-status"
                      value={formData.for}
                      onChange={(e) => setFormData({ ...formData, for: e.target.value })}
                    >
                      <option value="">Select Role</option>
                      <option value="arbitration">Arbitration</option>
                      <option value="mediation">Mediation</option>
                    </select>
                  </Col>
                  <Col md="4" className="mt-2">
                    <SUBMIT title={editMode ? "Update" : "Save"} disabled={!formData.stage || !formData.for} />
                  </Col>
                </Row>
              </form>
            )}
            <div className="row m-0 px-2 mt-3">
              <div className="col-2"><h6 className="m-0 highlightHead">#</h6></div>
              <div className="col-4"><h6 className="m-0 highlightHead">Name</h6></div>
              <div className="col-3"><h6 className="m-0 highlightHead">Role</h6></div>
              <div className="col-3"><h6 className="m-0 highlightHead">Action</h6></div>
            </div>
            <div className="underline-table"></div>
            <div>
              {loading ? (
                <div className="text-center p-8 mt-4">
                  <Loading />
                </div>
              ) : documentStage.length === 0 ? (
                <div className="n--data text-center mt-4">No data Found!</div>
              ) : (
                documentStage.map((stage, index) => (
                  <div
                    className="listing-main w-100 p-2 mt-3 d-md-flex justify-content-between align-items-center"
                    key={stage.id}
                  >
                    <div className="client-listing w-100">
                      <div className="row w-100 m-0">
                        <div className="main-title col-2">{limitDocumentStage * (currentPageDocumentStage - 1) + (index + 1)}</div>
                        <div className="main-title col-4">{stage.stage}</div>
                        <div className="main-title col-3">{stage.for.charAt(0).toUpperCase() + stage.for.slice(1)}</div>
                        <div className="right-unlist-1 d-flex align-items-center bg-transparent col-3">
                          <button className="make-aapbtn py-2" onClick={() => handleEditStage(stage)}>Edit</button>
                          <button className="make-aapbtn ml-2 py-2" onClick={() => handleDeleteStage(stage.id)}>Delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="pagination-container d-flex justify-content-end align-items-center mt-3">
              <span>
                {limitDocumentStage * (currentPageDocumentStage - 1) + 1}-
                {Math.min(limitDocumentStage * currentPageDocumentStage, totalRecordsDocumentStage)} of {totalRecordsDocumentStage}
              </span>
              <button className="btn btn-link ml-2" onClick={() => handlePageChangeDocumentStage("prev")} disabled={currentPageDocumentStage === 1}>
                &lt;
              </button>
              <button className="btn btn-link" onClick={() => handlePageChangeDocumentStage("next")} disabled={currentPageDocumentStage >= Math.ceil(totalRecordsDocumentStage / limitDocumentStage)}>
                &gt;
              </button>
            </div>
          </TabPane>

          <TabPane tabId="3">
            <div className="w-100 float-right row m-0">
              {showSearchDocument ? (
                <div className="col-4 d-flex align-items-center position-relative">
                  <input
                    type="text"
                    className="search-input-trash1"
                    value={searchTermDocument}
                    onChange={handleSearchChangeDocument}
                    placeholder="Search By Doc.. Name"
                  />
                  <i
                    className="zmdi zmdi-close text-gray-500 cursor-pointer  right-3 top-1/2 transform -translate-y-1/2"
                    style={{ fontSize: '20px' }}
                    onClick={() => {
                      setsearchTermDocument("");
                      toggleSearchInputDocument();
                    }}
                  />
                </div>
              ) : (
                <div className="flex items-center col-4 d-flex align-items-center">
                  <span className="ml-2 mt-2 mr-1 se12 m-0 p-0" style={{ fontSize: '15px', fontWeight: 'bold' }}>Search</span>
                  <span className="search56">
                    <i
                      className="zmdi zmdi-search text-gray-500 m-0 p-0 cursor-pointer"
                      style={{ fontSize: '23px' }}
                      onClick={toggleSearchInputDocument}
                    />
                  </span>
                </div>
              )}
              <div className="col-8 text-end mb-3">
                <button onClick={() => { setaddforDocument(!addforDocument); setEditMode(false); setFormData({ name: "", type: "", document_type: "", for: "" }); }} className="float-right addtemp">
                  {addforDocument ? "Cancel" : "+ Add New Name"}
                </button>
              </div>
            </div>
            {addforDocument && (
              <form onSubmit={handleSubmitDocument}>
                <Row className="utility-management-row">
                  <Col md="4">
                    <TEXTFIELD
                      label="Document Name"
                      value={formData.name}
                      name="name"
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </Col>
                  <Col md="4">
                    <TEXTFIELD
                      label="Document Type"
                      value={formData.document_type}
                      name="name"
                      onChange={(e) => setFormData({ ...formData, document_type: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row className="utility-management-row">
                  <Col md="4">
                    <select
                      name="type"
                      className="form-control dropdpwn-status"
                      value={formData.type}
                      onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    >
                      <option value="">Select Type</option>
                      <option value="notice">Notice</option>
                      <option value="order">Order</option>
                      <option value="mom">MOM</option>
                    </select>
                  </Col>
                  <Col md="4">
                    <select
                      name="for"
                      className="form-control dropdpwn-status"
                      value={formData.for}
                      onChange={(e) => setFormData({ ...formData, for: e.target.value })}
                    >
                      <option value="">Select Role</option>
                      <option value="arbitration">Arbitration</option>
                      <option value="mediation">Mediation</option>
                    </select>
                  </Col>
                  <Col md="4" className="mt-2">
                    <SUBMIT title={editMode ? "Update" : "Save"} disabled={!formData.name || !formData.type || !formData.document_type || !formData.for} />
                  </Col>
                </Row>
              </form>
            )}
            <div className="row m-0 px-2 mt-3">
              <div className="col-1"><h6 className="m-0 highlightHead">#</h6></div>
              <div className="col-3"><h6 className="m-0 highlightHead">Name</h6></div>
              <div className="col-1"><h6 className="m-0 highlightHead">Type</h6></div>
              <div className="col-3"><h6 className="m-0 highlightHead">Document Type</h6></div>
              <div className="col-2"><h6 className="m-0 highlightHead">Role</h6></div>
              <div className="col-2"><h6 className="m-0 highlightHead">Action</h6></div>
            </div>
            <div className="underline-table"></div>
            <div>
              {loading ? (
                <div className="text-center p-8 mt-4">
                  <Loading />
                </div>
              ) : documentName.length === 0 ? (
                <div className="n--data text-center mt-4">No data Found!</div>
              ) : (
                documentName.map((doc, index) => (
                  <div
                    className="listing-main w-100 p-2 mt-3 d-md-flex justify-content-between align-items-center"
                    key={doc.id}
                  >
                    <div className="client-listing w-100">
                      <div className="row w-100 m-0">
                        <div className="main-title col-1">{limitDocumentName * (currentPageDocumentName - 1) + (index + 1)}</div>
                        <div className="main-title col-3">{doc.name}</div>
                        <div className="main-title col-1">{doc.type.toLowerCase() === "mom" ? doc.type.toUpperCase() : doc.type.charAt(0).toUpperCase() + doc.type.slice(1)}</div>
                        <div className="main-title col-3">{doc.document_type}</div>
                        <div className="main-title col-2">{doc.for.charAt(0).toUpperCase() + doc.for.slice(1)}</div>
                        <div className="right-unlist-1 d-flex align-items-center bg-transparent col-2">
                          <button className="make-aapbtn py-2" onClick={() => handleEditDocument(doc)}>Edit</button>
                          <button className="make-aapbtn ml-2 py-2" onClick={() => handleDeleteDocument(doc.id)}>Delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="pagination-container d-flex justify-content-end align-items-center mt-3">
              <span>
                {limitDocumentName * (currentPageDocumentName - 1) + 1}-
                {Math.min(limitDocumentName * currentPageDocumentName, totalRecordsDocumentName)} of {totalRecordsDocumentName}
              </span>
              <button className="btn btn-link ml-2" onClick={() => handlePageChangeDocumentName("prev")} disabled={currentPageDocumentName === 1}>
                &lt;
              </button>
              <button className="btn btn-link" onClick={() => handlePageChangeDocumentName("next")} disabled={currentPageDocumentName >= Math.ceil(totalRecordsDocumentName / limitDocumentName)}>
                &gt;
              </button>
            </div>
          </TabPane>

        </TabContent>
      </div>
    </div>
  );
};

export default Casetabs;