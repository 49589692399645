import React, { Fragment } from "react";
import {
  withRouter,
  Row, Col, toast,
  Axios, TEXTFIELD, ApiUrl,
  RESET, SUBMIT
} from "../../../../../../../components";

const { API_GET_RESPONDANT_BY_ID, API_UPDATE_RESPONDANT } = ApiUrl;

class EditRespondent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.match.params.id,
      name: "",
      email: "",
      phone: "",
      address: "",
      status: "Active",
      loading: true,
      disabled: true,
      originalData: {},
    };
  }

  componentDidMount() {
    Axios.get(`${API_GET_RESPONDANT_BY_ID}/${this.state.editId}`)
      .then((res) => {
        if (res.data.success) {
          const { name, email, phone, address } = res.data.data;
          this.setState({
            name,
            email,
            phone,
            address: address ? address.address : "N/A",
            loading: false,
            originalData: { name, email, phone, address },
          });
        } else {
          toast.error("Failed to fetch data");
        }
      })
      .catch((err) => {
        toast.error("Error fetching data");
        this.setState({ loading: false });
      });
  }

  handleChange = (event) => {
    this.setState(
      { [event.target.name]: event.target.value },
      () => this.checkFormChange()
    );
  };

  checkFormChange = () => {
    const { name, email, phone, address, originalData } = this.state;
    const isChanged =
      name !== originalData.name ||
      email !== originalData.email ||
      phone !== originalData.phone ||
      address !== originalData.address;

    this.setState({ disabled: !isChanged });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, email, phone, address, editId, originalData } = this.state;

    if (!name.trim()) {
      toast.error("Name cannot be empty or contain only spaces.");
      return;
    }
    if (!email.trim()) {
      toast.error("Email cannot be empty or contain only spaces.");
      return;
    }
    if (!phone.trim()) {
      toast.error("Phone cannot be empty or contain only spaces.");
      return;
    }
    if (!address.trim()) {
      toast.error("Address cannot be empty or contain only spaces.");
      return;
    }

    let updateData = {};

    if (name !== originalData.name && name !== "") updateData.name = name.trim();
    if (email !== originalData.email && email !== "") updateData.email = email.trim();
    if (phone !== originalData.phone && phone !== "") updateData.phone = phone.trim();
    if (address !== originalData.address && address !== "") updateData.address = address.trim();

    if (Object.keys(updateData).length === 0) {
      toast.info("No changes detected.");
      return;
    }

    Axios.post(`${API_UPDATE_RESPONDANT}/${editId}`, updateData)
      .then((res) => {
        if (res.response && res.response.status === 400) {
          toast.error(res.response.message);
        } else if (res.data.success) {
          toast.success("Respondent updated successfully!");
          this.props.history.goBack();
          this.fetchUpdatedDetails();
        }
      })
      .catch((err) => {
        toast.error("Error updating respondent");
        console.log(err);
      });
  };

  fetchUpdatedDetails = () => {
    Axios.get(`${API_GET_RESPONDANT_BY_ID}/${this.state.editId}`)
      .then((res) => {
        if (res.data.success) {
          const { name, email, phone, address } = res.data.data;
          this.setState({
            name,
            email,
            phone,
            address: address ? address.address : "N/A",
          });
        } else {
          toast.error("Failed to fetch updated data");
        }
      })
      .catch((err) => {
        toast.error("Error fetching updated data");
      });
  };

  reset = () => {
    const { originalData } = this.state;
    this.setState({
      name: "",
      email: "",
      phone: "",
      address: "",
      disabled: true,
    }, () => {
      this.fetchUpdatedDetails();
    });
  };
  
  render() {
    const { name, email, phone, address, loading, disabled } = this.state;

    return (
      <div className="bg-white p-5 mr-3">
        <Fragment>
          {loading && <div className="text-center w-100">Loading...</div>}

          <div className="mb-3">
            <button
              className="btn"
              onClick={() => this.props.history.goBack()}
            >
              <i className="zmdi zmdi-arrow-left" style={{ fontSize: '19px' }}></i>
            </button>
          </div>

          <form onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col md="4" xs="12">
                <TEXTFIELD
                  label="Name"
                  value={name}
                  name="name"
                  onChange={this.handleChange}
                />
              </Col>

              <Col md="4" xs="12">
                <TEXTFIELD
                  label="Email"
                  value={email}
                  name="email"
                  onChange={this.handleChange}
                />
              </Col>

              <Col md="4" xs="12">
                <TEXTFIELD
                  label="Mobile"
                  value={phone}
                  name="phone"
                  onChange={this.handleChange}
                />
              </Col>

              <Col md="12" xs="12">
                <TEXTFIELD
                  label="Address"
                  value={address}
                  name="address"
                  onChange={this.handleChange}
                />
              </Col>
            </Row>

            <Row>
              <Col md="2" xs="12">
                <SUBMIT title="Submit" disabled={disabled} />
              </Col>
              <Col md="2" xs="12">
                <RESET title="Reset" onClick={this.reset} />
              </Col>
            </Row>
          </form>
        </Fragment>
      </div>
    );
  }
}

export default withRouter(EditRespondent);