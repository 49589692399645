// Api BASE URLs
export const API_URL = "https://pre.justsettle.in/backend";
// export const API_URL = "http://justsettle_backend.test";
// export const API_URL = "https://stoic-nightingale.100-42-187-13.plesk.page/backend/public";
// export const API_URL = "https://justsettle.in/backend";


////***************TESTING URL********************/

export const API_VERSION = "/api/v1";

// COMMON CONSTANT
export const API_TOKEN = localStorage.getItem("user_id");
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
export const Numeric = /^[0-9]+$/;
export const Mobile_Pattern = new RegExp(/^[0-9\b]+$/);
export const ALPHABET = /^[A-Za-z]+$/;
export const ALPHANUMERIC_REGEX = /[^a-z\d]/i;
export const PASS_VALIDATION_MSG =
  "password should be at least 8 characters, one uppercase, lowercase, special character, numeric value.";

export const APP_NAME = "JustSettle";
export const PANEL_NAME = "Admin";
// AUTH URL
export const API_LOGIN = "/login";
export const REFRESH_TOKEN_API = "/refresh";
export const SEND_OTP = "/auth/send-otp";
export const VERIFY_OTP = "auth/get/verify-otp";
export const FRESH_CASE_REGISTER = "/register/party"


// Client  
export const ALL_CASES = "/bulk/client/get-all-cases"
export const ADD_NOTES = "/bulk/add/note/"
export const DELETE_NOTES = "/bulk/delete/note"
export const VIEW_NOTES = "/bulk/view/notes/"
export const CLIENT_SINGLE_CASE = "/bulk/case/show-all-details"
export const SELECTED_CASE_IDS = "/bulk/multiple-cases/add/note"
export const FILTER_CASE = "/bulk/lawfirm/filter/cases"
export const MEETING_DATA = "/bulk/all-meeting/client-wise"
export const FILTER_DATA = "/bulk/get/filter-data"
export const JOIN_MEETING = "bulk/track-meeting/auth-user-wise"
export const API_EDIT_SINGLE_NOTES = "bulk/update/note"
export const API_GET_NOTES_DETAILS = "bulk/edit/note/"


// Follow up
export const API_ADD_FOLLOW_UP = "/add/follow-up/"
export const API_VIEW_FOLLOW_UP = "/view/follow-up/"
export const API_EDIT_FOLLOW_UP = "/update/follow-up/"
export const API_DELETE_FOLLOW_UP = "/delete/follow-up"
export const API_GET_FOLLOW_UP_DETAILS = "/edit/follow-up/"
export const API_SEND_REPLY = "/add/follow-up-reply/"


//collectors
export const ALL_COLLECTION_CASES = "/bulk/collectors/get-all-cases"
export const API_GET_ALL_TOTAL_COLLECTION_CASES = "/bulk/get/case-count"
export const API_UPDATE_PASSWORD = "update/password"
export const API_GET_PROFILE_DETAILS = "edit/profile"
export const API_UPDATE_PROFILE = "update/profile"

//Already exist
export const VERIFY_CASE = "/sc-case/verification"
export const EXISTING_USERS = "/sc-case/send-otp/existing-users"
export const VERIFY_OTP_USER = "/sc-case/verify-otp/existing-users"
export const SIGNUP_USER_EXISTING = "/sc-case/existing-user/signup"
export const SIGNUP_Other_CLAIMANT = "/sc-case/existing-other/other"

export const API_SEND_OTP = "/auth/send-otp/"
export const API_VERIFY_OTP = "/auth/get/verify-otp"

export const API_GET_OTP = "/auth/verify-user"
export const API_FORGET_PASSWORD = "/auth/change-password"

// DASHBOARD
export const DASHBOARD = "";

// File Upload
export const FILE_UPLOAD = "";


// Category
export const API_GET_CATEGORY_URL = "";
export const API_GET_CATEGORY = "";
export const API_ADD_CATEGORY = "";
export const API_EDIT_CATEGORY = "";
export const API_DELETE_CATEGORY = "";

// Notes
export const API_EDIT_NOTES = "";
export const API_ADD_NOTES = "";
export const API_GET_NOTES_BY_ID = "";
export const API_CHECK_NOTIFICATION = "/bulk/notification/read"
export const API_DOWNLOAD_INDIAPOST = "convert-shipment-to-pdf/"

// Excel
export const API_ADD_EXCEL = "/cases/upload/";

// Menu Item
export const API_GET_MENU_URL = "";
export const API_GET_MENU_BY_ID = "";
export const API_ADD_MENU = "";
export const API_EDIT_MENU = "";
export const API_DELETE_MENU = "";


//Addons Category

export const API_GET_ADDONS_CATEGORY = "";

// Customers URLs
export const API_GET_CUSTOMERS_URL = "";
export const API_GET_CUSTOMER = "";
export const API_ADD_CUSTOMER = "";
export const API_EDIT_CUSTOMER = "";
export const API_DELETE_CUSTOMER = "";
export const API_UPLOAD_CUSTOMER = "/user/importCsvList/";

// SUPER ADMIN
export const API_ADD_SUPER_USER = "/users/new-user"
export const API_SUPER_USER_LIST = "/users/list"
export const API_GET_USER_DETAIL = "user/show/"
export const API_EDIT_USER = "users/edit/"
export const API_PENDING_ARBITRATOR = "/users/list/Arbitrator"
export const API_PENDING_ADVOCATE = "/users/list/Advocate"
export const API_PENDING_CLAIMANT = "/users/list/Client"
export const API_PENDING_RESPONDENT = "/users/list/Party"
export const API_GET_SUPER_USER = ""
export const API_GET_SUP_CLIENT_LIST = "/get/lawfirm/clients"
export const API_GET_CLIENT_LOT = "/get/clients/lots"
export const API_ASSIGN_CASE_MANAGER = "/assign/lot/cases"
export const API_GET_CLIENT_LOT_DATA = "/get/clients/lot/details"
export const API_ASSIGN_MANAGER = "/assign/manager/casemanager"
export const API_GET_CASES_LOT = "get/assigned/lots/"
export const API_ASSIGN_NEW_CASE_MANAGER = "re-assign/lot/cases"
export const API_ASSIGN_MANAGER_CASES = "assign/case-manager"
export const API_GET_CASE_MANAGER_ROLE_LIST = "casemanagers/list"
export const API_RE_ASSIGN_MANAGER_CASES = "/cases/re-assign"
export const API_GET_REGISTRED_ARBITRATOR = "/users/role/list/Arbitrator"
export const API_GET_REGISTRED_ADVOCATE = "/users/role/list/Advocate"
export const APPROVE_DISAPPROVE_REGISTERD = "/roles/status-update"
export const GET_TOTAL_COUNT  = "/users/role/count"


// dharmani
export const API_SET_APP_NAME_LOGO = "/app-settings";
export const API_GET_ADMIN_ALL_CASE = "/show-all-admin-cases/list";
export const DELETE_BULK_DOCK = "/delete-bulk-doc";
export const RESTORE_BULK_DOCK = "/delete-bulk-doc/restore";
export const SHOW_DELETE_DOCUMENTS = "/show-deleted-documents/list";
export const UPLOAD_USERS_BY_EXCEL = "/add-user/excel";
export const DELETE_RESPONDENT_BY_ADMIN = "/delete-case";
export const API_GET_RESPONDANT_BY_ID = "/respondant/user";
export const API_UPDATE_RESPONDANT = "/respondant/edit";
export const API_GET_VENUE_DETAILS = "/admin-case/venue-details";
export const UPDATE_VENUE_DETAILS = "/update-case-meta";
export const GET_ALL_CASES_ADMIN = "/get-all-cases-admin"
export const DELETED_CASES_BULK_SPECIFIC = "/show-deleted-cases/list";
export const RESTORE_DELETED_CASES = "/restore-deleted-cases";
export const API_GET_LOTS_APPROVED = "/bulk/not-in-lot/show-all";
export const API_DELETE_LOT_CASES = "/bulk/delete-cases-in-lot";
export const API_GET_LOTS_TRASH_CASES = "/bulk/show-deleted-lots/list";
export const API_RESTORED_LOT_CASES = "/bulk/restore-cases-in-lot";
export const GET_BULK_HEARINGS_RECORDINGS = "/list/recordings";
export const DELETE_USER_BY_EXCEL = "/delete-case-excel";
export const DELETE_DOCUMENTS_BULK_SA = "/delete-bulk-case-doc";
export const API_GET_USERS_ADMIN_APPROVALS = "/users/arbitrator-advocate/"
export const API_CLIENT_FILTER_DELETED_SEARCH = "/filter/deleted/"
export const API_SEARCH_ZOOM_TOPIC = "/hearing-topic"
export const API_DELETE_ZOOM_TOPIC = "/delete-bulk-case-hearing";
export const API_DOWNLOAD_MIS = "/export/mis/cases";
export const API_ASSIGN_SECATRIATE = "/assigned-secretrait";
export const GET_BULK_NOTIFICATIONS = "/bulk/get/all/notifications";
export const BULK_NOTIFICATIONS_DELETE = "/bulk/delete/notifications";
export const API_CHECK_EMAIL = "/auth/check-email"

//ADMIN - CRUD
export const API_CREATE_STATUS = "/create/case/status";
export const API_UPDATE_STATUS = "/update/case/status";
export const API_DELETE_STATUS = "/delete/case/status";
export const API_CREATE_STAGE = "/create/case/stage";
export const API_UPDATE_STAGE_ADMIN = "/update/case/stage";
export const API_DELETE_STAGE = "/delete/case/stage";
export const API_CREATE_DOCUMENT = "/create/case/document";
export const API_UPDATE_DOCUMENT = "/update/case/document";
export const API_DELETE_DOCUMENT = "/delete/case/document";


// ARBITRATOR
export const API_GET_ARBITRATOR_CASES = "/bulk/get-all-cases-role-wise/";
export const API_ADD_HEARING_DATE = "/hearing/create";
export const API_UPLOAD_ARBITRATOR_ORDER = "/bulk/generate-notices/arbitrator"
export const API_UPLOAD_DOCUMENT = "/bulk/case-related-file/upload";

//Register
export const API_PARTY_REGISTER = "/register/party";
export const API_MEDIATOR_REGISTER = "/register/mediator";
export const API_ADVOCATE_REGISTER = "/register/advocate";
export const API_ARBITRATOR_REGISTER = "/register/arbitrator";

//Header Sets
export const API_GET_HEADER_SETS = "/bulk/get-headers/"
export const GET_MAPPING_FIELDS = "/bulk/excel-upload/get-excel-fields/"
export const API_ADD_CASE_FILE = "/bulk/cases/upload/"
export const API_GET_BORROWER_TYPE = "/case/borrower-type"

//Client
export const API_ADD_CLIENT = "/bulk/case/show-all-details/";
export const API_ADD_CLIENT_URL = "/bulk/add/client";

export const API_GET_USER_CASES_URL = "/bulk/case/show-all-details/";
export const API_GET_USERS_CASE = "/bulk/show-all/cases/";
export const API_GET_TIMELINE = "/bulk/show/case-timeline/";

export const API_SEARCH_CASE_LIST = "bulk/certified-copy"
export const API_SEARCH_GENERATE_DOCUMENT = "bulk/generate/certified-copy"

export const API_SINGLE_LOTS_CASE = "/bulk/show-all/lotcases/";
export const API_ALLOW_LOT_CASES = "/bulk/approve/lot/";
export const API_DIS_ALLOW_LOT_CASES = "/bulk/disapproved/lot/";

export const API_GET_LOTS_LAWFIRM = "/bulk/lot/show-all"

export const API_GET_USER_TEMPLATES_URL = "/bulk/show/templates/";
export const API_GET_USER_STAGES_URL = "/case/stages";
export const API_GET_SINGLE_TEMPLATE_URL = "/get-file/link/";
export const API_GET_SINGLE_CASE = "/bulk/case/show-all-details/";
export const API_DELETE_CASES = "";
export const API_GET_USERS_URL = "/bulk/show-all/client"
export const API_DIS_APPROVE_NOTICES = "/bulk/disapproved/notices"

export const API_GET_LOTS = "/bulk/get/approval-notices/lot"

export const API_GET_SETTING_DOC = "ger/status/stamp-sign"
export const API_GET_CASE_STATUS = "case-status/list"
export const API_UPDATE_CASE_STATUS = "case-status/update"
export const API_UPDATE_CASE_STAGE = "present-stage/update"

export const API_GET_DOCUMENTS_URL = "document/classification/"
export const API_SUBMIT_EXCEL = "filter/excel-cases"


export const API_GENERATE_CASES = "/bulk/generate-notices"

export const API_GET_CLIENTS = "/bulk/get-all-client/notices"   //dharmani

export const API_GET_NOTICES = "/bulk/all-not-approved/notices"
export const API_SEND_NOTICES = "/bulk/approve-n-send/notices"
export const API_GET_LOTS_BY_ID = "/bulk/get/approval-lot/cases/"

export const API_GET_USERS_APPROVALS = "/all/not-approved-users/role-wise/"
export const API_APPROVE_USER_LAWFIRM = "/user/get-approved"
export const API_DIS_APPROVE_USER_LAWFIRM = "/user/dis-approved"
export const API_GET_NOTES_LIST = "/bulk/view/notes/"
export const API_GET_SHIPMENT_STATUS = "/shipment/"

export const API_UPLOAD_GENERAL_SETTING = "upload/stamp-sign"
export const API_GET_GENERAL_SETTING = "get/stamp-sign"
export const API_DELETE_GENERAL_SETTING = "delete/stamp-sign/"

export const API_GET_ARBIT_LIST = "/bulk/get/filter-data/"
export const API_ASSIGN_ARBIT = "/Arbitrator/assign"

export const API_ASSIGN_MEDIATOR = "/assign/manager"
export const API_GET_MANAGER_LIST = "/get/manager/clients"

export const API_GET_SINGLE_CLIENT = ""
export const API_DELETE_TEMPLATE = "/bulk/templates/delete/";

export const API_GET_ALL_CASES_URL = "/bulk/get-all-cases"
export const API_FILTER_CASE = "/bulk/lawfirm/filter/cases"
export const API_ARBITRATOR_FILTER_CASE = "/bulk/arbitrator/filter/cases"
export const API_CASE_MANAGER_FILTER_CASES = "filter/casemanager/cases"
export const API_FILTER_TRACKER_CASE = "/bulk/case/message-status-wise"
export const API_FILTER_METRIC = "/bulk/lawfirm/filter/cases"
export const API_FILTER_SELECT_DATA = "/bulk/get/filter-data/"

export const API_ADD_CLIENT_NOTES = "/bulk/add/note/"
export const API_FILTER_SEARCH = "/filter/case/get"
export const API_CLIENT_FILTER_SEARCH = "filter/client/"
export const API_FILTER_SEARCH_COLLECTION = "filter/case/role-wise"
export const API_GET_FOLLOW_UP_CODES = "get/list/followup-codes"

export const API_AUDIT_LOGS = "/bulk/generate/case/audit-trail";

export const API_UPADTE_DOCUMENT_DATE = "/update/document-date/"
export const API_UPADTE_DOCUMENT_NAME = "case/update/alt/"
export const API_GET_DOC_NAME = "case/edit/alt/"

// manager
export const API_REPLY_CHAT = "get/follow-up/replies/"
export const API_CHAT = "/add/follow-up-reply"
export const API_MANAGER_FOLLOW = "manager/view/followup/"
export const API_NOTIFICATION = "/manager/follow-up/notification"
export const API_UPDATE_STAGE = "update/follow-up-stage"
export const API_CASE_STAGES = "get/list/followup-codes/stage"
 
// lawfirm mediation
export const API_GET_ALL_CASES_URL_MEDIATION_LAW = "/bulk/get-all-cases/mediation"
export const API_GET_ALL_UNASSIGNED_CASES_URL_MEDIATION_LAW = "get/assigned/cases"
export const API_GET_ALL_UNASSIGNED_CASES_MEDIATION_LAW = "get/unassigned/cases"
export const API_GET_LIST_ASSIGNED_CASES = "get/list/assigned/cases"

export const API_GET_MESSAGES = "bulk/get/messages"
export const API_SEND_MESSAGE = "bulk/send/message"
export const API_GET_ADMIN_MESSAGES = "bulk/get/latest/messages"
export const API_GET_ADMIN_CHAT = "bulk/get/admin/messages/"

export const API_GET_NOTIFICATION_COUNT = "bulk/get/admin/messages/"
export const API_MEDIATORS_FILTER_CASE = "/bulk/arbitrator/filter/cases/mediation"
export const API_GET_MED_NOTIFICATION_COUNT = "/bulk/all-not-approved/notices/relation"
//case manage 
export const API_GET_ALL_CASE_MANAGER_CASES = "get/list/assigned/cases"
export const API_GET_ALL_MANAGER_CASES = "get/manager/cases"
export const API_GET_CASE_MANAGER_CLIENTS = "get/casemanager/clients"
export const API_GET_CASE_MANAGER_CASES = "get/casemanager/clients/cases/";
export const API_GET_ALL_CASE_MANAGER_CHART = "filter/casemanager/follow-up/notes";
export const API_CASE_COUNT = "/mediation/case/counts";


/* mediation firm*/

export const API_MEDIATION_FIRM_APPROVE = "/bulk/approve/change-request";
export const API_MEDIATION_FIRM_DIS_APPROVE = "/bulk/dis-approve/change-request";




//Admin Setting URLs
export const API_GET_ALL_ROLES = "/roles/get";
export const API_EDIT_ADMIN = "";
export const API_ADD_ROLE = "/roles/add";
export const API_ADD_PERMISSION = "/permissions/add";
export const API_GET_ADMIN = "";
export const API_GET_ALL_SUPER_PERMISSIONS = "/permissions";
export const API_GET_ALL_STATES = "bulk/get/client-states/"
export const API_GET_COUNTRIES = "get/country"
export const API_GET_STATES = "get/state/"
export const API_GET_CITIES = "get/city/"

// Content pages
export const API_GET_CONTENT_PAGES_URL = "";
export const API_EDIT_CONTENT_PAGE = "";
export const API_GET_CONTENT_PAGE_BY_ID = "";

// Template
export const API_ADD_TEMPLATE = "/bulk/upload/template/";
export const API_GET_TEMPLATE = "/bulk/show/single-template/";
export const API_EDIT_TEMPLATE = "/bulk/templates/update/";

export const API_UPLOAD_FOLDER = "/bulk/notices/upload"
export const API_UPLOAD_SHIPMENTS = "/importShipments"

export const API_GET_ALL_FAQ = "";
export const API_DELETE_FAQ = "";

export const API_UPLOAD_TESTING = "/bulk/cases/update"

///// Contact Query
export const API_GET_ALL_CONTACT_QUERY = "";

// Push Notification URLs
export const SEND_PUSH_NOTIFICATION = "";
export const SEND_PUSH_NOTIFICATION_BY_MAIL = "";

// Settings
export const API_UPDATE_ADMIN_PASSWORD = "";
export const API_GET_BASIC_SETTINGS = "";
export const API_UPDATE_BASIC_SETTINGS = "";
export const API_UPDATE_NOTIFICATION_SETTINGS = "";
export const API_UPDATE_SOCIAL_SETTINGS = "";
export const API_INSTALLATION_SETTINGS = "";

/*Arbitration */

export const Case_Category = "/sc-category/get/case_category";
export const Dispute_Value = "/sc-category/get/dispute_value";
export const FILED_CASE = "/cases/arbitration/filed/cases";
export const CASE_ADD = "/party/arbitration/filed/case/add";
export const UPDATE_CASE = "/party/arbitration/filed/cases/edit";
export const Preview_CASE = "/party/arbitration/filed/cases/preview";
export const RESPONDANT_CASES = "/party/arbitration/filed/cases/as/respondent";
export const ADVOCATE_RESPONDANT_CASES = "/party/arbitration/filed/cases/as/advocate";

export const MEETING_API = "/bulk/all-meeting/respondent-wise";
export const UPLOADING_FILES = "/party/arbitration/filed/cases/document-upload";

export const PARTY_TIMELINE_API = "/bulk/show/case-timeline/";


export const PERMISSIONS_ARRAY = [
  "dashboard",
  "cases",
  "clients",
  "superadmin",
  "arbitrator",
  "advocate",
  "party",
  "client",
];

export const PERMISSIONS = PERMISSIONS_ARRAY.map((p) => {
  const name = p.replace("_", " ");
  return {
    [p]: name.charAt(0).toUpperCase() + name.slice(1),
  };
});