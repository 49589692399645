import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from 'react-router-dom';
import Checkbox from "./checkbox";

import {
  Link,
  ApiUrl,
  Axios,
  DialogBox,
  Row,
  Col,
  SELECT,
  toast,
  Loading,
  moment,
  TEXTFIELD
} from "../../../../../components";

import filter from "../../../../../assets/images/icone-de-filtre-grise.png";

const {
  API_SINGLE_LOTS_CASE,
  API_ALLOW_LOT_CASES,
  API_DIS_ALLOW_LOT_CASES,
  API_DELETE_CASES,
  API_FILTER_CASE,
  API_FILTER_SELECT_DATA
} = ApiUrl;

const Revenuetable = (props) => {
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    temp: "",
    disabled: false,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [showVenue, setVenue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [temp, setTemp] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [arbitratorData, setArbitratorData] = useState([]);
  const [seatData, setSeatData] = useState([]);
  const [lotData, setLotData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [count, setCount] = useState();

  const [formData, setFormData] = useState({
    status: "",
    created_at: "",
    created_at_from: "",
    created_at_to: "",
    hearing_date_from: "",
    arbitrator: "",
    hearing_date: "",
    not_have_hearing_date: "",
    state: "",
    seat: "",
    venue: "",
    client: "",
    lot: "",
  });
  const [id, setId] = useState("");

  const [detail, setDetail] = useState({
    title: "",
    des: "",
  });

  // console.log(isCheck, "isCheckAll");

  // useEffect(() => {
  //   if (props.props.location.name) {
  //     localStorage.setItem("Pname", props.props.location.name);
  //   }
  //   if (props.props.location.description) {
  //     localStorage.setItem("description", props.props.location.description);
  //   }
  //   setDetail({
  //     title: localStorage.getItem("Pname"),
  //     des: localStorage.getItem("description"),
  //   });
  // }, [props.props.location.name, props.props.location.description]);

  // console.log(isCheck, "isCheckAll");

  const handleGoBack = () => {
    localStorage.removeItem("Pname");
    localStorage.removeItem("description");
    props.history.goBack();
  }

  const handleCancel = () => {
    setShow(false);
  };

  const handlePreview = (id) => {
    setShow(true);
  };

  useEffect(() => {
    getTableRecords();
    filterData("state");
    filterData("venue");
    filterData("stages");
    filterData("arbitrator");
    filterData("seat");
    filterData("lot");
    filterData("lawFirmClient");
  }, []);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
    // setId(id)
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (e, id) => {
    const isChecked = isCheck.includes(id);
    if (isChecked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    } else {
      setIsCheck([...isCheck, id]);
    }
  };

  const filterData = async (type) => {
    try {
      const response = await Axios.get(API_FILTER_SELECT_DATA + `${type}`);
      if (type === "state") {
        var data = response.data.data;
        const statedata =
          data.length > 0 &&
          data.map((h, id) => {
            return { [h.state]: h.state };
          });
        setStateData(statedata);
      } else if (type === "venue") {
        var data = response.data.data;
        const venuedata =
          data.length > 0 &&
          data.map((h) => {
            return { [h.venue]: h.venue };
          });
        setVenueData(venuedata);
      }
      //  else if (type === "stages") {
      //   setStagesData(response.data.data.map((item) => item.stage));
      //   value;
      // }
      else if (type === "arbitrator") {
        var data = response.data.data;
        const arbitdata =
          data.length > 0 &&
          data.map((h, id) => {
            return { [h.name]: h.name };
          });
        setArbitratorData(arbitdata);
      } else if (type === "lot") {
        var data = response.data.data;
        const lotdata =
          data.length > 0 &&
          data.map((h) => {
            return { [h.title]: h.title };
          });
        setLotData(lotdata);
      } else if (type === "seat") {
        var data = response.data.data;
        const seatdata =
          data.length > 0 &&
          data.map((h) => {
            return { [h.seat]: h.seat };
          });
        setSeatData(seatdata);
      } else if (type === "stages") {
        var data = response.data.data;

        setStagingData(response.data.data);
      } else if (type === "lawFirmClient") {
        var data = response.data.data;
        const clientdata =
          data.length > 0 &&
          data.map((h, id) => {
            return { [h.id]: h.name };
          });
        setClientData(clientdata);
      }
    } catch (error) {
      console.error("Error filtering data");
    }
  };

  const getTableRecords = async () => {
    setLoading(true);
    const hash = window.location.hash;
    const extractedValue = hash.split('/').pop();
    const apiUrl = `${API_SINGLE_LOTS_CASE}${extractedValue}`;
    Axios.Get(apiUrl)
      .then((response) => {
        const resData = response.data;
        if (response.status == 200) {
          // toast.success(resData.message);
          setList(resData.data);
          setLoading(false);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };

  const handleChangeSeat = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      seat: value,
    }));
  };

  const refresh = () => {
    getTableRecords();
    setCount();
    setResponseData([]);
  };

  const redirectPage = () => {
    props.props.history.goBack();
  };
  const allowCases = async () => {
    Axios.Post(API_ALLOW_LOT_CASES + props.edit)
      .then((res) => {
        console.log(res, "reskjkj");
        let response = res.data;
        setLoading(false);

        if (response.status === 200) {
          toast.success(response.message);
          setTimeout(() => {
            redirectPage();
          }, 1000);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };
  const disallowCases = async () => {
    Axios.Post(API_DIS_ALLOW_LOT_CASES + props.edit)
      .then((res) => {
        console.log(res, "reskjkj");
        let response = res.data;
        setLoading(false);

        if (response.status === 200) {
          toast.success(response.message);
          setTimeout(() => {
            redirectPage();
          }, 1000);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const deleteAction = (id) => {
    Axios.post(API_DELETE_CASES + id)
      .then((res) => {
        setOpen(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        setOpen(false);
        toast.success("Cases does not deleted!");
      });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;

    const formattedDate = value
      ? new Date(value).toISOString().split("T")[0]
      : "";
    setFormData({ ...formData, [name]: formattedDate });
  };
  const handleHearingDateChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      hearing_date: value,
    }));
  };
  const handlenotassignChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      not_have_hearing_date: value,
    }));
  };
  const handleChangeState = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      state: value,
    }));
  };
  const handleChangeLot = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      lot: value,
    }));
  };
  const handleChangeClient = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      client: value,
    }));
  };
  const handleChangeDateRange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeHearingDateRange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleConfirmSelection = () => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => value !== "")
    );
    Axios.post(API_FILTER_CASE, filteredFormData)
      .then((response) => {
        console.log(response, "ree");
        setResponseData(response.data);
        setCount(response.data.data.length);
        setShowModal(false);
        setFormData({});
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
      });
  };
  const handleOpenModal1 = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const filteredCatalogue =
    responseData.data &&
    responseData.data.length > 0 &&
    responseData.data.map((item, index) => {
      console.log(item, "itemchecking");
      return (
        <div className="single-revenue-conatiner" key={item.id}>
          <div className="revenue-table-heading">
            <p className="checkbox-revenue">
              {" "}
              {/* <span>
              <Checkbox
                key={item.id}
                type="checkbox"
                name={item.label}
                id={item.id}
                handleClick={handleClick}
                isChecked={isCheck.includes(item.id)}
              />
            </span> */}
              <Link
                to={`/app/client/case-detail/${item.hash_id}`}
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Case No."
                className="d-flex"
              >
                {item.case_master_users.map((i) => {
                  return (
                    <div className="ml-2 text-capitalize">
                      <span>
                        {i.related_user_role == "Client" &&
                          i.related_user_type == "claimant"
                          ? `${i?.related_user?.name}   vs`
                          : ""}
                      </span>

                      <span>
                        {i.related_user_role == "Party" &&
                          i.related_user_type == "respondent"
                          ? i?.related_user?.name
                          : ""}
                      </span>
                    </div>
                  );
                })}
              </Link>
            </p>
            <p className="checkbox-revenue-subtext">
              <span> {item.date} </span>
              <span className="revenue-status">{item.case_status}</span>
            </p>
          </div>
          <Link to={`/app/client/case-detail/${item.hash_id}`}>
            <div className="filesrevenue-conatiner">
              <div className="filesrevenue-subconatiner">
                <div className="pov123 mt-3">
                  <p>
                    <span className="pov123Text">Case No.</span>
                    {item.case_number}
                  </p>
                  <p>
                    <span className="pov123Text">Filed On:</span>
                    {moment(item.created_at).format("LLL")}
                  </p>
                </div>
              </div>

              <div className="filesrevenue-subconatinerpov">
                {item.hearing &&
                  item.hearing.length > 0 &&
                  item.hearing.map((hearing) => {
                    const startDate = new Date(hearing.hearing.start_date);
                    const currentDate = new Date();

                    if (startDate >= currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing.hearing_id}
                        >
                          <p>Next</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    } else if (startDate < currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing.hearing_id}
                        >
                          <p>Previous</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    }
                  })}

                {!item.hearing ||
                  (item.hearing.every(
                    (hearing) =>
                      new Date(hearing.hearing.start_date) >= new Date()
                  ) && <></>)}
              </div>
            </div>
          </Link>

          {showVenue === index ? (
            <div onClick={() => toggleRev1(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev1(index)} className="text-center">
              <span className="arrow-conainer">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
          {showVenue === index &&
            item.metas.map((m) => {
              return (
                <>
                  <div className="revenue-state">
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {" "}
                        {m.meta_value}{" "}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}

          {/* {show === index ? (
          <div onClick={() => toggleRev(index)}>
            <h2 class="hr-lines">
              <span>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </h2>
          </div>
        ) : (
          <div onClick={() => toggleRev(index)} className="text-center">
            <span className="arrow-conainer">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                  stroke="#42526E"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        )}
        {show === index && (
          <div className="revenue-state2">
            <div className="revenue-state-box">
              <p className="text-court"> Usergroup: </p>
              <p className="text-state"> No user group added yet </p>
            </div>
            <div className="revenue-state-box">
              <p className="text-court">Team:</p>
              <p className="text-state"> No user added yet </p>
            </div>
          </div>
        )} */}
        </div>
      );
    });

  const cataloge =
    list &&
    list?.length > 0 &&
    list.slice(0, limit).map((item, index) => {
      return (
        <div className="single-revenue-conatiner" key={item.id}>
          <div className="revenue-table-heading">
            <p className="checkbox-revenue">
              {" "}
              {/* <span>
                <Checkbox
                  key={item.id}
                  type="checkbox"
                  name={item.label}
                  id={item.id}
                  handleClick={(e) => handleClick(e, item.id)}
                  isChecked={isCheck.includes(item.id)}
                />
              </span> */}
              <Link
                to={`/app/client/case-detail/${item.hash_id}`}
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Case No."
                className="d-flex"
              >
                {item.case_master_users.map((i) => {
                  return (
                    <div className="ml-2 text-capitalize">
                      <span>
                        {i.related_user_role == "Client" &&
                          i.related_user_type == "claimant"
                          ? `${i.related_user?.name}   vs`
                          : ""}
                      </span>

                      <span>
                        {i.related_user_role == "Party" &&
                          i.related_user_type == "respondent"
                          ? i.related_user?.name
                          : ""}
                      </span>
                    </div>
                  );
                })}
              </Link>
            </p>
            <p className="checkbox-revenue-subtext">
              <span> {item.date} </span>
              <span className="revenue-status">{item.case_status}</span>
            </p>
          </div>
          <Link to={`/app/client/case-detail/${item.hash_id}`}>
            <div className="filesrevenue-conatiner">
              <div className="filesrevenue-subconatiner">
                <div className="pov123 mt-3">
                  <p>
                    <span className="pov123Text">Case No.</span>
                    {item.case_number}
                  </p>
                  <p>
                    <span className="pov123Text">Filed On:</span>
                    {moment(item.created_at).format("LLL")}
                  </p>
                </div>
              </div>

              <div className="filesrevenue-subconatinerpov">
                {item.hearing &&
                  item.hearing?.length > 0 &&
                  item.hearing.map((hearing) => {
                    const startDate = new Date(hearing?.hearing?.start_date);
                    const currentDate = new Date();

                    if (startDate >= currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing.hearing_id}
                        >
                          <p>Next</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    } else if (startDate < currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing?.hearing_id}
                        >
                          <p>Previous</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    }
                  })}

                {!item.hearing ||
                  (item?.hearing?.every(
                    (hearing) =>
                      new Date(hearing?.hearing?.start_date) >= new Date()
                  ) && <></>)}
              </div>
            </div>
          </Link>

          {showVenue === index ? (
            <div onClick={() => toggleRev1(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev1(index)} className="text-center">
              <span className="arrow-conainer">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
          {showVenue === index &&
            item.metas.map((m) => {
              return (
                <>
                  <div className="revenue-state">
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {" "}
                        {m.meta_value}{" "}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      );
    });
  console.log(state.disabled, "state.disabled");
  return (
    <section className="Sales_analytics">
      <DialogBox
        title={"Cases"}
        open={open}
        onClose={handleClose}
        deleteAction={deleteAction}
      />
      <div fluid>
        <Row>
          <Col md="8" xs="12" className="d-flex">
            <Link
              to="#"
              onClick={handleGoBack}
              style={{
                color: "grey",
                fontSize: "32px",
              }}
            >
              <i
                class="zmdi zmdi-arrow-left"
                style={{
                  fontSize: "22px",
                  marginTop: "25px",
                  marginLeft: "9px",
                }}
              ></i>
            </Link>
            <div className="d-flex">
              <p className="m-4">{localStorage.getItem("Pname")}</p>
              <p className="m-4" style={{ whiteSpace: " pre-wrap" }}>
                {localStorage.getItem("description")}
              </p>
            </div>
          </Col>
          
          {/* <Col md="4" xs="12">
            <Tooltip title="Need to select Template and checkboxe" arrow>
              <button className=" mt-4 mr-5 allow-dis-btn" onClick={allowCases}>
                Approve
              </button>
            </Tooltip>

            <Tooltip title="Need to select Template and checkboxe" arrow>
              <button
                className=" mt-4 mr-5 dis-allow-btn"
                onClick={disallowCases}
              >
                Dis Approve
              </button>
            </Tooltip>
          </Col> */}

        </Row>
        <div className="revenueTable-conatiner">
          <div className="revenueTable-pagination-box">
            <div className="pgination-1st">
              {" "}
              {/* <span className="check-po-icon">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </div>
              </span> */}
              <span className="check-po-icon" onClick={refresh}>
                <i class="zmdi zmdi-refresh-alt"></i>
              </span>
              {/* <span className="check-po-icon" onClick={handleClickOpen}>
                <i class="zmdi zmdi-delete"></i>
              </span> */}
              <span className="check-po-icon" onClick={handleOpenModal1}>
                <span className="mr-2 mb-1">Filter</span>

                <img
                  src={filter}
                  alt=""
                  style={{ width: " 20px", height: " 18px" }}
                />
                {/* <i class="zmdi zmdi-filter-list"></i> */}
              </span>
              <Modal isOpen={showModal} toggle={handleCloseModal}>
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseModal}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <ModalBody>
                  <Row>
                    <Col md="4" xs="12">
                      <SELECT
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        label="Status"
                        data={[
                          { pending: "Pending" },
                          { disposed: "disposed" },
                          { registered: "Registered" },
                        ]}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <TEXTFIELD
                        type="date"
                        label="Created At"
                        onChange={handleDateChange}
                        value={formData.created_at}
                        name="created_at"
                      />
                    </Col>

                    <Col md="4">
                      <SELECT
                        onChange={handleChange}
                        value={formData.arbitrator}
                        name="arbitrator"
                        label="Arbitrator"
                        data={arbitratorData}
                      />
                    </Col>

                    <Col md="4">
                      <TEXTFIELD
                        type="date"
                        label="Hearing Date"
                        onChange={handleHearingDateChange}
                        value={formData.hearing_date}
                        name="hearing_date"
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handlenotassignChange}
                        value={formData.not_have_hearing_date}
                        name="not_have_hearing_date"
                        label="Hearing not assigned"
                        data={[{ yes: "Yes" }]}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChangeState}
                        value={formData.state}
                        name="state"
                        label="State"
                        data={stateData}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChangeSeat}
                        value={formData.seat}
                        name="seat"
                        label="Seat"
                        data={seatData}
                      />
                    </Col>
                    <Col md="4">
                      <SELECT
                        onChange={handleChange}
                        value={formData.venue}
                        name="venue"
                        id="variation-select-6"
                        label="Venue"
                        data={venueData}
                      />
                    </Col>

                    <Col md="4">
                      <SELECT
                        name="lot"
                        onChange={handleChangeLot}
                        value={formData.client}
                        id="variation-select-8"
                        label="LOT"
                        data={lotData}
                      />
                    </Col>
                    <Col md="6" className="mt-4">
                      <div>
                        <label htmlFor="date_range" className="d-block">
                          Created Date Range:
                        </label>
                        <div className="d-flex">
                          <input
                            type="date"
                            id="date_range"
                            name="created_at_from"
                            value={formData.created_at_from}
                            onChange={handleChangeDateRange}
                            className="form-control mr-1"
                          />
                          <span className="align-self-center"> - </span>
                          <input
                            type="date"
                            name="created_at_to"
                            value={formData.created_at_to}
                            onChange={handleChangeDateRange}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className="mt-4">
                      <div>
                        <label htmlFor="date_range" className="d-block">
                          Hearing Date Range:
                        </label>
                        <div className="d-flex">
                          <input
                            type="date"
                            id="date_range"
                            name="hearing_date_from"
                            value={formData.hearing_date_from}
                            onChange={handleChangeHearingDateRange}
                            className="form-control mr-1"
                          />
                          <span className="align-self-center"> - </span>
                          <input
                            type="date"
                            name="hearing_date_to"
                            value={formData.hearing_date_to}
                            onChange={handleChangeHearingDateRange}
                            className="form-control ml-1"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="4" xs="12">
                      <SELECT
                        name="client"
                        onChange={handleChangeClient}
                        value={formData.client}
                        id="variation-select-8"
                        label="Client"
                        data={clientData}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {" "}
                    <Col md="4" xs="12">
                      <Button
                        color="primary"
                        onClick={handleConfirmSelection}
                        className="btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT M add-notes w-30"
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
            <div className="pagination-conatinerw">
              <div className="d-flex pagination-text justify-content-end">
                <span className="pto-5">Show</span>
                <select
                  class="form-select page-select12"
                  aria-label="Default select example"
                  onChange={(event) => setLimit(event.target.value)}
                >
                  {/* (filteredCatalogue && filteredCatalogue.length > 10) || */}
                  {
                    (list && list?.length > 10 && (
                      <>
                        <option selected>20</option>
                        <option value="50">50</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                      </>
                    ))}
                  <option value={list && list?.length}>
                    {count == 0
                      ? 0
                      : filteredCatalogue && filteredCatalogue?.length > 0
                        ? count
                        : list && list?.length}{" "}
                  </option>
                </select>
                <span className="pto-5">
                  {" "}
                  per page from{" "}
                  {count == 0
                    ? 0
                    : filteredCatalogue && filteredCatalogue?.length > 0
                      ? count
                      : list && list?.length}{" "}
                  results{" "}
                </span>
              </div>
              {/* <nav>
                <ul className="pagination position-po pagination-text">
                  <span className="pto-5">|</span>
                  <li className="page-item pto-5">
                    <span> Prev</span>
                  </li>

                  <li key="" className="">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>

                  <li className="page-item pto-5">
                    <span>Next</span>
                  </li>
                </ul>
              </nav> */}
            </div>
          </div>
          {loading === false && list && list?.length === 0 && (
            <div className="n--data text-center m-5">No data Found!</div>
          )}
          {loading === true && (
            <div className="n--data text-center m-5">
              <Loading />
            </div>
          )}
          <div>
            {filteredCatalogue && filteredCatalogue?.length > 0 ? (
              <div className="revenue-scroll-container">{filteredCatalogue}</div>
            ) : count == 0 ? (
              <p className="n--data text-center mt-5">No data found</p>
            ) : (
              <div className="revenue-scroll-container">{cataloge}</div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={show} toggle={handleCancel}>
        <p className="text-right m-0">
          <span onClick={handleCancel} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <p className="modal-case-12-heading">Case Limitation</p>
          <form>
            <Row>
              <Col md="4" xs="12">
                <SELECT
                  label="Chamber of arbitration"
                  name={"role"}
                  // value={role}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Source Law Firm"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Arbitrator"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
              <Col md="4" xs="12">
                <SELECT
                  label="Arbitrator"
                  name={"status"}
                  // value={status}
                  // onChange={handleChange}
                  data={[{ 1: "Active" }, { 0: "In Active" }]}
                />
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </section>
  );
};

export default withRouter(Revenuetable);