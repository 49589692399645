import React, { useState, useEffect, Fragment } from "react";
import {
  withRouter,
  Container,
  Row,
  Col,
  toast,
  TEXTFIELD,
  SELECT,
  CHECKBOX,
  FILEUPLOAD,
  Axios,
  Button,
  ApiUrl,
} from "../../components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { set } from "lodash";
const { FILE_UPLOAD, API_GET_ADDONS_CATEGORY } = ApiUrl;
const itemsOBJ = {
  name: "",
  price: 0,
  size: "",
};

const itemsOBJ_new = Object.assign({}, itemsOBJ);

const AddonObj = {
  catName: "",
  items: [{ ...itemsOBJ_new }],
};

const AddonObj_new = {
  user_id: "",
  course_name: "",
  organiser_name: "",
  course_duration: "",
  course_year: "",
};

const Addons = ({ dataMediation, updateMediation, courseName }) => {
  const [state, setState] = useState({
    addons: dataMediation,
    errors: {}
  });
  const [isChange, setIsChange] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    setState(prev => ({ ...prev, addons: dataMediation }));
  }, [dataMediation]);

  useEffect(() => {
    if (isChange) {
      updateMediation(state.addons);
      setIsChange(false);
    }
  }, [isChange, state.addons, updateMediation]);

  const validateField = (name, value, index) => {
    let error = "";
    switch (name) {
      case "course_name":
        error = value.trim() === "" ? "Required" : "";
        break;
      case "organiser_name":
        error = value.trim() === "" ? "Required" : "";
        break;
      case "course_duration":
        if (value.trim() === "") {
          error = "Required";
        } else if (!/^\d+$/.test(value.trim())) {
          error = "Must be a number(hours)";
        }
        break;
      case "course_year":
        const currentYear = new Date().getFullYear();
        if (value.trim() === "") {
          error = "Required";
        } else if (!/^\d{4}$/.test(value.trim())) {
          error = "Must 4-digit year";
        } else {
          const year = Number(value.trim());
          if (year < 1900) {
            error = "Must 1900 or later";
          } else if (year > currentYear) {
            error = "Future not allowed";
          } else {
            error = "";
          }
        }
        break;
      default:
        break;
    }
    return error;
  };

  const changeRowvalue = (e, i) => {
    e.preventDefault();
    const { name, value } = e.target;
    const error = validateField(name, value, i);
    const newErrors = {
      ...state.errors,
      [`${name}_${i}`]: error
    };
    setState(prev => ({
      ...prev,
      errors: newErrors,
      addons: [
        ...prev.addons.slice(0, i),
        { ...prev.addons[i], [name]: value },
        ...prev.addons.slice(i + 1)
      ]
    }));
    setIsChange(true);
    console.log(` ${name}: "${value}"`);
  };

  const addRow = (e) => {
    e.preventDefault();
    setState(prev => ({
      ...prev,
      addons: [...prev.addons, { ...AddonObj_new }],
    }));
    setIsChange(true);
  };

  const deleteRow = (e, index) => {
    e.preventDefault();
    const newAddons = state.addons.filter((_, i) => i !== index);
    const newErrors = { ...state.errors };
    Object.keys(newErrors).forEach(key => {
      if (key.endsWith(`_${index}`)) {
        delete newErrors[key];
      }
    });
    setState({
      addons: newAddons,
      errors: newErrors
    });
    setIsChange(true);
  };

  return (
    <Fragment>
      <h2 className="m-2 fw-bold font-weight-bold">
        {courseName} Training course
      </h2>
      {state.addons &&
        state.addons.map((s, i) => {
          return (
            <div className="uper-multi-row" key={i}>
              <Row>
                <Col md="3" xs="3">
                  <TEXTFIELD className="inputPadding"
                    label="Course Name"
                    value={s.course_name}
                    name={"course_name"}
                    onChange={(e) => changeRowvalue(e, i)}
                    errors={{ course_name: state.errors[`course_name_${i}`] }}
                    required
                  />
                </Col>
                <Col md="3" xs="3">
                  <TEXTFIELD className="inputPadding"
                    label="Organizer Name"
                    value={s.organiser_name}
                    name={"organiser_name"}
                    onChange={(e) => changeRowvalue(e, i)}
                    errors={{ organiser_name: state.errors[`organiser_name_${i}`] }}
                    required
                  />
                </Col>
                <Col md="3" xs="3">
                  <TEXTFIELD className="inputPadding"
                    label="Course Duration"
                    value={s.course_duration}
                    name={"course_duration"}
                    onChange={(e) => changeRowvalue(e, i)}
                    errors={{ course_duration: state.errors[`course_duration_${i}`] }}
                    required
                  />
                </Col>
                <Col md="2" xs="3">
                  <TEXTFIELD className="inputPadding"
                    label="Course Year"
                    value={s.course_year}
                    name={"course_year"}
                    onChange={(e) => changeRowvalue(e, i)}
                    errors={{ course_year: state.errors[`course_year_${i}`] }}
                    required
                    placeholder="YYYY"
                  />
                </Col>
                <Col md="1" xs="12">
                  {i === 0 ? (
                    <IconButton
                      aria-label="delete"
                      style={{
                        marginTop: 20,
                      }}
                      onClick={addRow}
                    >
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="Add"
                      onClick={(e) => deleteRow(e, i)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
    </Fragment>
  );
};

export default React.memo(Addons);