import React, { useState } from "react";

import {
  Row,
  Col,
  SELECT,
} from "../../../../../../components";


const Filters = (props) => {
  const {
    fieldName,
    fieldValue,
    filterData,
    removefilter,
    handleChange,
    exportButton,
  } = props;
  const { file, url, params, columns } = exportButton;
  let disabled = !fieldName || !fieldValue ? true : false;
  return (
    <form onSubmit={filterData} noValidate className="filter mt-3">
      <Row>
        <Col md="2" xs="12">
          <SELECT
            label="Filter Name"
            name="fieldName"
            onChange={(e) => handleChange(e)}
            value={fieldName}
            data={[
              { name: "Name" },
            ]}
          />
        </Col>
        <Col md="3" xs="12">
           <div className="form-search-header">
             <input
              type="search"
              placeholder="Search..."
              value={fieldValue}
              name="fieldValue"
              onChange={handleChange}
            />
            <button type="submit" disabled={disabled}>
              Search
            </button>
            {fieldValue && (
              <span
                onClick={removefilter}
                style={disabled ? { display: "none" } : {}}
              >
                <i class="zmdi zmdi-close"></i>
              </span>
            )}
          </div>
        </Col>
        <Col md="4" xs="12"></Col>
      </Row>
    </form>
  );
};

export default Filters;
