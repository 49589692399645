import React, { useState, useEffect } from "react";
import {
  Link,
  ApiUrl,
  Axios,
  moment,
  Loading,
  Row,
  Col,
  SELECT,
  TEXTFIELD,
  SUBMIT,
  toast,
} from "../../../../../components";
import Checkbox from "../../common/checkbox";
import { Button, Modal, ModalBody } from "reactstrap";

import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import filter from "../../../../../assets/images/icone-de-filtre-grise.png";

import Dashboard from "./dashboardadmin";

const {
  GET_ALL_CASES_ADMIN,
  API_FILTER_CASE,
  API_FILTER_METRIC,
  API_FILTER_SELECT_DATA,
  API_GET_USER_STAGES_URL,
  API_FILTER_TRACKER_CASE,
  API_FILTER_SEARCH,
  API_GET_CASE_STATUS,
  API_SUBMIT_EXCEL,
  API_GET_ARBITRATOR_CASES,
} = ApiUrl;

const Admincalender = (props) => {
  const [dateRange, setDateRange] = useState({ startStr: "", endStr: "" });
  const [show, setShow] = useState(false);
  const [showVenue, setVenue] = useState(false);
  const [type, setType] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [countList, setcountlist] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTrackerModal, setShowTrackerModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [trackerData, settrackerData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [arbitratorData, setArbitratorData] = useState([]);
  const [seatData, setSeatData] = useState([]);
  const [lotData, setLotData] = useState([]);
  const [stagingData, setStagingData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [stage, setStage] = useState([]);
  const [showNoticeA, setShowNoticeA] = useState(false);
  const [caseStatus, setcaseStatus] = useState([]);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  console.log(file, "file456");
  const [filterMetric, setFilterMetric] = useState("");
  const [typeData, setTypeData] = useState([]);
  const [formData, setFormData] = useState({
    status: "",
    created_at: "",
    created_at_from: "",
    created_at_to: "",
    arbitrator: "",
    hearing_date: "",
    hearing_date_from: "",
    hearing_date_to: "",
    not_have_hearing_date: "",
    state: "",
    lot: "",
    seatData: "",
    venue: "",
    client: "",
    stage: "",
    msg_type: "",
    msg_status: "",
    fieldValue: "",
    fieldName: "",
    notes: "",
    final_status: "",
  });
  const [showMetric, setShowmetric] = useState(true);
  const data = [
    {
      title: "Total",
      count: `${list?.length || "0" }`,
      value: "all",
    },
    {
      title: "Last Week",
      count: `${countList?.last_week_count || "0" }`,
      value: "last_week_pending",
    },
    {
      title: "3 Months",
      count: `${countList?.three_month_count || "0" }`,
      value: "3_month_pending",
    },
    {
      title: "six months older",
      count: `${countList?.six_month_count || "0" }`,
      value: "6_month_pending",
    },
    {
      title: "ten months older",
      count: `${countList?.ten_month_count || "0" }`,
      value: "10_month_pending",
    },
  ];
  const toggleRevMetric = () => {
    console.log(showMetric, "show");
    setShowmetric(!showMetric);
  };

  const handleDateRange = (range) => {
    const { startStr, endStr } = range;
    console.log("Date range received from child:", { startStr, endStr });
    setDateRange(range);
    getTableRecords(startStr, endStr);
  };

  const getTableRecords = async (startDate, endDate) => {
    setLoading(true);
    try {
      const res = await Axios.get(
        `${GET_ALL_CASES_ADMIN}?startDate=${startDate} 00:00:00&endDate=${endDate} 23:59:59`
      );
      const list = res.data;
      setList(list?.data?.cases);
      setcountlist(list?.data?.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching table records:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    filterData("state");
    filterData("venue");
    filterData("stages");
    filterData("arbitrator");
    filterData("seat");
    filterData("lot");
    getStage();
    filterData("lawFirmClient");
    getStatus();
  }, []);

  const filterData = async (type) => {
    try {
      const response = await Axios.get(API_FILTER_SELECT_DATA + `${type}`);
      if (type === "state") {
        var data = response.data.data;
        const statedata =
          data?.length > 0 &&
          data.map((h, id) => {
            return { [h.state]: h.state };
          });
        setStateData(statedata);
      } else if (type === "venue") {
        var data = response.data.data;
        const venuedata =
          data?.length > 0 &&
          data.map((h) => {
            return { [h.venue]: h.venue };
          });
        setVenueData(venuedata);
      }
      //  else if (type === "stages") {
      //   setStagesData(response.data.data.map((item) => item.stage));
      //   value;
      // }
      else if (type === "arbitrator") {
        var data = response.data.data;
        const arbitdata =
          data?.length > 0 &&
          data.map((h, id) => {
            return { [h.name]: h.name };
          });
        setArbitratorData(arbitdata);
      } else if (type === "lot") {
        var data = response.data.data;
        const lotdata =
          data?.length > 0 &&
          data.map((h) => {
            return { [h.title]: h.title };
          });
        setLotData(lotdata);
      } else if (type === "seat") {
        var data = response.data.data;
        const seatdata =
          data?.length > 0 &&
          data.map((h) => {
            return { [h.seat]: h.seat };
          });
        setSeatData(seatdata);
      } else if (type === "stages") {
        var data = response.data.data;

        setStagingData(response.data.data);
      } else if (type === "lawFirmClient") {
        var data = response.data.data;
        const clientdata =
          data?.length > 0 &&
          data.map((h, id) => {
            return { [h.id]: h.name };
          });
        setClientData(clientdata);
      }
    } catch (error) {
      console.error("Error filtering data");
    }
  };

  const getStatus = async () => {
    Axios.get(API_GET_CASE_STATUS)
      .then((response) => {
        const stage = response.data;
        console.log(stage, "stage");
        const status = stage.data.map((s) => {
          return { [s.id]: s.name };
        });
        setcaseStatus(status);
      })
      .catch((err) => {
        console.log("");
      });
  };

  const handleOpenModal1 = () => {
    setShowModal(true);
  };

  const getStage = async () => {
    Axios.get(API_GET_USER_STAGES_URL)
      .then((response) => {
        const stage = response.data;
        const stages = stage.data.map((s) => {
          return { [s.id]: s.stage };
        });
        setStage(stages);
      })
      .catch((err) => {
        console.log("");
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // const handleChangeKey = (e) => {
  //   const { name, value } = e.target;
  //   console.log(name, value, "checkingq");
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   })
  //   );
  //   searchFilters();
  // };

  const handleChangeKey = (e) => {
    const { name, value } = e.target;

    // Update formData state based on the name of the input field
    if (name === "fieldName") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        fieldValue: "", // Reset fieldValue when fieldName changes
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // Call searchFilters with the correct data
    const searchData =
      name === "fieldName"
        ? { fieldName: value, fieldValue: "" }
        : { fieldName: formData.fieldName, fieldValue: value };

    // Ensure value is not empty before calling searchFilters
    if (value.trim() !== "") {
      searchFilters(searchData);
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    const formattedDate = value
      ? new Date(value).toISOString().split("T")[0]
      : "";
    setFormData({ ...formData, [name]: formattedDate });
  };
  const handleHearingDateChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      hearing_date: value,
    }));
  };
  const handlenotassignChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      not_have_hearing_date: value,
    }));
  };
  const handleChangeState = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      state: value,
    }));
  };
  const handleChangeSeat = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      seat: value,
    }));
  };
  const handleChangeLot = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      lot: value,
    }));
  };
  const handleChangeClient = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      client: value,
    }));
  };
  const handleChangeNotes = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      notes: value,
    }));
  };
  const handleChangeDateRange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeHearingDateRange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const refresh = () => {
    if (dateRange.startStr && dateRange.endStr) {
      getTableRecords(dateRange.startStr, dateRange.endStr);
    } else {
      console.warn("No date range selected. Skipping refresh with date filter.");
    }
    setCount();
    setResponseData([]);
    settrackerData([]);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModalTracker = () => {
    setShowTrackerModal(true);
  };
  const handleCloseTrackerModal = () => setShowTrackerModal(false);
  const handleConfirmSelection = () => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => value !== "")
    );
    Axios.post(API_FILTER_CASE, filteredFormData)
      .then((response) => {
        // console.log(response, "ree");
        setList(response?.data?.data);
        setCount(response?.data?.data?.length);
        setShowModal(false);
        setFormData({});
        // setResponseData(response.data);
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
      });
  };

  const handleFile = (event) => {
    event.preventDefault();
    let selectedFile = event.target.files[0];
    let newErr = { file: "" };
    console.log(selectedFile, "filefile");

    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleTrackerSelection = () => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([_, value]) => value !== "")
    );
    Axios.Post(API_FILTER_TRACKER_CASE, filteredFormData)
      .then((response) => {
        console.log(response, "ree");
        if (response.data.success === true) {
          setList(response.data.data);
          setCount(response?.data?.data?.length);
          setShowTrackerModal(false);
          setFormData({});
          // settrackerData(response.data.data);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
      });
  };

  const submitExcel = () => {
    console.log(file, "after file456");

    const data = new FormData();
    data.append("case_file", file);

    Axios.Post(API_SUBMIT_EXCEL, data)
      .then((response) => {
        console.log(response, "ree");
        if (response.data.success === true) {
          setShowTrackerModal(false);
          setList(response.data.data);
          setFile("");
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
      });
  };

  const metricFilters = (value) => {
    console.log(value, "filterMetrics");
    const data = {};
    if (value == "all") {
      data.all = true;
    }
    if (value == "last_week_pending") {
      data.last_week_pending = true;
    }
    if (value == "3_month_pending") {
      data.pending_3_months = true;
    }
    if (value == "6_month_pending") {
      data.pending_6_months = true;
    }
    if (value == "10_month_pending") {
      data.pending_10_months = true;
    }
    Axios.post(API_FILTER_METRIC, data)
      .then((response) => {
        console.log(response, "xzcxzczxc");
        var data = response.data.data;
        if (response.data.success === true) {
          setList(data);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        // toast.error("No data found");
      });
  };
  // const searchFilters = (value) => {
  //   console.log(value, formData.fieldName, "filterMetrics");
  //   setLoading(true);
  //   let data = {};
  //   let url = API_FILTER_SEARCH;
  //   if (formData.fieldName == "client") {
  //     data = `?client_name=${formData.fieldValue}`;
  //   } else {
  //     data = `?case_number=${formData.fieldValue}`;
  //   }

  //   Axios.get(url + data)
  //     .then((response) => {
  //       console.log(response, "responsexzcxzczxc");
  //       var data = response.data.data;
  //       console.log(data, "xzcxzczxc");
  //       setList(data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       // toast.error("No data found");
  //     });
  // };

  const searchFilters = (value) => {
    console.log(value, formData.fieldName, "filterMetrics");
    setLoading(true);
    let data = {};
    let url = API_FILTER_SEARCH;
    if (value.fieldName == "client") {
      data = `?client_name=${value.fieldValue}`;
    } else if (value.fieldName == "respondent_name") {
      data = `?respondent_name=${value.fieldValue}`;
    } else if (value.fieldName == "LAN") {
      data = `?LAN=${value.fieldValue}`;
    } else {
      data = `?case_number=${value.fieldValue}`;
    }

    Axios.get(url + data)
      .then((response) => {
        console.log(response, "responsexzcxzczxc");
        var data = response.data.data;
        console.log(data, "xzcxzczxc");
        setList(data);
        setLoading(false);
      })
      .catch((error) => {
        // toast.error("No data found");
      });
  };

  const removefilter = () => {
    setFormData((prev) => ({ ...prev, fieldValue: "", fieldName: "" }));
    setShowSearch(!showSearch);
    refresh();
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // const handleClick = (e, id) => {
  //   const isChecked = isCheck.includes(id);
  //   if (isChecked) {
  //     setIsCheck(isCheck.filter(item => item !== id));
  //   } else {
  //     setIsCheck([...isCheck, id]);
  //   }
  // };

  const handleClick = (e, id) => {
    const isChecked = isCheck.includes(id);
    if (isChecked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    } else {
      setIsCheck([...isCheck, id]);
    }
  };
  const toggleRev = (index) => {
    setShow((prev) => (prev === index ? null : index));
  };
  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}.${month}.${year}`;
  };

  const cataloge =
    list &&
    list?.length > 0 &&
    list.slice(0, limit).map((item, index) => {
      const partyRespondentVs = item.case_master_users.find(
        (i) =>
          i.related_user_role === "Client" && i.related_user_type === "claimant"
      );
      const respondentNameVs = partyRespondentVs
        ? partyRespondentVs.related_user.name
        : null;

      const respondentNames = item.case_master_users.reduce((acc, i) => {
        if (
          i.related_user_role === "Party" &&
          i.related_user_type === "respondent"
        ) {
          acc.push(i.related_user.name);
        }
        return acc;
      }, []);
      return (
        <div className="single-revenue-conatiner" key={item.id}>
          <div className="revenue-table-heading">
            <p className="checkbox-revenue">
              {" "}
              {/* <span>
                <Checkbox
                   key={item.id}
                  type="checkbox"
                  name={item.label}
                  id={item.id}
                  handleClick={(e) => handleClick(e, item.id)}
                  isChecked={isCheck.includes(item.id)}
                />
              </span> */}
              <Link
                to={`/app/client/case-detail/${item.hash_id}`}
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Case No."
                className="d-flex"
              >
                <div className="ml-2 text-capitalize">
                  <span>{respondentNameVs} vs </span>
                  <span>
                    {respondentNames?.length === 1
                      ? respondentNames
                      : respondentNames?.length === 2
                        ? respondentNames[0] + " & another"
                        : respondentNames?.length === 3 ||
                          respondentNames?.length > 3
                          ? respondentNames[0] + " & others"
                          : ""}
                  </span>
                </div>
              </Link>
            </p>
            {/* <p className="checkbox-revenue-subtext">
              <span> {item.date} </span>
              <span className="revenue-status">{item.case_status}</span>
            </p> */}
            <p className="checkbox-revenue-subtext">
              <span className="status-container-status14">
                Case Status : {item?.final_status?.name || "N/A"}
              </span>
              <span> {item?.date} </span>
              <span className="revenue-status-14 font-bold">
                Present Stage : {item?.case_status}
              </span>
            </p>
          </div>
          <Link to={`/app/client/case-detail/${item.hash_id}`}>
            <div className="filesrevenue-conatiner">
              <div className="filesrevenue-subconatiner">
                <div className="pov123 mt-3">
                  <p>
                    <span className="pov123Text">Case No.</span>
                    {item.case_number}
                  </p>
                  <p>
                    <span className="pov123Text">Filed On:</span>
                    {moment(item.created_at).format("DD.MM.YYYY  hh:mm a")}
                  </p>
                </div>
              </div>
              <div className="filesrevenue-subconatinerpov">
                {/* {item.hearing &&
                  item?.hearing?.length > 0 &&
                  item?.hearing.map((hearing) => {
                    const startDate = new Date(hearing?.hearing?.start_date);
                    const currentDate = new Date();

                    if (startDate >= currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing?.hearing_id}
                        >
                          <p>Next</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    } else if (startDate < currentDate) {
                      return (
                        <div
                          className="filesrevenue-povBoxes"
                          key={hearing?.hearing_id}
                        >
                          <p>Previous</p>
                          <p>{formatDate(startDate)}</p>
                        </div>
                      );
                    }
                  })} */}

                {item?.hearing &&
                  item?.hearing?.length > 0 &&
                  (() => {
                    const currentDate = new Date();

                    // Filter out previous and next hearings
                    const previousHearings = item?.hearing?.filter(
                      (hearing) => {
                        const startDate = new Date(
                          hearing?.hearing?.start_date
                        );
                        return startDate < currentDate;
                      }
                    );

                    const nextHearings = item.hearing.filter((hearing) => {
                      const startDate = new Date(hearing?.hearing?.start_date);
                      return startDate >= currentDate;
                    });

                    // Sort previous hearings by start_date in descending order
                    previousHearings.sort(
                      (a, b) =>
                        new Date(b.hearing?.start_date) -
                        new Date(a.hearing?.start_date)
                    );

                    // Sort next hearings by start_date in ascending order
                    nextHearings.sort(
                      (a, b) =>
                        new Date(a.hearing?.start_date) -
                        new Date(b.hearing?.start_date)
                    );

                    // Get the latest previous hearing and the earliest next hearing
                    const latestPreviousHearing = previousHearings[0];
                    const nextHearing = nextHearings[0];

                    return (
                      <>
                        {latestPreviousHearing && (
                          <div
                            className="filesrevenue-povBoxes"
                            key={latestPreviousHearing?.hearing_id}
                          >
                            <p>Previous</p>
                            <p>
                              {formatDate(
                                new Date(
                                  latestPreviousHearing?.hearing?.start_date
                                )
                              )}
                            </p>
                          </div>
                        )}
                        <div
                          className="filesrevenue-povBoxes"
                          key={
                            nextHearing ? nextHearing?.hearing_id : "no-next"
                          }
                        >
                          <p>Next</p>
                          <p>
                            {nextHearing
                              ? formatDate(
                                new Date(nextHearing?.hearing?.start_date)
                              )
                              : "N/A"}
                          </p>
                        </div>
                      </>
                    );
                  })()}

                {!item.hearing ||
                  (item.hearing.every(
                    (hearing) =>
                      new Date(hearing?.hearing?.start_date) >= new Date()
                  ) && <></>)}
              </div>
              {/* <div className="filesrevenue-subconatinerpov">
              <div className="filesrevenue-povBoxes">
                <p>Previous</p>
                <p> Oct 19, 2023 </p>
              </div>
              <div className="filesrevenue-povBoxes">
                <p>Previous</p>
                <p> Oct 19, 2023 </p>
              </div>
              <div className="filesrevenue-povBoxes">
                <p>Previous</p>
                <p> Oct 19, 2023 </p>
              </div>
            </div> */}
            </div>
          </Link>

          {showVenue === index ? (
            <div onClick={() => toggleRev1(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev1(index)} className="text-center">
              <span className="arrow-conainer">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
          {showVenue === index &&
            item.metas
              .filter(m => !["venue", "seat", "section_17"].includes(m.meta_key))
              .map((m) => {
                return (
                  <div key={m.meta_key} className="revenue-state">
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {" "}
                        {m.meta_value}{" "}
                      </p>
                    </div>
                  </div>
                );
              })}

          { /* {showVenue === index &&
            item.metas.map((m) => {
              return (
                <>
                  <div className="revenue-state">
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {" "}
                        {m.meta_value}{" "}
                      </p>
                    </div>
                  </div>
                </>
              );
            })} */}

          {/* {show === index ? (
          <div onClick={() => toggleRev(index)}>
            <h2 class="hr-lines">
              <span>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </h2>
          </div>
        ) : (
          <div onClick={() => toggleRev(index)} className="text-center">
            <span className="arrow-conainer">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                  stroke="#42526E"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        )}
        {show === index && (
          <div className="revenue-state2">
            <div className="revenue-state-box">
              <p className="text-court"> Usergroup: </p>
              <p className="text-state"> No user group added yet </p>
            </div>
            <div className="revenue-state-box">
              <p className="text-court">Team:</p>
              <p className="text-state"> No user added yet </p>
            </div>
          </div>
        )} */}
        </div>
      );
    });

  return (
    <>
      <section className="total_revnue">
        <div fluid>
          {/* <div className="filters-container">
          <div className="filters-sub-container">
            <button className="add-new-btn">+ New</button>
            <div>
              <form className="form-search-header">
                <input type="search" placeholder="Search..." />
                <button type="submit">Search</button>
              </form>
            </div>
          </div>
        </div> */}
          <div className={showMetric == false ? "d-none" : "revenue-wrap1"}>
            {data.map((cases, index) => {
              return (
                <div className="total_booking_wrap1" key={index}>
                  <div
                    className="booking_coomon"
                    onClick={() => metricFilters(cases.value)}
                  >
                    <h5 name={cases.value} value={cases.value}>
                      {cases.title}
                    </h5>
                    <h4 className="mb-0">{cases.count}</h4>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-end">
            <p className="hideText" onClick={() => toggleRevMetric()}>
              {showMetric == false ? <span>Show </span> : <span>Hide </span>}
              Metric Cards
            </p>
          </div>
        </div>
      </section>

      <Dashboard onDateRangeChange={handleDateRange} List={list} />

      <section className="Sales_analytics">
        <div fluid>
          <div className="revenueTable-conatiner">
            <div className="revenueTable-pagination-box">
              <div className="pgination-1st">
                {" "}
                {/* <span className="check-po-icon">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      onChange={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  </div>
                </span> */}
                <span className="check-po-icon" onClick={refresh}>
                  <i class="zmdi zmdi-refresh-alt"></i>
                </span>
                {/* <span className="check-po-icon" check-po-icon>
                  <i class="zmdi zmdi-delete"></i>
                </span> */}
                <span className="check-po-icon" onClick={handleOpenModal1}>
                  <span className="mr-2 mb-1">Filter</span>

                  <img
                    src={filter}
                    alt=""
                    style={{ width: " 20px", height: " 18px" }}
                  />
                  {/* <i class="zmdi zmdi-filter-list"></i> */}
                </span>
                <span
                  className="check-po-icon"
                  onClick={handleOpenModalTracker}
                >
                  <span className="mr-2 mb-1">Filter by excel</span>

                  <img
                    src={filter}
                    alt=""
                    style={{ width: " 20px", height: " 18px" }}
                  />
                </span>
                <Modal isOpen={showModal} toggle={handleCloseModal}>
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      onClick={handleCloseModal}
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                  <ModalBody>
                    <Row>
                      <Col md="4" className="mt-3">
                        <SELECT
                          onChange={handleChange}
                          value={formData.final_status}
                          name="final_status"
                          label="Case Status"
                          data={caseStatus}
                        />
                      </Col>
                      <Col md="4" xs="12">
                        <FormControl className="select-multi">
                          <InputLabel id="demo-mutiple-checkbox-label">
                            Present Stage
                          </InputLabel>
                          <Select
                            fullWidth
                            id="demo-mutiple-name"
                            onChange={handleChange}
                            name="status"
                          >
                            <option
                              value="pending"
                              style={{ cursor: "pointer" }}
                            >
                              Pending
                            </option>
                            <option
                              value="registered"
                              style={{ cursor: "pointer" }}
                            >
                              Registered
                            </option>
                            {stagingData &&
                              stagingData.map((e, key) => {
                                return (
                                  <option
                                    key="abc"
                                    value={e.stage}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {e.stage}
                                  </option>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col md="4" xs="12">
                        <TEXTFIELD
                          type="date"
                          label="Created At"
                          onChange={handleDateChange}
                          value={formData.created_at}
                          name="created_at"
                        />
                      </Col>

                      <Col md="4" className="mt-3">
                        <SELECT
                          onChange={handleChange}
                          value={formData.arbitrator}
                          name="arbitrator"
                          label="Arbitrator"
                          data={arbitratorData}
                        />
                      </Col>

                      <Col md="4">
                        <TEXTFIELD
                          type="date"
                          label="Hearing Date"
                          onChange={handleHearingDateChange}
                          value={formData.hearing_date}
                          name="hearing_date"
                        />
                      </Col>
                      <Col md="4" className="mt-3">
                        <SELECT
                          onChange={handlenotassignChange}
                          value={formData.not_have_hearing_date}
                          name="not_have_hearing_date"
                          label="Hearing not assigned"
                          data={[{ yes: "Yes" }]}
                        />
                      </Col>

                      <Col md="4" style={{ marginTop: "10px" }}>
                        <SELECT
                          onChange={handleChangeState}
                          value={formData.state}
                          name="state"
                          label="State"
                          data={stateData}
                        />
                      </Col>
                      <Col md="4">
                        <SELECT
                          onChange={handleChangeSeat}
                          value={formData.seat}
                          name="seat"
                          label="Seat"
                          data={seatData}
                        />
                      </Col>
                      <Col md="4">
                        <SELECT
                          onChange={handleChange}
                          value={formData.venue}
                          name="venue"
                          id="variation-select-6"
                          label="Venue"
                          data={venueData}
                        />
                      </Col>

                      <Col md="4">
                        <SELECT
                          name="lot"
                          onChange={handleChangeLot}
                          value={formData.lot}
                          id="variation-select-8"
                          label="LOT"
                          data={lotData}
                        />
                      </Col>
                      <Col md="4" xs="12">
                        <SELECT
                          name="client"
                          onChange={handleChangeClient}
                          value={formData.client}
                          id="variation-select-8"
                          label="Client"
                          data={clientData}
                        />
                      </Col>

                      <Col md="6" className="mt-4">
                        <div>
                          <label htmlFor="date_range" className="d-block">
                            Hearing Date Range:
                          </label>
                          <div className="d-flex">
                            <input
                              type="date"
                              id="date_range"
                              name="created_at_from"
                              value={formData.created_at_from}
                              onChange={handleChangeDateRange}
                              className="form-control mr-1"
                            />
                            <span className="align-self-center"> - </span>
                            <input
                              type="date"
                              name="created_at_to"
                              value={formData.created_at_to}
                              onChange={handleChangeDateRange}
                              className="form-control ml-1"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className="mt-4">
                        <div>
                          <label htmlFor="date_range" className="d-block">
                            Case Filed Date Range:
                          </label>
                          <div className="d-flex">
                            <input
                              type="date"
                              id="date_range"
                              name="hearing_date_from"
                              value={formData.hearing_date_from}
                              onChange={handleChangeHearingDateRange}
                              className="form-control mr-1"
                            />
                            <span className="align-self-center"> - </span>
                            <input
                              type="date"
                              name="hearing_date_to"
                              value={formData.hearing_date_to}
                              onChange={handleChangeHearingDateRange}
                              className="form-control ml-1"
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md="4" xs="12">
                        <SELECT
                          name="notes"
                          onChange={handleChangeNotes}
                          value={formData.notes}
                          id="variation-select-8"
                          label="Notes"
                          data={[{ yes: "Yes" }]}
                        />
                      </Col>
                      {/* <Col md="4" xs="12">
                        <FormControl className="select-multi mt-0">
                          <InputLabel id="demo-mutiple-checkbox-label">
                            Notice
                          </InputLabel>
                          <Select
                            fullWidth
                            id="demo-mutiple-name"
                            onChange={handleChange}
                            name="notice"
                          >
                            <option
                              value="pending"
                              style={{ cursor: "pointer" }}
                            >
                              Pending
                            </option>
                            <option
                              value="registered"
                              style={{ cursor: "pointer" }}
                            >
                              Registered
                            </option>
                            {stagingData &&
                              stagingData.map((e, key) => {
                                return (
                                  <option
                                    key="abc"
                                    value={e.stage}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {e.stage}
                                  </option>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Col> */}
                      <Col md="4" xs="12" style={{ marginTop: "3px" }}>
                        <SELECT
                          name="msg_type"
                          value={formData.msg_type}
                          onChange={handleChange}
                          label="Notice Type"
                          data={[
                            { whatsapp: "Whatsapp" },
                            { sms: "SMS" },
                            { email: "Mail" },
                          ]}
                        />
                      </Col>
                      <Col md="4" xs="12" style={{ marginTop: "4px" }}>
                        <SELECT
                          name="msg_status"
                          value={formData.msg_status}
                          onChange={handleChange}
                          label="Notice Status"
                          data={[
                            { sent: "Sent" },
                            { delivered: "Delivered" },
                            { read: "Read" },
                            { UNDELIV: "Un Delivered" },
                            { failed: "Failed" },
                          ]}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col md="4" xs="12">
                        <Button
                          color="primary"
                          onClick={handleConfirmSelection}
                          className="btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT M add-notes w-30"
                        >
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={showTrackerModal}
                  toggle={handleCloseTrackerModal}
                  className="generateCerti"
                >
                  <div className="modal-header">
                    <span>Filter by excel</span>

                    <button
                      type="button"
                      className="close"
                      onClick={handleCloseTrackerModal}
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                  <ModalBody>
                    <Row>
                      <Col md="12" xs="12">
                        <div class="file-upload-cus mb-2">
                          <div class="file-select">
                            <div class="file-select-button" id="fileName">
                              Choose File
                            </div>
                            <div class="file-select-name" id="noFile">
                              {fileName ? fileName : "No file chosen..."}
                            </div>
                            <div class="importcus">
                              <input
                                type="file"
                                name={"file"}
                                id="chooseFile"
                                accept=".xlsx , .xls , .csv"
                                onChange={handleFile}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" xs="12">
                        <Button
                          color="primary"
                          onClick={submitExcel}
                          className={
                            file
                              ? "btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT add-notes w-30"
                              : "btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT apply-disabled"
                          }
                        >
                          Apply
                        </Button>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md="4" xs="12">
                        <SELECT
                          label="Stage"
                          name={"stage"}
                          onChange={handleChange}
                          data={stage}
                          className="mt-0"
                        />
                      </Col>
                      <Col md="4" xs="12">
                        <SELECT
                          name="msg_type"
                          value={formData.msg_type}
                          onChange={handleChange}
                          label="Type"
                          data={[
                            { whatsapp: "Whatsapp" },
                            { sms: "SMS" },
                            { email: "Mail" },
                          ]}
                        />
                      </Col>
                      <Col md="4" xs="12">
                        <SELECT
                          name="msg_status"
                          value={formData.msg_status}
                          onChange={handleChange}
                          label="Status"
                          data={[
                            { sent: "Sent" },
                            { delivered: "Delivered" },
                            { read: "Read" },
                          ]}
                        />
                      </Col>
                    </Row> 
                    <Row>
                      {" "}
                      <Col md="4" xs="12">
                        <Button
                          color="primary"
                          onClick={handleTrackerSelection}
                          className={
                            formData.stage &&
                            formData.msg_status &&
                            formData.msg_type
                              ? "btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT add-notes w-30"
                              : "btn btn-warning add_claimant MuiButtonBase-root MuiButton-root jr-btn jr-btn-label left CBmargin CBmarginT apply-disabled"
                          }
                        >
                          Apply
                        </Button>
                      </Col>
                    </Row>*/}
                  </ModalBody>
                </Modal>
                <span className="mr-2 mb-1 se12">Search</span>
                <span
                  className="search56"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <i class="zmdi zmdi-search"></i>
                </span>
              </div>

              <div className="pagination-container">
                <div className="d-flex pagination-text">
                  <span className="pto-5">Show</span>
                  <select
                    className="form-select page-select12"
                    aria-label="Default select example"
                    onChange={(event) => setLimit(event.target.value)}
                    value={limit || (list?.length > 10 ? "20" : list?.length)}
                  >
                    {list?.length >= 10 && <option value="20">20</option>}
                    {list?.length >= 50 && <option value="50">50</option>}
                    {list?.length >= 100 && <option value="100">100</option>}
                    {list?.length >= 200 && <option value="200">200</option>}
                    {list?.length >= 500 && <option value="500">500</option>}
                    <option value={list?.length}>
                      {count === 0 ? 0 : list?.length}
                    </option>
                  </select>
                  <span className="pto-5">
                    per page from {count === 0 ? 0 : list && list?.length} results
                  </span>
                </div>
              </div>

            </div>
            {showSearch === true && (
              <div className="box-serch-filter0">
                <Row className="ml-2">
                  <Col md={3}>
                    <SELECT
                      label="Filter Name"
                      name="fieldName"
                      onChange={handleChangeKey}
                      value={formData.fieldName}
                      data={[
                        { client: "Client Name" },
                        { respondent_name: "Respondent Name" },
                        { case_number: "Case Number" },
                        { LAN: "LAN" },
                      ]}
                    />
                  </Col>
                  <Col md={3}>
                    <div className="form-search-header">
                      <input
                        type="search"
                        placeholder="Search..."
                        value={formData.fieldValue}
                        name="fieldValue"
                        onChange={handleChangeKey}
                      />

                      {/* <button onClick={searchFilters} className="f-srch-i">
                        <i class="zmdi zmdi-search"></i>
                      </button> */}
                      {formData.fieldValue && (
                        <span
                          onClick={removefilter}
                        // style={disabled ? { display: "none" } : {}}
                        >
                          <i class="zmdi zmdi-close"></i>
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            {loading === false && list && list?.length === 0 && (
              <div className="n--data text-center m-5">No data Found!</div>
            )}
            {loading === true && (
              <div className="n--data text-center m-5">
                <Loading />
              </div>
            )}
            <div className="revenue-scroll-container">{cataloge}</div>
            {/* {filteredCatalogue && filteredCatalogue?.length > 0 ? (
              <div className="revenue-scroll-container">
                {filteredCatalogue}
              </div>
            ) : count == 0 ? (
              <p className="n--data text-center mt-5">No data found</p>
            ) : trackCatalogue && trackCatalogue?.length > 0 ? (
              <div className="revenue-scroll-container">{trackCatalogue}</div>
            ) : count == 0 ? (
              <p className="n--data text-center mt-5">No data found</p>
            ) : (
              <div className="revenue-scroll-container">{cataloge}</div>
            )} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Admincalender;