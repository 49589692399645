import React, { Fragment } from "react";
import {
  withRouter, Row, Col, toast, Axios,
  TEXTFIELD, ApiUrl, RESET, SUBMIT
} from "../../../../../../../components";

const { API_GET_VENUE_DETAILS, UPDATE_VENUE_DETAILS } = ApiUrl;

class EditVenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: this.props.match.params.id,
      seat: "",
      venue: "",
      loading: true,
      disabled: true,
      originalData: {},
      errors: {},
    };
  }

  componentDidMount() {
    Axios.get(`${API_GET_VENUE_DETAILS}/${this.state.editId}`)
      .then((res) => {
        if (res.data.success) {
          const data = res.data.data;
          let seat = "";
          let venue = "";

          data.forEach((item) => {
            if (item.meta_key === "seat") {
              seat = item.meta_value;
            } else if (item.meta_key === "venue") {
              venue = item.meta_value;
            }
          });

          this.setState({
            seat,
            venue,
            loading: false,
            originalData: { seat, venue },
          });
        } else {
          toast.error("Failed to fetch data");
        }
      })
      .catch((err) => {
        toast.error("Error fetching data");
        this.setState({ loading: false });
      });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      (prevState) => ({
        [name]: value,
        errors: { ...prevState.errors, [name]: "" },
      }),
      () => this.checkFormChange()
    );
  };

  checkFormChange = () => {
    const { seat, venue, originalData } = this.state;
    const isChanged =
      seat !== originalData.seat ||
      venue !== originalData.venue;

    this.setState({ disabled: !isChanged });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { seat, venue, editId, originalData } = this.state;

    let errors = {};
    if (!seat) {
      errors.seat = "The Seat detail is required.";
    }
    if (!venue) {
      errors.venue = "The Venue detail is required.";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    let updateData = {};

    if (seat !== originalData.seat && seat !== "") {
      updateData.seat = seat;
    }
    if (venue !== originalData.venue && venue !== "") {
      updateData.venue = venue;
    }

    if (Object.keys(updateData).length === 0) {
      toast.info("No changes detected.");
      return;
    }

    Axios.post(`${UPDATE_VENUE_DETAILS}/${editId}`, updateData)
      .then((res) => {
        if (res.data.success) {
          toast.success("Venue updated successfully!");
          this.props.history.goBack();
        } else {
          toast.error("Error updating venue");
        }
      })
      .catch((err) => {
        toast.error("Error updating venue");
        console.log(err);
      });
  };

  reset = () => {
    const { originalData } = this.state;
    this.setState({
      seat: originalData.seat || "",
      venue: originalData.venue || "",
      disabled: true,
      errors: {},
    });
  };

  render() {
    const { seat, venue, loading, disabled, errors } = this.state;

    return (
      <div className="bg-white p-5 mr-3">
        <Fragment>
          {loading && <div className="text-center w-100">Loading...</div>}

          <div className="mb-3">
            <button
              className="btn"
              onClick={() => this.props.history.goBack()}
            >
              <i className="zmdi zmdi-arrow-left" style={{ fontSize: '19px' }}></i>
            </button>
          </div>

          <form onSubmit={this.handleSubmit} noValidate>
            <Row>
              <Col md="4" xs="12">
                <TEXTFIELD
                  label="Seat"
                  value={seat}
                  name="seat"
                  onChange={this.handleChange}
                />
                {errors.seat && <div className="text-danger">{errors.seat}</div>}
              </Col>

              <Col md="4" xs="12">
                <TEXTFIELD
                  label="Venue"
                  value={venue}
                  name="venue"
                  onChange={this.handleChange}
                />
                {errors.venue && <div className="text-danger">{errors.venue}</div>}
              </Col>
            </Row>
            <Row>
              <Col md="2" xs="12">
                <SUBMIT title="Submit" disabled={disabled} />
              </Col>
              <Col md="2" xs="12">
                <RESET title="Reset" onClick={this.reset} />
              </Col>
            </Row>
          </form>
        </Fragment>
      </div>
    );
  }
}

export default withRouter(EditVenue);