import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  ApiUrl,
  Axios,
  Row,
  Col,
  toast,
  Loading,
  CardText,
} from "../../../../../components";

const {
  API_GET_USERS_ADMIN_APPROVALS,
  API_APPROVE_USER_LAWFIRM,
  API_DIS_APPROVE_USER_LAWFIRM,
} = ApiUrl;

const Revenuetable = () => {
  const [limit, setLimit] = useState(20);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const downloadFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  useEffect(() => {
    getTableRecords();
  }, []);

  const getTableRecords = async () => {
    setLoading(true);
    Axios.Get(API_GET_USERS_ADMIN_APPROVALS + "Arbitrator")
      .then((response) => {
        const resData = response.data;
        if (response.status == 200) {
          setList(resData?.data || []);
          setLoading(false);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };

  const refresh = () => {
    getTableRecords();
  };

  const userApprove = async (id, status) => {
    const data = {
      user_id: id,
      status: status,
    };
    Axios.Post(API_APPROVE_USER_LAWFIRM, data)
      .then((res) => {
        let response = res.data;
        setLoading(false);
        if (response.success == true) {
          toast.success(response.message);
          setTimeout(() => {
            getTableRecords();
          }, 1000);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const userDisApprove = async (id, status) => {
    const data = {
      user_id: id,
      status: status,
    };
    Axios.Post(API_DIS_APPROVE_USER_LAWFIRM, data)
      .then((res) => {
        let response = res.data;
        setLoading(false);
        if (response.success == true) {
          toast.success(response.message);
          setTimeout(() => {
            getTableRecords();
          }, 1000);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const cataloge =
    list &&
    list.length > 0 &&
    list?.slice(0, limit).map((item, index) => {
      return (
        <div className="timline-content" key={index}>
          <div className="filesrevenue-subconatiner">
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={12}>
                    <div className="mt-3">
                      <CardText className="col-sm-6">
                        <p className="text-capitalize">
                          <span className="pov123Text">Name:</span>
                          {item?.name}
                        </p>
                      </CardText>
                      <CardText className="col-sm-6">
                        <p>
                          <span className="pov123Text">Email:</span>
                          {item?.email}
                        </p>
                      </CardText>
                      <CardText className="col-sm-6">
                        <p>
                          <span className="pov123Text">Mobile:</span>
                          {item?.phone}
                        </p>
                      </CardText>
                      <CardText className="col-sm-6">
                        <p>
                          <span className="pov123Text">Purpush:</span>
                          {item?.details_arbitrator?.purpose_of_registration}
                        </p>
                      </CardText>
                      <NavLink to={`/app/admin/arbitrator/details/view/${item?.id}`} className="text-primary">
                        <p
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            marginLeft: "14px",
                          }}
                        >
                          More Details
                        </p>
                      </NavLink>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <div className="d-flex">
                  <button
                    className=" mt-4 mr-5 allow-dis-btn"
                    style={{ backgroundColor: '#28a745', color: '#fff' }}
                    onClick={() => userApprove(item?.id, item?.status)}
                  >
                    Approve
                  </button>
                  <button
                    className=" mt-4 mr-5 allow-dis-btn"
                    style={{ backgroundColor: '#dc3545', color: '#fff' }}
                    onClick={() => userDisApprove(item?.id, item?.status)}
                  >
                    Dis Approve
                  </button>
                </div>
                <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
                  {item?.arbitrator_documents && item.arbitrator_documents.length > 0 && (
                    item.arbitrator_documents.map((doc, index) => {
                      const documentNames = doc.document_name ? doc.document_name.split(",") : [];
                      return doc?.documents?.map((document, docIndex) => (
                        <button
                          key={`${index}-${docIndex}`}
                          className="download-all-btn mt-4 d-flex align-items-center"
                          onClick={() => downloadFile(document)}
                          style={{
                            fontSize: "16px",
                            cursor: "pointer",
                            padding: "4px 8px",
                            backgroundColor: "#4CAF50",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <i className="zmdi zmdi-eye"></i>
                          {documentNames[docIndex]?.trim() || `Document ${docIndex + 1}`}
                        </button>
                      ))
                    })
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    });

  return (
    <section className="Sales_analytics mr-4 mt-2 mt-3">
      <div fluid>
        <div className="revenueTable-conatiner mt-4">
          <div className="revenueTable-pagination-box">
            <div className="pgination-1st">
              {" "}
              <span className="check-po-icon" onClick={refresh}>
                <i class="zmdi zmdi-refresh-alt"></i>
              </span>
            </div>
            <div className="pagination-conatinerw">
              <div className="d-flex pagination-text justify-content-end">
                <span className="pto-5">Show</span>
                <select
                  className="form-select page-select12"
                  aria-label="Default select example"
                  onChange={(event) => setLimit(event.target.value)}
                  value={limit || (list?.length > 10 ? "20" : list?.length)}
                >
                  {list?.length >= 10 && <option value="20">20</option>}
                  {list?.length >= 50 && <option value="50">50</option>}
                  {list?.length >= 100 && <option value="100">100</option>}
                  {list?.length >= 200 && <option value="200">200</option>}
                  {list?.length >= 500 && <option value="500">500</option>}
                  <option value={list?.length}>
                    {list === 0 ? 0 : list?.length}
                  </option>
                </select>
                <span className="pto-5">
                  {" "}
                  per page from {list && list?.length} results{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="revenue-scroll-container">
            {loading === false && list && list?.length === 0 && (
              <div className="n--data text-center m-5">No data Found!</div>
            )}
            {loading === true && (
              <div className="n--data text-center m-5">
                <Loading />
              </div>
            )}
            {cataloge}</div>
        </div>
      </div>
    </section>
  );
};

export default Revenuetable;