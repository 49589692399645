import React, { useState, useEffect } from "react";
import {
  ApiUrl,
  Axios,
  Link,
  Row,
  moment,
  toast
} from "../../components";

import { GET_BULK_NOTIFICATIONS, BULK_NOTIFICATIONS_DELETE } from "../../ApiUrl";

import { getNotificationsSuccess } from "../../actions/Auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const Notifications = (props) => {
  const role = localStorage.getItem("role");
  const [notificationsMessage, setNotificationsMessage] = useState([]);

  const fetchNotifications = async () => {
    try {
      const response = await Axios.get(GET_BULK_NOTIFICATIONS);
      const messages = response.data.data
        .map(notification => ({
          id: notification.id,
          notifiableType: notification.notifiable_type,
          text: notification.data,
          createdAt: notification.created_at,
        }))
        .slice(0, 15);
      setNotificationsMessage(messages);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleDelete = (id) => {
    const payload = {
      notification_ids: [id],
    };
    Axios.post(BULK_NOTIFICATIONS_DELETE, payload)
      .then((res) => {
        toast.success(res.data.message);
        setNotificationsMessage((prevMessages) =>
          prevMessages.filter((notification) => notification.id !== id)
        );
      })
      .catch((err) => {
        toast.error("Failed to delete notification!");
      });
  };

  return (
    <div className="notif-cont-family">
      <div className="notif-h-cont">
        <span className="">Notifications</span>
        <span className="float-right" onClick={props.openNotification}>
          <i class="zmdi zmdi-close"></i>
        </span>
      </div>

      <div className="notif-welcome-cont">
        <Row className="ml-4">
          {notificationsMessage.length > 0 ? (
            notificationsMessage.map(({ id, notifiableType, text, createdAt }) => (
              <div key={id} className="flex flex-col border-b p-2 w-full">
                <p className="font-semibold text-gray-700">{notifiableType}</p>
                <p className="text-gray-600">{text}</p>
                <p>
                  <span className="pov123Text">Created On:</span>
                  {moment.utc(createdAt).tz("Asia/Kolkata").format("DD.MM.YYYY hh:mm A")}
                </p>
                <p
                  className="cursor-pointer text-red-500 mt-2 self-end"
                  onClick={() => handleDelete(id)}
                >
                  <i className="zmdi zmdi-delete"></i>
                </p>
              </div>
            ))
          ) : (
            <div className="no-notifications">
              No notifications found!
            </div>
          )}
        </Row>
      </div>

      <div>
        <div className="notif-grid-cont">
          {/* {(role === "LawFirm" || role === "Mediator") &&
        <>
          <span className="notif-grid-cont-span">
            <i class="zmdi zmdi-notifications-none mr-1"></i>
            <span className="notif-grid-count"> 1 </span> Approvals
          </span>
          <span className="notif-grid-cont-span">
            <i class="zmdi zmdi-notifications-none mr-1"></i>
           <span className="notif-grid-count"> 1</span> User Approvals
          </span>
          </>
        } */}
          {/* { role === "casemanager" && 
          <span className="notif-grid-cont-span">
            <i class="zmdi zmdi-notifications-none mr-1"></i>
           <span className="notif-grid-count"> 1</span>
            Follow Up
          </span>
        } */}
          <span className="notif-grid-cont-span" onClick={props.toggel}>

            <Link to="/app/lawfirm/notifications/">
              {/* <i class="zmdi zmdi-notifications-none mr-1"></i> */}
              {/* {props.count && 
            <span className="notif-grid-count">{props.count}</span> 
            } */}
              {/* Notes */}
              Show More
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

// export default Notifications;

const mapStateToProps = ({ auth }) => {
  console.log(auth, "auth90890");
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

// export default connect(mapStateToProps, {
//   notesNotifications,
// })(Notifications);

// const mapStateToProps = (state) => ( console.log(state,"staestae"),{
//   notifications: state.notification.notifications,
// });

// const mapDispatchToProps = (dispatch) => (
//   console.log(dispatch,"dispatchdispatch"),{
//   notesNotifications: () => dispatch(getNotifications()),
// });

export default connect(mapStateToProps, getNotificationsSuccess)(withRouter(Notifications));