import React, { Fragment } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  withRouter,
  Row,
  Col,
  Axios,
  TEXTFIELD,
  Link,
  PASSWORDFIELD,
  SELECT,
  SUBMIT,
  RESET,
  ApiUrl,
  checkValidations,
  checkError,
  FILEUPLOAD,
  Loading,
  ImagePreview,
} from "../components";

import Header from "../components/Common/main-dashboard/header";
import Footer from "../components/Common/main-dashboard/footer";

const {
  API_ADVOCATE_REGISTER,
  EMAIL_REGEX,
  Numeric,
  PASS_VALIDATION_MSG,
  PASS_REGEX,
  API_SEND_OTP,
  API_VERIFY_OTP,
} = ApiUrl;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.barLicenseRef = React.createRef();
    this.atorneyRef = React.createRef();
    this.state = {
      editId: this.props.editId,
      process: this.props.role,
      name: "",
      password: "",
      password_confirmation: "",
      email: "",
      phone: "",
      enrollment_number: "",
      case_number: "",
      gender: "",
      city: "",
      state: "",
      pin_code: "",
      bar_license: "",
      atorney: "",
      address: "",
      status: "Active",
      otp: "",
      showOtpField: false,
      otpSent: false,
      otpVerified: false,
      verifiedPhone: "",
      errors: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        enrollment_number: "",
        case_number: "",
        gender: "",
        city: "",
        state: "",
        pin_code: "",
        bar_license: "",
        atorney: "",
        address: "",
        otp: "",
      },
      disabled: true,
      loading: false,
    };
  }

  componentDidMount() {
    const { editId, process } = this.state;
  }

  handleFileBarLicense = (event) => {
    const { name } = event.target;
    let errors = this.state.errors;
    let file = event.target.files[0];
    if (file) {
      if (!file.name.match(/\.pdf$/)) {
        errors[name] = " File format is not valid. Only PDF files are allowed.";
      } else if (file.size > 5 * 1024 * 1024) {
        errors[name] = "File size should not exceed 5MB.";
      } else {
        errors[name] = "";
      }
    } else {
      errors[name] = "Please select a file.";
    }
    this.setState({ [name]: file, errors }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
    console.log(file, "file");
  };

  handleFileAtorney = (event) => {
    const { name } = event.target;
    let errors = this.state.errors;
    let file = event.target.files[0];
    if (file) {
      if (!file.name.match(/\.pdf$/)) {
        errors[name] = " File format is not valid. Only PDF files are allowed.";
      } else if (file.size > 5 * 1024 * 1024) {
        errors[name] = "File size should not exceed 5MB.";
      } else {
        errors[name] = "";
      }
    } else {
      errors[name] = "Please select a file.";
    }
    this.setState({ [name]: file, errors }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
    console.log(file, "file");
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = { ...this.state.errors };
    let finalVal = value;

    switch (name) {
      case "name":
        errors.name = value.length < 1 ? "Required" : "";
        break;
      case "address":
        errors.address = value.length < 1 ? "Required" : "";
        break;
      case "email": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else if (!EMAIL_REGEX.test(value)) {
          err = "Invalid email";
        }
        errors.email = err;
        break;
      }
      case "pin_code": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else if (value.length !== 6 || !/^\d+$/.test(value)) {
          err = "Invalid pin code. Must be 6 digits.";
        }
        errors.pin_code = err;
        break;
      }
      // case "phone": {
      //   let err = "";
      //   if (value.length < 1) {
      //     err = "Required";
      //   } else if (!Numeric.test(value)) {
      //     err = "Only numeric allowed";
      //     finalVal = value.replace(/\D/g, '');
      //   } else if (value.length > 10) {
      //     finalVal = value.slice(0, 10);
      //   }
      //   errors.phone = err;
      //   break;
      // }
      case "phone": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else if (!Numeric.test(value)) {
          err = "Only numeric allowed";
          finalVal = value.replace(/\D/g, '');
        } else if (value.length > 10) {
          finalVal = value.slice(0, 10);
        }
        errors.phone = err;
        if (this.state.otpSent || this.state.otpVerified) {
          if (value !== this.state.verifiedPhone && value !== this.state.phone) {
            this.setState({
              showOtpField: false,
              otpSent: false,
              otpVerified: false,
              verifiedPhone: "",
              otp: "",
            });
          }
        }
        break;
      }
      case "password": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else if (!PASS_REGEX.test(value)) {
          err = "Invalid format";
        }
        errors.password = err;
        break;
      }
      case "password_confirmation": {
        let err = "";
        if (value.length < 1) {
          err = "Required";
        } else if (value !== this.state.password) {
          err = "Passwords do not match";
        }
        errors.password_confirmation = err;
        break;
      }
      case "otp": {
        errors.otp = value.length < 1 ? "Required" : "";
        break;
      }
      default:
        break;
    }
    this.setState({
      errors,
      [name]: finalVal
    }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  handleSendOtp = () => {
    const { phone, errors } = this.state;
    if (errors.phone || phone.length !== 10) {
      toast.error("Please enter a valid 10-digit mobile number");
      return;
    }
    this.setState({ loading: true });
    let data = { phone };
    Axios.Post(API_SEND_OTP, data)
      .then((res) => {
        if (res.data.success) {
          toast.success("OTP sent successfully!");
          this.setState({
            showOtpField: true,
            otpSent: true,
            loading: false
          });
        } else {
          let errorMessage = "";
          if (typeof res.data.error === "string") {
            errorMessage = res.data.error;
          } else if (typeof res.data.error === "object") {
            errorMessage = Object.values(res.data.error).flat().join(' ') || 'Something went wrong';
          } else {
            errorMessage = "Something went wrong";
          }
          toast.error(errorMessage);
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        toast.error("Failed to send OTP. Please try again.");
        this.setState({ loading: false });
      });
  };

  handleVerifyOtp = () => {
    const { otp, phone, errors } = this.state;
    if (errors.otp || !otp) {
      toast.error("Please enter a valid OTP");
      return;
    }
    this.setState({ loading: true });
    let data = { phone, otp };
    Axios.Post(API_VERIFY_OTP, data)
      .then((res) => {
        if (res.data.success) {
          toast.success("OTP verified successfully!");
          this.setState({
            otpVerified: true,
            verifiedPhone: phone,
            loading: false,
            showOtpField: false,
            otp: ""
          });
        } else {
          toast.error("Invalid OTP");
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        toast.error("OTP verification failed. Please try again.");
        this.setState({ loading: false });
      });
  };

  redirectPage = () => {
    this.props.history.push("/signin");
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const {
      name,
      editId,
      process,
      errors,
      role,
      email,
      address,
      password,
      password_confirmation,
      phone,
      enrollment_number,
      case_number,
      gender,
      city,
      state,
      pin_code,
      bar_license,
      atorney,
      status,
    } = this.state;

    let err = checkError(errors);
    if (err.disabled === true) {
      toast.error(err.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    let data = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      enrollment_number: enrollment_number,
      case_number: case_number,
      gender: gender,
      city: city,
      state: state,
      pin_code: pin_code,
      password: password,
      confirm_password: password_confirmation,
      Bar_Council_license: bar_license,
      Power_of_Attorney: atorney
    };

    if (password) {
      data.password = password;
    }

    if (password_confirmation) {
      data.password_confirmation = password_confirmation;
    }

    let valid = checkValidations(data);

    if (valid.error) {
      const errorMessage = valid.error.replace(/,/g, ", ");
      toast.error(errorMessage);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    data = valid.data;
    let url = API_ADVOCATE_REGISTER;
    if (process == "edit") {
      data.userId = editId;
      url = API_EDIT_ADMIN;
    }

    let formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    if (bar_license && bar_license instanceof File) {
      formData.append("bar_license", bar_license);
    }

    if (atorney && atorney instanceof File) {
      formData.append("atorney", atorney);
    }

    Axios.Post(url, formData)
      .then((res) => {
        let response = res.data;
        if (res.status === 422) {
          toast.error(res.data.error, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loading: false, disabled: false });
        } else if (response.sucess) {
          toast.success("Registration successful!");
          this.setState({ loading: false, disabled: false });
          setTimeout(() => {
            this.redirectPage();
          }, 1000);
        } else if (response.error) {
          toast.error(response.errors.phone[0], {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loading: false, disabled: false });
        } else {
          toast.error("Registration failed. Please try again.");
          this.setState({ loading: false, disabled: false });
        }
      })
      .catch((error) => {
        let res = error.response;
        console.log(error, res?.message || "An error occurred");
        toast.error("An error occurred. Please try again.");
        this.setState({ loading: false, disabled: false });
      });
  };

  reset = () => {
    this.setState({
      name: "",
      password: "",
      password_confirmation: "",
      email: "",
      phone: "",
      enrollment_number: "",
      case_number: "",
      gender: "",
      city: "",
      state: "",
      pin_code: "",
      bar_license: "",
      atorney: "",
      address: "",
      otp: "",
      showOtpField: false,
      otpSent: false,
      otpVerified: false,
      verifiedPhone: "",
      errors: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        enrollment_number: "",
        case_number: "",
        gender: "",
        city: "",
        state: "",
        pin_code: "",
        bar_license: "",
        atorney: "",
        address: "",
        otp: "",
      },
      disabled: true,
    });
    if (this.barLicenseRef.current) {
      this.barLicenseRef.current.value = "";
    }
    if (this.atorneyRef.current) {
      this.atorneyRef.current.value = "";
    }
  };

  render() {
    const {
      errors,
      name,
      email,
      password,
      password_confirmation,
      enrollment_number,
      case_number,
      gender,
      city,
      state,
      pin_code,
      bar_license,
      atorney,
      address,
      phone,
      disabled,
      process,
      loading,
      otpShow,
      otp,
      verifyOtp,
      successMessage,
      showModal,
      mobileValidated,
      showOtpField,
      otpVerified,
      verifiedPhone,
    } = this.state;

    return (
      <>
        <Header />
        <ToastContainer style={{ top: "5em" }} />
        <div style={{ paddingTop: "17vh" }}></div>
        <Fragment>
          {loading && (
            <div className="text-center w-100">
              <Loading />
            </div>
          )}
          <div className="w-100 app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content w-100">
              <div className="app-login-content w-100">
                <div className="">
                  <h1>Sign Up as Advocate</h1>
                </div>
                <div className="app-login-forma">
                  <form onSubmit={this.handleSubmit} noValidate>
                    <Row>
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="Name"
                          value={name}
                          name={"name"}
                          onChange={this.handleChange}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="Email"
                          value={email}
                          name={"email"}
                          onChange={this.handleChange}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="Mobile No."
                          name="phone"
                          onChange={this.handleChange}
                          value={phone}
                          errors={errors}
                          maxLength="10"
                          disabled={otpVerified && phone === verifiedPhone}
                        />
                        {phone.length === 10 && !showOtpField && !otpVerified && (
                          <button
                            type="button"
                            className="btn btn-primary mt-2"
                            onClick={this.handleSendOtp}
                            disabled={loading}
                          >
                            Send OTP
                          </button>
                        )}
                        {otpVerified && phone === verifiedPhone && (
                          <span className="text-success mt-2 d-block">✓ Verified</span>
                        )}
                      </Col>
                      {showOtpField && phone !== verifiedPhone && (
                        <Col md="6" xs="12">
                          <TEXTFIELD
                            label="Enter OTP"
                            name="otp"
                            onChange={this.handleChange}
                            value={otp}
                            errors={errors}
                          />
                          <button
                            type="button"
                            className="btn btn-success mt-2"
                            onClick={this.handleVerifyOtp}
                            disabled={loading}
                          >
                            Verify OTP
                          </button>
                        </Col>
                      )}
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="Address"
                          value={address}
                          name={"address"}
                          onChange={this.handleChange}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="City"
                          value={city}
                          name={"city"}
                          onChange={this.handleChange}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="Pin Code"
                          value={pin_code}
                          name={"pin_code"}
                          onChange={this.handleChange}
                          errors={errors}
                          maxLength="6"
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="State"
                          value={state}
                          name={"state"}
                          onChange={this.handleChange}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12" className="mt-3">
                        <SELECT
                          label="Gender"
                          value={gender}
                          name={"gender"}
                          onChange={this.handleChange}
                          errors={errors}
                          data={[{ 1: "Male" }, { 0: "Female" }]}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="Enrolment No."
                          value={enrollment_number}
                          name={"enrollment_number"}
                          onChange={this.handleChange}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <TEXTFIELD
                          label="Case No."
                          value={case_number}
                          name={"case_number"}
                          onChange={this.handleChange}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <PASSWORDFIELD
                          label="Password"
                          value={password}
                          name={"password"}
                          edit={process == "edit" ? true : false}
                          onChange={this.handleChange}
                          note={PASS_VALIDATION_MSG}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <PASSWORDFIELD
                          label="Confirm Password"
                          value={password_confirmation}
                          name={"password_confirmation"}
                          edit={process == "edit" ? true : false}
                          onChange={this.handleChange}
                          note={PASS_VALIDATION_MSG}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <FILEUPLOAD
                          ref={this.barLicenseRef}
                          label="Upload Bar Council License"
                          value={bar_license}
                          name={"bar_license"}
                          onChange={this.handleFileBarLicense}
                          errors={errors}
                          accept="application/pdf"
                        />
                      </Col>
                      <Col md="6" xs="12">
                        <FILEUPLOAD
                          ref={this.atorneyRef}
                          label="Upload Vakalatnama/POA"
                          value={atorney}
                          name={"atorney"}
                          onChange={this.handleFileAtorney}
                          errors={errors}
                          accept="application/pdf"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4" xs="12">
                        <SUBMIT
                          title="Submit"
                          disabled={disabled || !otpVerified}
                        />
                      </Col>
                      <Col md="4" xs="12">
                        <RESET onClick={this.reset} />
                      </Col>
                    </Row>
                    <Link to="/signin">Already have an account</Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
        <div style={{ paddingTop: "17vh" }}></div>
        <Footer />
      </>
    );
  }
}

export default withRouter(SignUp);