import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER,
} from "../../constants/ActionTypes";
// import SearchBox from "components/SearchBox";
// import MailNotification from "../MailNotification/index";
// import AppNotification from "../AppNotification/index";
// import CardHeader from "components/dashboard/Common/CardHeader/index";
import { switchLanguage, toggleCollapsedNav } from "../../actions/Setting";
// import IntlMessages from "util/IntlMessages";
// import LanguageSwitcher from "components/LanguageSwitcher/index";
// import Menu from "../../components/TopNav/Menu";
import UserInfoPopup from "../../components/UserInfo/UserInfoPopup";
import UserInfo from "../../components/UserInfo";
import axios from 'axios';
import { ApiUrl } from "../../components";
import eventEmitter from "../../../src/util/emitter";

const { API_URL, API_VERSION, API_SET_APP_NAME_LOGO } = ApiUrl;

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: "",
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      appName: '',
      appLogo: '',
      descRiption: '',
    };
  }

  componentDidMount() {
    eventEmitter.on("appDataUpdated", this.handleAppDataUpdated);
    this.fetchAppData();
  }

  componentWillUnmount() {
    eventEmitter.removeListener("appDataUpdated", this.handleAppDataUpdated);
  }

  handleAppDataUpdated = (updatedAppName, updatedAppLogo) => {
    console.log("Received updated app data: 2 ", updatedAppName, updatedAppLogo);
    this.setState({ appName: updatedAppName, appLogo: updatedAppLogo });
    this.fetchAppData();
  };

  fetchAppData = async () => {
    const role = localStorage.getItem("role");
    const apiUrl = `${API_URL}${API_VERSION}${API_SET_APP_NAME_LOGO}?role=${role}`;
    try {
      const response = await axios.get(apiUrl);
      if (response.data.success) {
        const { app_name, app_logo, description } = response.data.data;
        this.setState({ appName: app_name, appLogo: app_logo, descRiption: description });
      }
    } catch (error) {
      console.error("Error fetching app data:", error);
    }
  };

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification,
    });
  };

  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification,
    });
  };

  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget,
    });
  };

  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox,
    });
  };

  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps,
    });
  };

  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo,
    });
  };

  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false,
    });
  };

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  render() {
    const role = localStorage.getItem("role");
    const lawMediation = localStorage.getItem("lawMediation")

    const {
      drawerType,
      locale,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;

    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-block d-xl-none"
      : drawerType.includes(COLLAPSED_DRAWER)
        ? "d-block"
        : "d-none";

    return (

      <AppBar
        className={`${lawMediation === "true" || lawMediation === null ? "app-main-header custom-color" : "law-mediation-header custom-color"}  ${navigationStyle === HORIZONTAL_NAVIGATION &&
          horizontalNavPosition === BELOW_THE_HEADER
          ? "app-main-header-top"
          : ""
          }`}
      >
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div
              className="d-block d-md-none pointer mr-3"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
          ) : (
            <>
              <IconButton
                className={`jr-menu-icon mr-3 ${drawerStyle}`}
                aria-label="Menu"
                onClick={this.onToggleCollapsedNav}
              >
                <span className="menu-icon" />
              </IconButton>

              <div class="header-title d-flex align-items-center">
               
                <img
                  src={this.state.appLogo}
                  alt="logo"
                  className="mobile-logo"
                />
                <span className="app-name"> {this.state.appName} </span>

                <h3> {this.state.descRiption} </h3>

              </div>
            </>
          )}
          <UserInfo />

          {/* {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === INSIDE_THE_HEADER && <Menu />} */}

          <ul className="header-notifications list-inline ml-auto">
            {navigationStyle === HORIZONTAL_NAVIGATION && (
              <li className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.userInfo}
                  toggle={this.onUserInfoSelect.bind(this)}
                >
                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                  >
                    <IconButton className="icon-btn size-30">
                      <Avatar
                        alt="..."
                        src={"https://via.placeholder.com/150x150"}
                        className="size-30"
                      />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup />
                  </DropdownMenu>
                </Dropdown>
              </li>
            )}
          </ul>

          <div className="ellipse-shape"></div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  // console.log(auth, "auth12");
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
  } = settings;
  return { drawerType, locale, navigationStyle, horizontalNavPosition };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, switchLanguage })(Header)
);