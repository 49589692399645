import React, { Fragment, useState } from "react";
import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
} from "../../../../../../components";
const { API_UPLOAD_TESTING, API_EDIT_TEMPLATE } = ApiUrl;

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.editId,
      templateId: this.props.templateId,
      // id: 33,
      name: "",
      description: "",
      file: [],
      document_name: "",
      editid: "",
      errors: {
        name: "",
        description: "",
        file: {},
        document_name: "",
      },
      process: this.props.role,
      loading: false,
    };
  }

  async componentDidMount() {
    console.log(this.props.templateId, "stateid");
  }

  handleChange = (event) => {
    console.log(event, "asdasdasdasd");
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let finalVal = value;

    switch (name) {
      case "file":
        errors.file = value.length < 1 ? "Required" : "";
        break;
      case "document_name":
        errors.document_name = value.length < 1 ? "Required" : "";
        break;

      case "respondent":
        errors.respondent = value.length < 1 ? "Required" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };
  onFormSubmit = (e) => {
    e.preventDefault();
    const { process, file } = this.state;

    this.setState({ loading: true });
    const data = {
      file: file,
    };

    // check validations
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false });
      return false;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    console.log(data, valid.data);
    let url = API_UPLOAD_TESTING;
    if (process == "edit") {
      url = API_EDIT_TEMPLATE;
    }

    Axios.Post(url, formData)
      .then((res) => {
        let response = res.data;

        if (res.data.success == true) {
          // this.setState({ loading: false });
          toast.success(response.message);
          this.setState({ fileName: "", document_name: "" });
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((error) => {
        // toast.error(error);
      });
    this.setState({ loading: false });
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };
    console.log(file, "filefile");

    this.setState(
      {
        file: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ fileName: file.name });
  };

  render() {
    const { loading, document_name, fileName, disabled, errors } = this.state;

    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}

        <form
          noValidate
          autoComplete="off"
          onSubmit={this.onFormSubmit.bind(this)}
          style={{ padding: "20px" }}
        >
          <Row>
            {/* <Col md="4">
              <TEXTFIELD
                label="Document Name"
                value={document_name}
                name={"document_name"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col> */}

            <Col md="6" xs="12" className="mt-3">
              <div class="file-upload-cus mb-2">
                <div class="file-select">
                  <div class="file-select-button" id="fileName">
                    Choose File
                  </div>
                  <div class="file-select-name" id="noFile">
                    {fileName ? fileName : "No file chosen..."}
                  </div>
                  <div class="importcus">
                    <input
                      type="file"
                      name={"file"}
                      id="chooseFile"
                      accept=".xlsx , .xls , .csv"
                      onChange={this.handleFile}
                    />
                  </div>
                </div>
              </div>
              <a
                href="https://justsettle.in/backend/storage/templates/Update-case-template.xlsx"
                className="download-templbrtn"
              >
                Download Template For Reference
              </a>
            </Col>

            <Col md="2" className="mt-3">
              <SUBMIT title="Upload" disabled={disabled} />
            </Col>
          </Row>
        </form>
      </Fragment>
    );
  }
}

export default withRouter(AddEditForm);
