import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Checkbox from "../../../../common/checkbox";
import { FaEdit, FaTrashAlt, FaMapMarkedAlt, FaEllipsisV } from 'react-icons/fa';

import {
  Link,
  ApiUrl,
  Axios,
  toast,
  Loading,
  moment,
} from "../../../../../../../components";

import ArbitList from "../arbitlist";

const { API_GET_ADMIN_ALL_CASE, DELETE_RESPONDENT_BY_ADMIN } = ApiUrl;

const Revenuetable = (props) => {
  const [showNoticeA, setShowNoticeA] = useState(false);
  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showVenue, setVenue] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();
  const [responseData, setResponseData] = useState([]);
  const [trackerData, settrackerData] = useState([]);
  const [showRespondentModal, setShowRespondentModal] = useState(false);
  const [respondentNames, setRespondentNames] = useState([]);
  const [selectedRespondent, setSelectedRespondent] = useState(null);
  const [dropdownState, setDropdownState] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleClicked = (e, id) => {
    if (e.target.checked) {
      setIsCheck([...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const [detail, setDetail] = useState({
    name: "",
    email: "",
    clientId: "",
  });

  const handleCancelA = () => {
    setShowNoticeA(false);
  };

  const handlePreviewA = () => {
    setShowNoticeA(true);
  };

  const showErrorN = () => {
    toast.error("Please select checkbox first");
  };

  useEffect(() => {
    getTableRecords(currentPage);
  }, [currentPage]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const getTableRecords = async (page) => {
    setLoading(true);
    const requestData = {
      case_status: "approved",
      page: page,
      per_page: limit,
    };
    Axios.post(API_GET_ADMIN_ALL_CASE, requestData)
      .then((response) => {
        const resData = response.data.data;
        if (response.status === 200) {
          const updatedList = resData.data.map((item) => {
            const arbitrator = item?.case_master_users?.find(
              (user) => user?.related_user_role === "Arbitrator"
            );
            return {
              ...item,
              arbitratorName: arbitrator?.related_user?.name || "No Arbitrator Assigned",
            };
          });
          setList(updatedList);
          setTotalRecords(resData.total);
          setLoading(false);
          setDropdownState(null);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setDropdownState(null);
    } else if (direction === "next" && currentPage < Math.ceil(totalRecords / limit)) {
      setCurrentPage(currentPage + 1);
      setDropdownState(null);
    }
  };

  const handleRespondentSelect = (respondent) => {
    setSelectedRespondent(respondent);
    setShowRespondentModal(false);
    props.history.push(`/app/client/admin/edit-respondent/${respondent.id}`);
  };

  const refresh = () => {
    getTableRecords(currentPage);
    setCount();
    setResponseData([]);
    settrackerData([]);
  };

  const toggleRev1 = (index) => {
    setVenue((prev) => (prev === index ? null : index));
  };

  const cataloge =
    list &&
    list?.length > 0 &&
    list.slice(0, limit).map((item, index) => {
      const partyRespondentVs = item.case_master_users.find(
        (i) =>
          i.related_user_role === "Client" && i.related_user_type === "claimant"
      );
      const respondentNameVs = partyRespondentVs
        ? partyRespondentVs.related_user.name
        : null;

      const respondentNames = item.case_master_users.reduce((acc, i) => {
        if (
          i.related_user_role === "Party" &&
          i.related_user_type === "respondent"
        ) {
          acc.push(i.related_user.name);
        }
        return acc;
      }, []);

      const respondents = item.case_master_users.reduce((acc, i) => {
        if (
          i.related_user_role === "Party" &&
          i.related_user_type === "respondent"
        ) {
          acc.push({ id: i.related_user_id, name: i.related_user.name });
        }
        return acc;
      }, []);

      const handleDropdownToggle = (index) => {
        setDropdownState(dropdownState === index ? null : index);
      };

      const handleEdit = (respondentList) => {
        if (respondentList.length > 1) {
          setRespondentNames(respondentList);
          setShowRespondentModal(true);
          setDropdownState(null);
        } else if (respondentList.length === 1) {
          props.history.push(`/app/client/admin/edit-respondent/${respondentList[0].id}`);
        }
      };

      const handleEditVenue = (hashId) => {
        props.history.push(`/app/client/admin/edit-venue/${hashId}`);
      };

      const handleDelete = async (respondentId) => {
        try {
          setDisabled(true);
          const response = await Axios.delete(`${DELETE_RESPONDENT_BY_ADMIN}/${respondentId}`);

          if (response.status === 200) {
            toast.success("Case deleted successfully");
            const updatedList = list.filter((item) => item.id !== respondentId);
            setList(updatedList);
            setDropdownState(null);
          } else {
            toast.error("Error deleting case");
          }
        } catch (err) {
          const errorMessage = err.response?.data?.message || "Error deleting case";
          toast.error(errorMessage);
        } finally {
          setDisabled(false);
          setDropdownState(null);
        }
      };

      return (
        <div className="timline-content">
          <div class="px-1 time-name d-flex justify-content-between">
            <div class="d-flex">
              <span className="mt-1">
                <Checkbox
                  key={item.id}
                  type="checkbox"
                  name={item.label}
                  id={item.id}
                  handleClick={(e) => handleClicked(e, item.id)}
                  isChecked={isCheck.includes(item.id)}
                />
              </span>

              <h4>
                {" "}
                <Link
                  to={`/app/client/admin/case-detail/${item.hash_id}`}
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Case No."
                  className="d-flex"
                >
                  {" "}
                  <div className="ml-2 text-capitalize">
                    <span>{respondentNameVs} vs </span>
                    <span>
                      {respondentNames?.length === 1
                        ? respondentNames
                        : respondentNames?.length === 2
                          ? respondentNames[0] + " & another"
                          : respondentNames?.length === 3 ||
                            respondentNames?.length > 3
                            ? respondentNames[0] + " & others"
                            : ""}
                    </span>
                  </div>
                </Link>
              </h4>
            </div>

            <div className="button-dropdown-content" key={item.id}>
              <div className="px-1 time-name d-flex justify-content-between">
                <div className="dropdown">
                  <Button
                    className="action-btn m-0 px-5"
                    onClick={() => handleDropdownToggle(index)}
                  >
                    Action
                    <FaEllipsisV className="mr-2" />
                  </Button>
                  {dropdownState === index && (
                    <div className="dropdown-menu show  dropdown-shw">
                      <button
                        className="dropdown-item"
                        onClick={() => handleEdit(respondents)}
                      >
                        <FaEdit className="mr-2" />
                        Edit Respondent
                      </button>

                      <button
                        className="dropdown-item"
                        onClick={() => handleEditVenue(item.hash_id)}
                      >
                        <FaMapMarkedAlt className="mr-2" />
                        Edit Venue
                      </button>

                      <button
                        className="dropdown-item"
                        onClick={() => handleDelete(item.id)}
                        disabled={disabled}
                      >
                        <FaTrashAlt className="mr-2" />
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Link to={`/app/client/admin/case-detail/${item?.hash_id}`}>
            <div className="filesrevenue-conatiner">
              <div className="filesrevenue-subconatiner">
                <div className="pov123 mt-3">
                  <p>
                    <span className="pov123Text">Case No.</span>
                    {item.case_number}
                  </p>
                  <p>
                    <span className="pov123Text">Filed On:</span>
                    {moment(item.created_at).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
              <div className="filesrevenue-subconatinerpov">
                <p className="checkbox-revenue-subtext mt-3">
                  <span className="revenue-status-14 font-bold">
                    Current Arbitrator: {item?.arbitratorName}
                  </span>
                </p>
              </div>
            </div>
          </Link>

          {showVenue === index ? (
            <div onClick={() => toggleRev1(index)}>
              <h2 class="hr-lines">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7.32349L7.32352 1.99996L12.6667 7.34311"
                      stroke="#42526E"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </h2>
            </div>
          ) : (
            <div onClick={() => toggleRev1(index)} className="text-center">
              <span className="arrow-conainer">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.34314L7.34317 12.6667L2.00002 7.32352"
                    stroke="#42526E"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}

          {showVenue === index &&
            item.metas
              .filter(m => !["section_17"].includes(m.meta_key))
              .map((m) => {
                return (
                  <div key={m.meta_key} className="revenue-state">
                    <div className="revenue-state-box">
                      <p className="text-court text-capitalize">
                        {m.meta_key}:{" "}
                      </p>
                      <p className="text-state text-capitalize">
                        {" "}
                        {m.meta_value}{" "}
                      </p>
                    </div>
                  </div>
                );
              })}
        </div>
      );
    });

  return (
    <section className="Sales_analytics">
      <div className="revenueTable-conatiner mt-3">
        <div className="revenueTable-pagination-box">
          <div className="pgination-1st">
            {" "}
            <span className="check-po-icon">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={handleSelectAll}
                  isChecked={isCheckAll}
                />
              </div>
            </span>

            <span className="check-po-icon" onClick={refresh}>
              <i class="zmdi zmdi-refresh-alt"></i>
            </span>

          </div>

          <div className="d-flex pagination-text justify-content-end">
            <div className="box-rev-123">
              <button
                className="gnrtarbit123 scrol-btn-genrte"
                onClick={isCheck == "" ? showErrorN : handlePreviewA}
              >
                Assign Arbitrator
              </button>
            </div>
          </div>
        </div>

        {loading === true && (
          <div className="n--data text-center m-5">
            <Loading />
          </div>
        )}

        <div className="revenue-scroll-container">{cataloge}</div>

        <div className="pagination-container d-flex justify-content-end align-items-center mt-3">
          <span>
            {limit * (currentPage - 1) + 1}-
            {Math.min(limit * currentPage, totalRecords)} of {totalRecords}
          </span>
          <button
            className="btn btn-link ml-2"
            onClick={() => handlePageChange("prev")}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          <button
            className="btn btn-link"
            onClick={() => handlePageChange("next")}
            disabled={currentPage >= Math.ceil(totalRecords / limit)}
          >
            &gt;
          </button>
        </div>
      </div>

      <Modal isOpen={showNoticeA} toggle={handleCancelA}>
        <p className="text-right m-0">
          <span onClick={handleCancelA} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <>
            <div className="w-100">
              <ArbitList
                id={props.editId}
                casids={isCheck}
                detail={detail}
                handleCancelA={handleCancelA}
              />
            </div>
          </>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showRespondentModal}
        toggle={() => setShowRespondentModal(!showRespondentModal)}
      >
        <ModalBody>
          <h5>Select a Respondent Want to Edit</h5>
          <ul>
            {respondentNames.map((respondent) => (
              <li key={respondent.id}>
                <button
                  className="btn btn-link"
                  onClick={() => handleRespondentSelect(respondent)}
                >
                  {respondent.name}
                </button>
              </li>
            ))}
          </ul>
        </ModalBody>
      </Modal>

    </section>
  );
};

export default Revenuetable;