import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import {
  ApiUrl,
  Axios,
  moment,
  toast,
  Loading
} from "../../../../../../../../src/components/index";

import 'react-toastify/dist/ReactToastify.css';
import { API_URL, API_VERSION } from "../../../../../../../ApiUrl";
const {
  RESTORE_BULK_DOCK,
  SHOW_DELETE_DOCUMENTS
} = ApiUrl;

const Trashtab = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showpre, setShowPre] = useState(false);
  const [link, setLink] = useState();
  const docs = [
    {
      uri: link,
    },
  ];

  const fetchDocuments = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await Axios.get(`${SHOW_DELETE_DOCUMENTS}?page=${currentPage}&per_page=${limit}&document_name=${searchTerm}`);
      if (response.data.success) {
        setDocuments(response.data.data.data || []);
        setTotalRecords(response.data.data.total);
      } else {
        setError(response.data.message);
        toast.error(response.data.message);
      }
    } catch (err) {
      setError("No deleted documents found");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [currentPage, limit, searchTerm]);

  const refresh = () => {
    fetchDocuments();
  };

  const handleCancelPre = () => {
    setShowPre(false);
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    } else if (direction === "next" && currentPage < Math.ceil(totalRecords / limit)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedDocuments((prev) =>
      prev.includes(id) ? prev.filter((docId) => docId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedDocuments.length === documents.length || documents.length === 0) {
      setSelectedDocuments([]);
    } else {
      setSelectedDocuments(documents.map((doc) => doc.id));
    }
  };

  const downloadFile = async (id, name) => {
    console.log(id, name, "case id");
    try {
      const url = `${API_URL}${API_VERSION}/get-file/link/${id}`;
      const response = await fetch(url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", name + ".pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const previewFile = async (id) => {
    try {
      const url = `${API_URL}${API_VERSION}/get-file/link/${id}`;
      const response = await fetch(url);
      console.log(response, "response123");
      setLink(response.url);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setShowPre(true);
  };

  const restoreSelectedFiles = async () => {
    if (selectedDocuments.length === 0) {
      toast.warning("No documents selected for restoration.");
      return;
    }
    try {
      setDisabled(true);
      const response = await Axios.post(RESTORE_BULK_DOCK, { media_ids: selectedDocuments });
      if (response.data.success) {
        toast.success("Selected documents restored successfully!");
        setDocuments((prev) => prev.filter((doc) => !selectedDocuments.includes(doc.id)));
        setTotalRecords((prev) => prev - selectedDocuments.length);
        setSelectedDocuments([]);
        fetchDocuments();
      } else {
        toast.error("Failed to restore selected documents.");
      }
    } catch (error) {
      toast.error("Error restoring selected documents.");
      console.error("Bulk Restore Error:", error);
    } finally {
      setDisabled(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSearchInput = () => {
    setShowSearch((prev) => !prev);
  };

  return (
    <>
      <div className="wrapper mt-2" style={{ backgroundColor: 'white' }}>
        <div
          className="ml-4 mr-5 mt-2 listing-main d-md-flex justify-start align-items-center sticky top-0 z-10 bg-gray-800 text-white border border-gray-700 p-1.5 rounded grid grid-cols-2 shadow-lg shadow-black gap-x-2"
          style={{
            position: 'sticky',
            top: '0',
            zIndex: '10',
            backgroundColor: '#FFFFFF',
          }}
        >
          <input
            type="checkbox"
            className="ml-4"
            checked={documents.length > 0 && selectedDocuments.length === documents.length}
            onChange={handleSelectAll}
          />
          <span className="check-po-icon ml-4" onClick={refresh}>
            <i className="zmdi zmdi-refresh-alt"></i>
          </span>

          {showSearch && (
            <div>
              <input
                type="text"
                className="search-input-trash1"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search by Lot name"
              />
              <i
                className="zmdi zmdi-close text-gray-500  cls-btn cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                style={{ fontSize: '20px' }}
                onClick={() => {
                  setSearchTerm("");
                  toggleSearchInput();
                }}
              />
            </div>
          )}

          <span className="ml-2 mr-1 se12 m-0 p-0" style={{ fontSize: '15px', fontWeight: 'bold' }}>Search</span>

          <span className="search56">
            <i
              className="zmdi zmdi-search text-gray-500 m-0 p-o"
              style={{ fontSize: '23px' }}
              onClick={toggleSearchInput}
            />
          </span>

          <button
            className="btn btn-success ml-5 mt-2 mb-2"
            onClick={restoreSelectedFiles}
            disabled={disabled || selectedDocuments.length === 0}
          >
            Restore Selected
          </button>
        </div>

        <div
          className="pt-2 pl-4 pr-4 pb-2 mr-3"
          style={{ maxHeight: 'calc(100vh - 160px)', overflowY: 'auto' }}
        >
          {loading ? (
            <div className="text-center mt-4">
              <Loading />
            </div>
          ) : documents.length > 0 ? (
            <>
              {documents.map(({ id, media, role, deleted_at }) => (
                <div key={id} className="listing-main p-4 mt-3 d-md-flex justify-content-between align-items-center">
                  <input
                    type="checkbox"
                    className="mr-2 mb-1"
                    checked={selectedDocuments.includes(id)}
                    onChange={() => handleCheckboxChange(id)}
                  />
                  <div className="document-d-flex" style={{ flex: 1 }}>
                    <p className="order-name1 mx-2 fixed-doc-tab1 text-capitalize edit-doc-icon-cont">
                      <b>{media?.alt || "--"}</b>
                    </p>
                    <p className="order-name1 fixed-doc-tab1">
                      Uploaded By: <b className="mx-2">{role || "Unknown"}</b>
                    </p>
                    <p className="order-date1 fixed-doc-tab1 edit-doc-icon-cont">
                      <b>{moment(deleted_at).format("DD.MM.YYYY")}</b>
                    </p>

                    <span
                      className="preview-pdf34"
                      onClick={() =>
                        previewFile(media?.hash_id)
                      }
                    >
                      <b> Preview Pdf </b>
                    </span>

                    <p className="order-name1 mx-2">
                      <i
                        className="zmdi zmdi-download"
                        onClick={() =>
                          downloadFile(
                            media?.hash_id,
                            media?.name
                          )
                        }
                        style={{
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="text-center mt-5">No deleted documents found</div>
          )}
          {error && <div className="text-center text-danger">{error}</div>}

          <div className="pagination-container d-flex justify-content-end align-items-center mt-3">
            <span>
              {limit * (currentPage - 1) + 1}-
              {Math.min(limit * currentPage, totalRecords || 0 )} of {totalRecords || 0}
            </span>

            <button className="btn btn-link ml-2" onClick={() => handlePageChange("prev")} disabled={currentPage === 1}>
              &lt;
            </button>
            <button className="btn btn-link" onClick={() => handlePageChange("next")} disabled={currentPage >= Math.ceil(totalRecords / limit)}>
              &gt;
            </button>
          </div>
        </div>
         
      </div>

      <Modal isOpen={showpre} toggle={handleCancelPre}>
        <p className="text-right m-0">
          <span onClick={handleCancelPre} className="modal-cancel12">
            <i class="zmdi zmdi-close"></i>
          </span>
        </p>
        <ModalBody>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docs}
            style={{ height: "450px" }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Trashtab;